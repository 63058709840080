@import '../../../../../../abstract/variables';
@import '../../../../../../abstract/mixins';

@mixin list($listType, $width) {
  &-option {
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    width: $width;
    border-radius: 10px;
    margin: 5px 0px;

    &-lbl {
      padding: 5px 5px 5px 0px;
    }
    &-btn {
      margin-left: 10px;
    }

    &:hover {
      background-color: $primary-color;
      .#{$listType}-list-option-lbl {
        color: $label-primary-color !important;
      }
    }
  }
}
.graph-and-download-dropdown {
  background-color: white;
  &-button {
    border-radius: 5px !important;
    font-size: 16px;
    padding: 5px 8px;
    border: 1px solid #99b7da;
    cursor: pointer;
    display: inline-block;
    width: max-content;

    &-text {
      max-width: 150px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .expandBox-title {
      &-container {
        justify-content: space-between;
        padding: 0px;
      }

      &-expand-shape {
        fill: #909090 !important;
      }
    }
  }

  &-radio {
    overflow-y: scroll;
    overflow-x: unset !important;
    color: black;
    position: absolute;
    padding: 5px;
    width: max-content !important;
    max-height: 200px !important;
    z-index: 100;
    .radio-list {
      @include list('radio', 200px);
    }
  }
  &-checkbox {
    .checkbox-list-dropdown-container {
      width: max-content;
      .checkbox-list {
        max-height: 200px !important;
        overflow-y: scroll;
      }
    }
    
  }

  .expandBox-container {
    padding-bottom: 0px;
  }

  .expandBox-title-expand-shape {
    fill: #0054a6 !important;
  }
}
