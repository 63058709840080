@import '../../abstract/variables';
@import '../../abstract/mixins';

.map-marker-base {
  border-radius: 50%;
}

.map-marker-dam-storage {
  background: $dam-storage;
}

.map-marker-river-gauge {
  background: $river-gauge;
}

.map-marker-discharge-site {
  background: #3680A0;
}

.map-marker-environment {
  background: $environmental-flow-target;
}

.map-marker-constraints {
  background: $constraint;
}

.map-marker-rainfall {
  background: $rainfall;
}

.map-marker-pump {
  background: $pumps;
}

.map-marker-weir {
  background: $weir;
  padding: 2.5px 1px 2.5px 2px;
}

.map-marker-pump-green {
  background: $flow-class-pump-green;
  border-radius: 50%;
  color: #ededed;
  font-weight: bold;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
}

.map-marker-pump-red {
  background: $flow-class-pump-red;
  border-radius: 50%;
}

.map-marker-popup-header {
  height: 55px;
}

.marker-popup-header {
  @include textVerticalCenter;
  padding: 23px;
  height: 55px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 12px 12px 0px 0px;
}

@include sm {
  .marker-popup-header {
    border-radius: 10px 10px 0px 0px;
    font-size: 20px;
    padding: 30px;
  }
}
