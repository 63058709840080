@import '../../abstract/variables';

.announcement-item {
  &-container {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }

  &-row {
    font-size: 16px;
    color: $text-secondary-color;
    font-weight: bold;
  }
}
