@import '../../abstract/variables.scss';
@import '../../abstract/mixins.scss';

.data-filters {
  &-title {
    padding: 22px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    width: 100%;
    height: 60px;
    line-height: 16px;
    background-color: $primary-color;
    border-radius: 10px 10px 0 0;
  }
  &-container {
    background-color: #efefef;
    padding: 24px;
    display: flex;
    flex-direction: column;
    height: fit-content;
    gap: 10px;
    width: 100%;

    @include sm {
      border-radius: 0 0 10px 10px;
      padding: 10px;
      align-items: center;
    }
    .expandBox {
      &-title-container {
        padding: 0px;
      }
      &-title {
        &.archived {
          margin-bottom: 0;
          padding: 0 10px 0px 20px;
          .expandBox-title-container {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            height: 45px;
          }
        }
      }
      &-container {
        &.archived {
          background-color: white;
          border-radius: 4px;
          padding-bottom: 0px;
        }
        @include sm {
          margin: 0px 10px;
        }
      }
    }
  }

  &-item {
    .expandBox {
      &-title-container {
        flex-direction: row-reverse;
        justify-content: flex-end;
      }
    }
    color: $primary-color;
    font-size: map-get($text-size, 'content-h2');
    margin-bottom: 5px;

    &-arrow {
      fill: $primary-color;
    }

    &-content {
      background-color: white;
      padding: 0px 20px;
      margin-bottom: 20px;
      @include sm {
        .download-check-box,
        .type,
        .download-radio-picker {
          font-size: 14px;
        }
      }
    }
  }
  &-btn {
    margin-top: 10px;
    background-color: unset;
    border: 2px solid $primary-color;
    border-radius: 5px;
    color: $primary-color;
    font-size: map-get($text-size, 'content-h2');
    font-weight: bold;
    padding: 5px 10px;
    width: auto;
    align-self: flex-end;

    &.archived {
      @include sm {
        background-color: $primary-color;
        color: white;
        font-size: 16px;
        text-transform: capitalize;
      }
    }

    &:disabled {
      cursor: default;
      pointer-events: none;
      border: 2px solid $text-secondary-color;
      color: $text-secondary-color;
      background-color: unset;
    }
  }
  &-msg {
    font-style: italic;
    color: $text-secondary-color;
    text-align: right;
    font-size: 12px;
    margin-top: 10px;
  }
}
