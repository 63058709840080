@import '../../abstract/variables';
@import '../../abstract/mixins';

.legend-surface {
  border-radius: 5px;
}

.reference-line-label {
  font-size: 18px;
  color: $primary-color;
}

.btnsInnerContainer {
  margin-bottom: 10px;
}
.recharts-polar-angle-axis-tick-value {
  transform: translateX(-10 rem);
}
.chartDivLeft {
  float: left;
  margin-top: 10px;
  list-style: none;
}

.chartDivRight {
  float: right;
  margin-top: 10px;
  min-width: 150px;
}

.chartDivContainer {
  margin-bottom: 30px;
  border: 2px solid rgb(226, 226, 226);
}

.allocation-chart {
  border-radius: 10px;
  background: #fafafa;
  margin: 20px 0px 20px 0px;
}

.allocation-chart-title {
  font-weight: bold;
  color: $label-primary-color;
  background: #707070;
  border-radius: 10px 10px 0 0;
  font-size: map-get($text-size, content-h1);

  span {
    padding: 22px;
    @include textVerticalCenter;
  }
}

.allocation-chart-body {
  padding: 30px 22px 40px 22px;
  height: auto;
}

.allocation-chart {
  margin-top: 30px;
  height: auto;
}

.finalText {
  margin-top: 60px;
  font-size: map-get($text-size, content-body);
}

.narrative-reserves-graph {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 1.5s linear;
  height: auto;
  margin: 10px 0 0 10px;
}

.narrative-reserves-graph-active {
  display: block;
  height: auto;
  margin-top: 8px;
  visibility: visible;
  opacity: 1;
  margin: 10px 0 0 10px;
  font-size: map-get($text-size, content-body);
}

.allocation-chart-chart .recharts-cartesian-axis-tick-value {
  tspan {
    font-weight: bold;
    font-size: 15px;
  }
}

.recharts-wrapper {
  padding-top: 15px;
}

.recharts-legend-wrapper {
  position: initial;
  width: 700px;
  left: 0;
  padding-left: 0px;
  overflow-x: scroll;
  overflow: auto;
  margin-top: 10px;
}

.customized-legend {
  @media (min-width: $screen-md-min) {
    position: relative;
    display: flex;
    padding-left: 10px;
    justify-content: flex-start;
  }
  @media (min-width: $screen-lg-min) {
    position: relative;
    display: flex;
    padding-left: 10px;
    justify-content: flex-start;
  }
  position: relative;
  display: flex;
}

.legend-item-legend {
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: $primary-color;
}

.recharts-label-list tspan {
  font-weight: bold;
  font-size: 13px;
  fill: #707070;
}

.legend-label {
  font-size: 12px;
}

@media (max-width: $screen-sm-min) {
  .allocation-chart {
    width: 100%;
  }

  .allocation-chart-title {
    font-weight: bold;
    color: $label-primary-color;
    background: $primary-color;
    border-radius: 10px 10px 0 0;
    font-size: map-get($text-size, content-body);

    span {
      padding: 15px;
      @include textVerticalCenter;
    }
  }

  .chart-header-desc {
    font-size: map-get($text-size, content-body-subtitle);
  }

  .allocation-chart-body {
    padding: 15px 11px 20px 13px;
    height: auto;
  }

  .recharts-wrapper {
    padding-top: 0;
  }

  .recharts-legend-wrapper {
    width: 500px;
    margin-left: 0;
    padding-left: 0px;
  }

  .legend-item-legend {
    padding: 0.1rem;
    font-weight: bold;
    color: $primary-color;
  }

  .recharts-label-list tspan {
    font-weight: bold;
    font-size: 13px;
    fill: #707070;
  }

  .customized-legend {
    position: relative;
    display: flex;
    padding-left: 10px;
    justify-content: flex-start;
  }
  .allocation-customized-legend {
    margin-top: 15px;
    padding-left: 20px;
  }
}
