@import '../../abstract/variables';
@import '../../abstract/mixins';

.back-link {
  padding-bottom: 20px;
}

.back-link a {
  color: $primary-color;
  display: flex;
  align-items: center;
  font-size: map-get($text-size, 'content-h2');
}

.back-icon {
  padding-right: 8px;
}

@include sm {
  .back-link {
    padding-bottom: 15px;
  }
}
