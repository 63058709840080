@import '../../abstract/variables';
@import '../../abstract/mixins';

.order-usage-popup {
  width: 360px;
}

@include sm {
  .river-section {
    .modal {
      padding: 0px !important;
    }
    .modal-content {
      border-radius: 10px;
    }

    .modal-header {
      position: absolute;
      width: 100%;
      z-index: 1;
    }

    .modal-body {
      padding: 0px;
    }
  }

  .order-usage-popup {
    width: unset;
  }
}
