@import '../../abstract/variables';
@import '../../abstract/mixins';

.water-balance {
  &-legend-item {
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
    font-weight: bold;
    color: $primary-color;
  }
}

@include sm {
  .licenced-water-balance-dropdown {
    .general-dropdown {
      .expandBox-title-container-textContainer {
        min-width: 95px !important;
      }
      &-menu-item,
      &-button {
        font-size: map-get($mobile-text-size, content-body);
      }
    }
  }
  .water-balance {
    .general-dropdown {
      .expandBox-title-container-textContainer {
        min-width: 95px !important;
      }
      &-menu-item,
      &-button {
        font-size: map-get($mobile-text-size, content-body);
      }
    }
  }
}
