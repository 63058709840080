@import '../../abstract/variables';
@import '../../abstract/mixins';

.page-top {
  &-intro,
  &-subIntro,
  &-subIntro-lastItem {
    font-size: map-get($text-size, content-body);
    color: $text-secondary-color;
  }

  &-intro {
    margin: $page-margin-general 0;
  }

  #{&}-subIntro {
    margin: $page-margin-general 0;
  }

  #{&}-subIntro-lastItem {
    margin-bottom: 30px;
    margin-top: 15px;
  }

  &-archived-intro {
    font-size: 14px;
    color: #4b555a;
    line-height: 20px;
  }
}

@include sm {
  .page-top {
    &-intro,
    &-subIntro,
    &-subIntro-lastItem {
      font-size: map-get($mobile-text-size, content-body);
    }

    &-intro {
      margin: 10px 0px 20px 0px;
    }

    &-info-list {
      background-color: rgba(229, 237, 246, 0.4);
      font-size: 14px;
      border-radius: 5px;
      padding: 19px 24px 11px 20px;
      span {
        font-weight: bold;
      }
    }
  }
}
