@import '../../abstract/variables';
@import '../../abstract/mixins';

.report-table {
  height: 100%;
  margin-top: 15px;

  &-component {
    font-size: 14px;
  }

  &-xAxis-header {
    font-size: 12px;
    font-style: italic;
    color: $title-tertiary-color;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-yAxis-header {
    font-size: 12px;
    font-style: italic;
    color: $title-tertiary-color;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  table {
    table-layout: fixed;
    width: 100%;
  }

  thead {
    display: table-header-group;
  }

  &-water-source {
    font-weight: bold;
    color: $title-tertiary-color;

    a {
      color: $text-secondary-color;
    }
    a:hover {
      text-decoration: underline;
    }
  }
  &-dam {
    padding: 0.5em 3em !important;
  }
  th {
    border-bottom: 1px solid $title-tertiary-color;
    color: $title-tertiary-color;
  }

  th,
  td {
    text-align: right;
    padding: 1em 0.5em;
  }

  tbody td {
    border-bottom: 1px solid $title-secondary-color;
  }

  tr > th:first-child,
  tbody td:first-child {
    border-bottom: none;
  }

  tbody > tr:hover {
    background: $secondary-color;
  }

  tbody > tr:first-child:hover {
    background: none;
  }

  tr > td,
  tr > th {
    width: 10%;
    overflow-wrap: break-word;
  }
  tr > th:first-child {
    width: 3%;
  }
  tr > td:first-child,
  tr > th:nth-child(2) {
    text-align: left !important;
  }

  tr > th:nth-child(2),
  tr > td:first-child {
    width: 20%;
  }

  thead th:nth-child(2) {
    color: $text-primary-color;
  }
}

@include md {
  .report-table {
    font-size: 14px;

    tr > td,
    tr > th {
      width: 12.5%;
    }

    tr > td:nth-child(2),
    tr > th:nth-child(2) {
      width: 25%;
    }

    &-dam {
      padding: 0.5em 2em !important;
    }
  }
}

@include sm {
  .report-table {
    overflow: auto;
    width: auto;
    background-image: linear-gradient(to right, white, white),
      linear-gradient(to right, white, white),
      linear-gradient(to right, rgba(180, 180, 180, 0.2), rgba(255, 255, 255, 0)),
      linear-gradient(to left, rgba(180, 180, 180, 0.3), rgba(255, 255, 255, 0));
    background-position: left center, right center, left center, right center;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 20px 100%, 20px 100%, 15px 100%, 15px 100%;
    background-attachment: local, local, scroll, scroll;

    &-xAxis-header,
    &-yAxis-header {
      display: none;
    }

    table {
      width: auto;
      max-height: 300px;
    }
    thead th {
      position: -webkit-sticky; /* for Safari */
      position: sticky;
      top: 0;
    }
    tr > th:first-child {
      text-align: left;
    }
    tbody th,
    tbody td:first-child {
      position: -webkit-sticky; /* for Safari */
      position: sticky;
      left: 0;
    }

    thead th:first-child {
      color: $text-primary-color;
      left: 0;
      z-index: 2;
    }

    thead th:nth-child(2) {
      color: $title-tertiary-color;
    }

    tbody > tr:hover {
      background: none;
    }

    thead th,
    tbody td:first-child {
      background: $secondary-color;
      border-bottom: 1px solid white;
      border-right: 1px solid white;
      z-index: 1;
    }

    tbody th {
      background: #fff;
      border-right: 1px solid #ccc;
      box-shadow: 1px 0 0 0 #ccc;
    }

    table {
      border-collapse: collapse;
    }

    td,
    th {
      padding: 0.5em;
    }
  }
}
