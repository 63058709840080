@import '../../abstract/variables';
@import '../../abstract/mixins';

@mixin baseIcon($color, $base, $size, $url) {
  height: 40px;
  width: 40px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url($url);
  background-color: $color;
  background-size: $size;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid $base;
  margin-bottom: 5px;
  min-height: 100%;
}

@include sm {
  .water-site {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-column-gap: 8px;
    align-items: center;

    &-item {
      border: solid 1px $primary-color;
      border-radius: 10px;
      padding: 10px;
      text-transform: uppercase;
      color: $text-secondary-color;
      font-weight: bold;
      font-size: 14px;
      align-items: center;
      display: flex;
      flex-direction: column;

      &-dam-icon {
        @include baseIcon($dam-storage, $dam-storage, 120%, '../../image/icons/dam.svg');
      }
      &-weir-icon {
        @include baseIcon($dam-storage, $dam-storage, 100%, '../../image/icons/mapMarker/weir.svg');
      }
      &-gauge-icon {
        @include baseIcon($river-gauge, $river-gauge, 85%, '../../image/icons/mapMarker/gauge.svg');
      }
      &-bore-icon {
        @include baseIcon($river-gauge, $river-gauge, 90%, '../../image/icons/mapMarker/bore.png');
      }
    }
  }
}
