@import '../../abstract/variables';
@import '../../abstract/mixins';

.chart-icon,
.chart-icon-disabled {
  display: flex;
  align-items: center;
  padding: 10px;
  text-transform: uppercase;
  font-size: 14px;
  color: $text-primary-color;

  a {
    color: $text-primary-color;
  }

  &:hover {
    background: $selection-inactive-background-color;
    border-radius: 5px;
    cursor: pointer;
  }
  svg {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  &-disabled {
    color: $title-tertiary-color !important;
    pointer-events: none;

    svg {
      fill: $title-tertiary-color !important;
    }
  }
}
