@import '../../abstract/variables';
@import '../../abstract/mixins';
@import './feedbackVariable.scss';

.feedback-btn {
  height: 40px;
  width: 125px;
  position: fixed;
  right: 0;
  top: 50vh;
  border-radius: 10px 10px 0px 0px;
  border: none;
  background: $primary-color;
  color: $label-primary-color;
  font-size: map-get($text-size, content-body);
  transform: translate(50%, 0) rotate(-90deg) translate(0, -50%);
  animation: bounce 1s 3;
  transition: transform 0.2s linear;
  &:focus {
    outline: none;
  }

  &-expand {
    transform: translate(50%, 0) rotate(-90deg) translate(0, -50%) translateY(-$form-width);
  }
}

@keyframes bounce {
  0% {
    right: 0px;
  }
  20% {
    right: -15px;
  }
  40% {
    right: 0px;
  }
  60% {
    right: -10px;
  }
  80% {
    right: 0px;
  }
  100% {
    right: 0px;
  }
}

@include sm {
  .feedback-btn {
    transition: unset;
    height: 35px;
    width: 100px;
    top: 75vh;
    &-expand {
      opacity: 0;
    }
  }
}
