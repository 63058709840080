@import '../../abstract/variables';
@import '../../abstract/mixins';

.data-summary-graph {
  &-links {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  &-header {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    align-items: center;
    .general-dropdown-menu {
      &-item,
      &-item-selected {
        padding: 5px 10px;
        margin: 3px 0px;
      }
    }
  }

  &-left {
    color: $text-primary-color;
    font-weight: bold;
    font-size: map-get($text-size, content-body);
    .general-dropdown-menu {
      width: 200px;
    }
  }

  &-right {
    .expandBox-body {
      display: flex;
      justify-content: flex-end;
    }
  }

  button {
    text-transform: uppercase;
  }
}
@include sm {
  .data-summary-graph {
    &-title {
      font-size: 13px;
    }
  }
}
