@import '../../abstract/variables';
@import '../../abstract/mixins';

.data-summary-map-icon {
  &-container {
    @include flexCenter;
    background-color: $text-primary-color;
    cursor: pointer;
    border-radius: 50%;
    padding: 4px;
    width: 30px;
    height: 30px;
    margin-left: 1vw;

    @include sm {
      width: 25px;
      height: 25px;
    }
  }

  &-img {
    @include sm {
      height: 13px;
    }
  }
}
