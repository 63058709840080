@import '../../abstract/variables';
@import '../../abstract/mixins';

.select__multi-value {
  font-weight: bold;
}

.select__multi-value div {
  margin: 2px;
  margin-left: 8px;
  font-size: 100%;
}
