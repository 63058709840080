@import '../../../abstract/variables';
@import '../../../abstract/mixins';

.manage-request {
  .manage-body-header,
  &-body {
    grid-template-columns: 0.2fr 2fr 0.5fr 0.2fr 0.3fr;
    justify-items: center;
    display: grid;
    grid-column-gap: 10px;
    padding: 14px 17px;
    align-items: start;
    border-bottom: 1px solid #edeeee;
    &-desc {
      justify-items: flex-end;
    }
  }
  &-download-link {
    cursor: pointer;
    &-disabled {
      cursor: default;

      svg {
        fill: $title-secondary-color !important;
      }
    }
  }

  &-not-auth {
    padding: 10% 0;
  }
}

.pagination-container {
  margin-top: 16px;
}
