@import '../../../abstract/variables';
@import '../../../abstract/mixins';

.state-overview-summary {
  position: relative;
  min-height: 100px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 18px;
  // z-index: 450;
  width: 500px;
  background-color: white;
  padding: 10px 20px;
  border-radius: 10px;

  .spinner-wrapper {
    z-index: 0;
  }

  &-hide {
    display: none;
  }

  &-title {
    display: flex;
    align-items: center;
    &-text {
      font-size: map-get($text-size, content-body);
      padding-top: 2px;
      color: $text-primary-color;
      text-transform: uppercase;
      font-weight: bold;
    }

    &-icon {
      fill: $primary-color;
      width: 16px;
      height: 16px;
      margin-left: 3px;
    }

    &-arrow {
      position: relative;
      fill: $text-primary-color;
    }
  }

  &-overview {
    width: 460px;
    display: flex;
    margin: 12px 0px;
    &-button {
      font-size: map-get($text-size, 'content-body-subSubtitle');
      width: 100%;
      background-color: white;
      border: none;
      border-top: 1px solid $primary-color;
      border-bottom: 1px solid $primary-color;
      border-right: 1px solid $primary-color;
      color: $primary-color;
      text-transform: uppercase;
      font-weight: bold;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      &-value {
        font-size: map-get($text-size, 'content-h2');
      }

      &-unit {
        font-size: 12px;
      }
      &-icon {
        fill: $primary-color;
        border: 2px solid $primary-color;
        border-radius: 20px;
        width: 35px;
        height: 35px;
        padding: 4px;
        margin-right: 5px;
      }

      &-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 12px;
      }

      &-selected {
        @extend .state-overview-summary-overview-button;
        background-color: $primary-color;
        color: white;

        &-icon {
          @extend .state-overview-summary-overview-button-icon;
          fill: white;
          border-color: white;
        }
      }

      &:focus {
        outline: none;
      }

      &:first-of-type {
        border-left: 1px solid $primary-color;
        border-radius: 10px 0px 0px 10px;
      }

      &:last-of-type {
        border-radius: 0px 10px 10px 0px;
      }
    }
  }
  .expandBox-container {
    padding: 0px;
  }

  .expandBox-title {
    &-expand {
      position: absolute;
      top: 17px;
      right: 20px;
    }

    &-container {
      padding: 0px;
      display: flex;
      justify-content: space-between;
      cursor: default;
    }
  }
}
