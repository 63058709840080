@import '../../../abstract/variables';
@import '../../../abstract/mixins';

.state-overview-popups {
  @mixin baseIcon($color, $base, $size, $url) {
    background-image: url($url);
    background-color: $color;
    background-size: $size;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid $base;
  }
  @mixin storageIcon($color, $base) {
    background-image: url(../../../image/icons/dam.svg);
    @include baseIcon($color, $base, 100%);
  }

  @mixin tradeIcon($color, $base) {
    background-image: url(../../../image/icons/trade.svg);
    @include baseIcon($color, $base, 70%, '../../../image/icons/trade.svg');
  }

  @mixin availabilityIcon($color, $base) {
    background-image: url(../../../image/icons/availability.svg);
    @include baseIcon($color, $base, 60%, '../../../image/icons/availability.svg');
  }

  @mixin colorStage($color-1, $color-2, $color-3, $color-4, $color-5, $base, $size, $url) {
    &-1 {
      @include baseIcon($color-1, $base, $size, $url);
    }

    &-2 {
      @include baseIcon($color-2, $base, $size, $url);
    }

    &-3 {
      @include baseIcon($color-3, $base, $size, $url);
    }

    &-4 {
      @include baseIcon($color-4, $base, $size, $url);
    }

    &-5 {
      @include baseIcon($color-5, $base, $size, $url);
    }
  }
  &-icons {
    &-storage {
      @include colorStage(
        $storage-1,
        $storage-2,
        $storage-3,
        $storage-4,
        $storage-5,
        $storage-1,
        100%,
        '../../../image/icons/dam.svg'
      );
    }
    &-availability {
      $availabilityIcon: '../../../image/icons/availability.svg';
      $availabilitySize: 55%;
      &-surfacewater {
        @include colorStage(
          $surface-1,
          $surface-2,
          $surface-3,
          $surface-4,
          $surface-5,
          $surface-1,
          $availabilitySize,
          $availabilityIcon
        );
      }
      &-groundwater {
        @include colorStage(
          $ground-1,
          $ground-2,
          $ground-3,
          $ground-4,
          $ground-5,
          $ground-1,
          $availabilitySize,
          $availabilityIcon
        );
      }
    }
    &-trades {
      $tradeIcon: '../../../image/icons/trade.svg';
      $tradeSize: 60%;
      &-surfacewater {
        @include colorStage(
          $surface-1,
          $surface-2,
          $surface-3,
          $surface-4,
          $surface-5,
          $surface-1,
          $tradeSize,
          $tradeIcon
        );
      }
      &-groundwater {
        @include colorStage(
          $ground-1,
          $ground-2,
          $ground-3,
          $ground-4,
          $ground-5,
          $ground-1,
          $tradeSize,
          $tradeIcon
        );
      }
    }
  }
}
