@import '../../../abstract/variables';
@import '../../../abstract/mixins';

.trading-market {
  &-title {
    display: flex;
    align-items: center;
    margin: 32px 0px 6px 0px;
    &-text {
      font-size: map-get($text-size, content-h2);
      color: $text-primary-color;
      font-weight: bold;
    }

    &-icon {
      fill: $primary-color;
      width: 16px;
      height: 16px;
    }
  }
  &-intro {
    font-size: map-get($text-size, content-body);
    color: $text-secondary-color;
    margin-bottom: 6px;
  }

  &-empty {
    font-size: map-get($text-size, content-h2);
    align-self: center;
    color: $text-secondary-color;
    font-weight: bold;
    margin: 30px 20px;
  }

  .spinner-wrapper {
    position: unset;
    margin: 30px 0px;
  }
}

@include sm {
  .trading-market {
    &-title {
      display: flex;
      align-items: center;
      &-text {
        font-size: map-get($text-size, content-h2);
        color: $text-primary-color;
        font-weight: bold;
      }

      &-icon {
        fill: $primary-color;
        width: 16px;
        height: 16px;
      }
    }
    &-intro {
      font-size: map-get($text-size, content-body);
      color: $text-secondary-color;
      margin-bottom: 6px;
    }
  }
}
