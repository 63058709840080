@import '../../../abstract/variables';
@import '../../../abstract/mixins';

@mixin scale-label-base {
  height: 20px;
  display: flex;
  align-items: center;
}
@mixin circle($zIndex, $size, $color1: white, $color2: none) {
  z-index: $zIndex;
  width: $size;
  height: $size;
  border-radius: 50%;
  @if $color1 != white {
    border: solid 1px white;
  }
  @if $color2 != none {
    background: linear-gradient(to right, $color1 50%, $color2 50%);
  } @else {
    background: $color1;
  }
  margin-left: auto;
  margin-right: auto;
}

@mixin colorPerc($color1, $color2, $color3, $color4, $color5) {
  &-perc-full-1 {
    background: $color1;
  }
  &-perc-full-2 {
    background: $color2;
  }
  &-perc-full-3 {
    background: $color3;
  }
  &-perc-full-4 {
    background: $color4;
  }
  &-perc-full-5 {
    background: $color5;
  }
}

@mixin colorCircle(
  $color1-1,
  $color2-1,
  $color3-1,
  $color4-1,
  $color5-1,
  $color1-2: none,
  $color2-2: none,
  $color3-2: none,
  $color4-2: none,
  $color5-2: none
) {
  @if $color1-1 != none {
    &-volume-1 {
      @include circle(2, 100px);
      &-color {
        @include circle(2, 100px, $color1-1, $color1-2);
      }
    }
  }
  &-volume-2 {
    @include circle(3, 80px);
    &-color {
      @include circle(3, 80px, $color2-1, $color2-2);
    }
  }
  &-volume-3 {
    @include circle(4, 60px);
    &-color {
      @include circle(4, 60px, $color3-1, $color3-2);
    }
  }
  &-volume-4 {
    @include circle(5, 40px);
    &-color {
      @include circle(5, 40px, $color4-1, $color4-2);
    }
  }
  &-volume-5 {
    @include circle(6, 20px);
    &-color {
      @include circle(6, 20px, $color5-1, $color5-2);
    }
  }
}

.resources-map-legend {
  width: 200px;
  background: white;
  border-radius: 8px;
  position: absolute;
  bottom: 25px;
  right: 50px;
  flex-wrap: wrap;
  z-index: 460;
  padding: 13px;
  display: flex;

  &-percentage,
  &-volume {
    font-weight: bold;
    font-size: 14px;
    width: 100%;
  }

  &-reference {
    display: flex;
    align-items: center;
    margin-top: 5px;

    &-storage-volume-dot,
    &-surfacewater-dot,
    &-groundwater-dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 10px;
    }

    &-storage-volume-dot {
      background-color: $primary-color;
    }
    &-surfacewater-dot {
      background-color: $surface-1;
    }
    &-groundwater-dot {
      background-color: $ground-1;
    }
  }
  &-percentage {
    margin-bottom: 15px;

    &-scale {
      margin-top: 5px;
      display: flex;
      font-weight: normal;
      font-size: 12px;

      &-list {
        width: 28px;
        border: solid 1px white;
      }
      &-left {
        display: flex;
        float: left;
        width: 28px;
        margin-right: 8px;

        &-color {
          width: 100%;
          div:first-child {
            border-radius: 5px 5px 0px 0px;
          }
          div:last-child {
            border-radius: 0px 0px 5px 5px;
          }

          div {
            height: 20px;
            border: solid 1px white;
          }
          &-left {
            @extend .resources-map-legend-percentage-scale-left-color;
            div:first-child {
              border-radius: 5px 0px 0px 0px;
            }
            div:last-child {
              border-radius: 0px 0px 0px 5px;
            }
          }
          &-right {
            @extend .resources-map-legend-percentage-scale-left-color;
            div:first-child {
              border-radius: 0px 5px 0px 0px;
            }
            div:last-child {
              border-radius: 0px 0px 5px 0px;
            }
          }
        }

        &-storage {
          @include colorPerc($storage-1, $storage-2, $storage-3, $storage-4, $storage-5);
        }

        &-availability {
          &-ground {
            @include colorPerc($ground-1, $ground-2, $ground-3, $ground-4, $ground-5);
          }
          &-surface {
            @include colorPerc($surface-1, $surface-2, $surface-3, $surface-4, $surface-5);
          }
        }

        &-trades {
          &-ground {
            @include colorPerc($ground-1, $ground-2, $ground-3, $ground-4, $ground-5);
          }
          &-surface {
            @include colorPerc($surface-1, $surface-2, $surface-3, $surface-4, $surface-5);
          }
        }
      }
      &-right-label {
        @include scale-label-base;
      }
    }
  }

  &-volume {
    &-scale {
      display: flex;
      font-weight: normal;
      font-size: 12px;

      &-left {
        float: left;
        width: 36px;
        padding-right: 4px;
        padding-top: 5px;

        &-label {
          @include scale-label-base;
          justify-content: flex-end;
        }
      }

      &-right {
        &-circle {
          margin-top: 15px;
          width: 120px;
          position: absolute;

          hr {
            z-index: 10;
            height: 1px;
            border: 0;
            top: 10px;
            border-top: 1px solid #a4a9ac;
            width: 100%;
            margin: 0px;
          }
        }

        &-storage {
          @include colorCircle(none, $storage-1, $storage-1, $storage-1, $storage-1);
        }

        &-availability {
          @include colorCircle(
            none,
            $ground-1,
            $ground-2,
            $ground-3,
            $ground-4,
            none,
            $surface-1,
            $surface-2,
            $surface-3,
            $surface-4
          );
        }

        &-trades {
          @include colorCircle(
            none,
            #ff5c00,
            #ff5c00cc,
            #ff5c0099,
            #ff5c0066,
            none,
            #3e7a95,
            #3e7a95cc,
            #3e7a9599,
            #3e7a9566
          );
        }
      }
    }
  }
}
