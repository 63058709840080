@import '../../abstract/variables';
@import '../../abstract/mixins';

@include sm {
  .site-link {
    &-item {
      height: 56px;
      border-top: solid 1px #edeeee;
      display: flex;
      align-items: center;
      color: $text-secondary-color;

      span {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: block;
      }

      &-button {
        border: none;
        background-color: inherit;
        transform: rotate(-180deg);
        height: 30px;
      }

      &-arrow {
        fill: $primary-color;
        height: 100%;
      }

      &-remove {
        &-button {
          border: none;
          background-color: inherit;
          height: 40px;
        }
        &-icon {
          padding-bottom: 5px;
          img {
            filter: invert(82%) sepia(1%) saturate(1151%) hue-rotate(159deg) brightness(86%)
              contrast(78%);
            width: 16px;
            height: 19px;
          }
        }
      }
    }
  }
}
