@import '../../../abstract/variables';
@import '../../../abstract/mixins';

.text-read-more {
  &-button {
    padding: 0;
    border: none;
    background-color: inherit;
    color: $primary-color;
    text-decoration: underline;

    &:focus {
      outline: none;
    }
  }
}
