@import '../../abstract/variables';
@import '../../abstract/mixins';

@mixin arrowNeighbourLabel {
  .arrow-neighbour-label {
    height: 22px;
    position: absolute;
    align-items: center;
    justify-content: left;
    overflow: hidden;
    width: 0px;
    transition: all 0.3s linear;
    right: 10px;
    font-size: 17px;
    font-weight: bold;
    color: $primary-color;
    :hover {
      right: 10px;
      fill: $primary-color;
    }
  }

  .arrow-neighbour-label.show {
    position: relative;
    right: 10px;
    width: 220px;
  }
}

.arrow-neighbour {
  &-top {
    height: 29px;
    position: absolute;
    align-items: center;
    z-index: 400;
    display: flex;
    cursor: pointer;
    right: 25%;
    top: 10%;
    :hover {
      fill: $primary-color;
    }
    &-icon {
      transform: rotate(-90deg);
    }

    @include arrowNeighbourLabel;
  }

  &-bottom {
    height: 24px;
    position: absolute;
    z-index: 400;
    display: flex;
    cursor: pointer;
    bottom: 20%;
    right: 45%;
    :hover {
      fill: $primary-color;
    }
    &-icon {
      transform: rotate(90deg);
    }

    @include arrowNeighbourLabel;
  }

  &-left {
    height: 24px;
    position: absolute;
    z-index: 400;
    display: flex;
    cursor: pointer;
    bottom: 50%;
    right: 70%;
    :hover {
      fill: $primary-color;
    }
    &-icon {
      transform: rotate(-180deg);
    }

    @include arrowNeighbourLabel;
  }

  &-right {
    height: 24px;
    position: absolute;
    z-index: 400;
    display: flex;
    cursor: pointer;
    bottom: 50%;
    right: 10%;
    :hover {
      fill: $primary-color;
    }

    @include arrowNeighbourLabel;
  }
}

.barwon-arrow {
  top: 520px;
}
