@import '../../../abstract/variables';
@import '../../../abstract/mixins';

.btns-inner-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > * {
    margin: 10px 0px;
  }
}

@include sm {
  .btns-inner-container {
    &-mobile-hidden {
      display: none;
    }
  }
}
