@import '../../../../../../abstract/variables';
@import '../../../../../../abstract/mixins';

.station-download-type-select {
  padding-bottom: 12px;

  .btn, .btn-primary, .selection-btn {
    border: 1px solid #0054a6;
    font-size: 16px;
  }

  .selection-btn {
    border-radius: 10px;
    padding: 6px 12px;
  }
}

@include xl {
  .station-download-type-select {
    &-btn {
      font-size: map-get($mobile-text-size, 'content-body-subtitle') !important;
    }
  }
}
