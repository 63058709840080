@import '../../abstract/variables';
@import '../../abstract/mixins';

.global-search {
  .global-search-bar {
    &-input {
      width: 45vw;
      max-width: 450px;
      padding-right: 10px;
    }
    &-suggestion {
      width: 45vw;
      max-width: 450px;
    }
  }
  &-counts {
    font-size: 12px;
    color: $text-secondary-color;
    margin-top: 16px;
  }

  &-recommendation {
    color: $text-secondary-color;
    margin-top: 12px;
    font-weight: bold;

    &-content {
      text-decoration: underline;
      cursor: pointer;
      margin-left: 8px;
    }
  }

  &-body {
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: 0.5fr 1fr;
    &-sort {
      &-title {
        color: $text-secondary-color;
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 12px;
      }

      &-container {
        color: $text-secondary-color;
        margin-left: 20px;
      }

      &-options {
        label {
          margin-left: 12px;
          font-weight: bold;
        }
      }

      &-clear {
        font-size: map-get($text-size, 'content-body');
        color: $text-primary-color;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    &-result {
      &-item {
        margin-bottom: 40px;
      }

      &-header {
        color: $text-secondary-color;
        font-size: map-get($text-size, 'content-body');
        em {
          font-weight: bold;
        }
      }

      &-subheader {
        color: #535353cb;
        font-weight: bold;
        font-size: map-get($text-size, 'content-subSubtitle');
      }

      &-link {
        color: $text-primary-color;
        font-size: map-get($text-size, 'content-body');
      }
    }

    &-null {
      color: $text-secondary-color;
      font-size: map-get($text-size, 'content-body');
      margin-top: 32px;
    }
  }
}

@include md {
  .global-search {
    .global-search-bar {
      &-input {
        width: 100%;
      }
      &-suggestion {
        width: 100%;
      }
    }

    &-body-sort {
      &-title,
      &-clear,
      &-options > label {
        font-size: 14px;
      }
      &-container {
        margin-left: 0px;
      }
    }
  }
}
