@import '../../../abstract/variables';
@import '../../../abstract/mixins';

.data-quality-legend {
  border-radius: 10px;
  padding: 12px 20px;
  width: 180px;
  @include sm {
    padding: 10px 15px;
  }

  &-title {
    font-size: map-get($text-size, content-body-subtitle);
    font-weight: bold;
    margin-bottom: 8px;
    @include sm {
      font-size: 12px;
      margin-bottom: 4px;
    }
  }

  &-status {
    text-align: center;
    display: grid;
    font-size: 10px;
    grid-gap: 1px;
    &-item {
      color: $label-primary-color;
      padding: 6px;
      text-transform: uppercase;
      @include sm {
        padding: 4px;
      }
    }
    &-normal {
      @extend .data-quality-legend-status-item;
      background-color: #707070;
      border-radius: 5px 0px 0px 5px;
    }

    &-anomaly {
      @extend .data-quality-legend-status-item;
      background-color: #ef4848;
      border-radius: 5px;
    }
  }

  &-note {
    margin-top: 8px;
    color: $text-secondary-color;
    font-style: italic;
    line-height: 1.67;
    a {
      color: $primary-color;
      text-decoration: underline;
    }
  }
}
