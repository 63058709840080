@import '../../../../abstract/variables';
@import '../../../../abstract/mixins';

.site-selections {
  display: flex;
  width: 95%;

  &-msg {
    font-style: italic;
    color: $title-tertiary-color;
    font-size: 13px;
  }
  .input-dropdown {
    margin-top: 0px;
    &-input {
      border: 1px solid #0054a680;
      border-radius: 10px;
      margin-bottom: 5px;
      position: relative;
      background-image: url('../../../../image/icons/search.svg');
      position: left;
      padding-left: 40px;
      background-size: 25px;
      background-repeat: no-repeat;
      background-position-x: 8px;
      background-position-y: 5px;
    }
    &-input:focus {
      border: 1px solid $primary-color;
    }
    &-menu {
      &-content {
        color: $text-secondary-color;
      }
      &-item:hover {
        background: #ccdbed;
        color: $text-primary-color;
      }
      &-item:first-letter {
        text-transform: uppercase;
      }
    }
  }

  .spinner-wrapper {
    position: inherit;
  }
}

@include sm {
  .site-selections {
    display: block;
    width: 100%;
  }
}
