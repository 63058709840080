@import '../../abstract/variables';
@import '../../abstract/mixins';

.status {
  &-title {
    font-size: map-get($text-size, content-h1);
    color: $primary-color;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 12px;

    .expandBox-title-container {
      padding: 0px;

      &-textContainer {
        cursor: auto !important;
      }
    }
  }

  &-link {
    &-text {
      color: $primary-color;
      text-decoration: underline;
    }
  }
  .info-button {
    svg {
      fill: $primary-color;
    }
  }
}

@include sm {
  .status {
    &-title {
      margin-bottom: 6px;
      font-size: map-get($mobile-text-size, content-h1);
      background-color: $primary-color;
      border-radius: 10px;
      color: white;

      .expandBox-title-container {
        padding: 8px 22px;
        margin-bottom: 20px;
      }
    }

    &-link {
      font-size: map-get($mobile-text-size, content-body);
      margin-bottom: 10px;
    }

    &-arrow {
      fill: white;
    }
  }
}
