@import '../../../abstract/variables';
@import '../../../abstract/mixins';

.daily-update-item {
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  color: #707070;
  align-self: flex-end;

  &-value {
    font-size: 24px;
    &-primary {
      font-size: 40px;
    }
    &-primary-unit {
      font-size: 28px;
    }
  }

  &-unit-div {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
  }

  &-label,
  &-label-primary {
    margin-top: 5px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: normal;

    &-primary {
      font-size: 16px;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &-asterisk {
    font-weight: normal;
    font-size: 24px;
  }
}

.ws-daily-update-item {
  width: 50%;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  color: #707070;
  align-self: flex-end;

  &-value {
    font-size: 60px;
    &-unit {
      font-size: 40px;
    }
  }

  &-unit-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
  }

  &-label {
    margin-top: 5px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: normal;
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

@include xl {
  .ws-daily-update-item {
    &-value {
      font-size: 45px;
      &-unit {
        font-size: 30px;
      }
    }
    &-label {
      font-size: map-get($mobile-text-size, content-body);
      line-height: 1.13;
    }
  }
}

@include sm {
  .daily-update-item {
    margin: unset;

    &-value {
      font-size: 22px;
      &-primary {
        font-size: 28px;
      }
      &-primary-unit {
        font-size: 18px;
      }
    }

    &-unit-div {
      position: unset;
      display: flex;
      align-items: flex-end;
    }

    &-unit {
      position: unset;
      font-size: 18px;
      padding: 0px;
    }
    &-label-primary {
      font-size: 14px;
    }
  }

  .ws-daily-update-item {
    &-value {
      font-size: 40px;
      &-unit {
        font-size: 30px;
      }
    }
    &-label {
      font-size: map-get($mobile-text-size, content-body);
      line-height: 1.13;
    }
  }
}
