@import '../../abstract/variables';
@import '../../abstract/mixins';

.update-item {
  margin-top: 27px;
  margin-bottom: 30px;
  position: relative;
  overflow-wrap: break-word;
  padding-bottom: 15px;
  border-bottom: 2px solid #edeeee;

  &-type {
    text-transform: uppercase;
    color: $title-secondary-color;
  }

  &-type-div {
    @include spaceBetween;
    align-items: center;
    padding: 10px 0px;
    font-size: map-get($text-size, content-body);
    font-weight: bold;
    &--archive {
      @include spaceBetween;
      flex-direction: column;
      padding: 10px 0px;
      font-size: map-get($text-size, content-body);
      font-weight: bold;
    }
  }

  &-title {
    margin-top: 8px;
    font-size: map-get($text-size, content-h1);
    font-weight: bold;
    color: $title-tertiary-color;
    max-width: 650px;
  }

  &-date {
    padding-left: 5px;
    color: $title-tertiary-color;
    &.archivedUpdates {
      padding-left: 0;
    }
  }
  &-issuedDate {
    position: relative;
    font-size: map-get($text-size, content-body);
    font-weight: bold;
    font-style: italic;
    color: $title-secondary-color;
  }

  &-content {
    color: $text-secondary-color;
    font-size: map-get($text-size, content-body);
    margin-bottom: 5px;
    line-height: 20px;
  }

  &-link {
    font-size: map-get($text-size, content-body);
    color: $text-primary-color;
    text-decoration: underline;
  }
  &-link:hover {
    font-weight: bold;
    text-decoration: underline;
  }
}

.no-data-message {
  margin-top: 27px;
}

@include sm {
  .update-item {
    margin: 20px 10px;
    margin-left: 0;
    display: flex;
    flex-direction: column;
    // max-width: 300px;

    &-type {
      order: -1;
      font-size: map-get($mobile-text-size, content-body);
    }

    &-title {
      margin-top: 0px;
      font-size: map-get($mobile-text-size, page-h1);
    }

    &-date {
      padding-left: 0px;
      color: $title-tertiary-color;
      font-size: map-get($mobile-text-size, content-body);
      font-weight: bold;
    }

    &-content {
      font-size: map-get($mobile-text-size, content-body);
    }

    &-issuedDate {
      font-size: map-get($mobile-text-size, content-body);
    }
  }
}
