@import '../../abstract/variables';
@import '../../abstract/mixins';

.update-tab-restriction {
  .restrictions-body {
    border-radius: unset;
    background: unset;
    padding: 0px;
    padding-bottom: 7px;

    & > div {
      border-top: unset;
    }

    & > :first-child {
      border-top: unset;
    }

    &-header {
      text-transform: uppercase;
      margin-bottom: 12px;
      font-weight: bold;
      color: $text-primary-color;
      font-size: map-get($text-size, content-h1);
      border-radius: 0px;
      transition: unset;
      background: unset;

      .expandBox-title-container {
        padding: 0px;
      }
    }
  }

  .update-item {
    margin: 0px;
    margin-bottom: 24px;

    &-title {
      margin-top: 0px;
      text-transform: uppercase;
    }

    &-type-div {
      padding: 0px;
      flex-direction: column;
      align-items: flex-start;
    }

    &-type {
      display: none;
    }

    &-date {
      padding: 0px;
      margin-bottom: 9px;
    }

    &-content {
      margin-bottom: 6px;
    }
  }
}

@include sm {
  .update-tab-restriction {
    .restrictions-body {
      &-header {
        text-transform: uppercase;
        margin-bottom: 20px;
        color: $text-primary-color;
        font-size: map-get($mobile-text-size, content-h1);
        border-radius: 10px;
        background: $primary-color;
        color: $label-primary-color;

        .expandBox-title-container {
          padding: 8px 22px;
        }
      }
    }

    .update-item {
      &-title {
        font-size: map-get($mobile-text-size, page-h2);
      }

      &-date {
        font-size: map-get($mobile-text-size, page-h2);
      }

      &-content {
        margin-bottom: 6px;
        font-size: map-get($mobile-text-size, content-h1);
      }
    }

    .update-link-item {
      font-size: map-get($mobile-text-size, content-h1);
    }
  }
}
