@import '../../../abstract/variables';
@import '../../../abstract/mixins';

.maintainance-banner {
  &-container {
    background-color: $banenr-background;
    color: $banner-text-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 15px;
  }
  &-message-text {
    text-align: center;
    width: 100%;
    a {
      font-weight: bold;
      color: $banner-text-color;
      text-decoration: underline;
    }
  }
  &-close-button {
    background-color: transparent;
    color: $banner-text-color;
    border: none;
    cursor: pointer;
    font-size: 24px;
    font-weight: bold;
  }
}
