@import '../../../abstract/variables';
@import '../../../abstract/mixins';

.inter-valley-trade {
  margin-top: 32px;

  &-header {
    &-title {
      display: flex;
      align-items: center;
      margin-bottom: 6px;

      &-text {
        font-size: map-get($text-size, content-h2);
        color: $text-primary-color;
        font-weight: bold;
      }
      &-icon {
        fill: $primary-color;
        width: 16px;
        height: 16px;
      }
    }
  }

  &-intro {
    margin-bottom: 20px;
  }

  &-content {
    height: 600px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background: #ecedee;
    border-radius: 10px;
  }

  &-chart {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 10px;
  }

  &-desc {
    width: 90%;
    &-rel {
      background-color: white;
      min-height: 140px;
      width: 90%;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 2em;

      &-item {
        display: flex;
        justify-content: space-between;
      }
      &-left {
        display: inline;
        div {
          display: inline;
        }
      }

      &-right {
        white-space: nowrap;
        display: inline;
        div {
          display: inline;
        }
      }
      &-arrow {
        fill: $text-secondary-color;
        transform: rotate(180deg);
        width: 20px;
        height: 30px;
      }

      &-label {
        font-size: 20px;
        font-weight: bold;
      }

      &-value {
        font-size: 20px;
        font-weight: bold;
        color: $text-secondary-color;
      }
    }
    &-legends {
      margin-top: 90px;
      margin-left: 10%;
      display: flex;
      height: 170px;
      width: 80%;
      flex-direction: column;
      flex-wrap: wrap;

      .map-legend-item-wrapper {
        padding: 10px;
        font-size: 14px;
        display: flex;
        align-items: center;
      }
      margin-bottom: 10px;
    }
  }
}
