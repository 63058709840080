@import '../../../abstract/variables';
@import '../../../abstract/mixins';

.chip {
  display: inline-block;
  border-radius: 15px;
  background-color: $selection-active-background-color;
  color: $title-primary-color;
  font-size: 14px;
  border: 1px solid #0054a6;

  &-container {
    padding: 4px 10px;    
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
  }

  &-delete-btn {
    fill: white;
    background-color: $primary-color;
    border-radius: 10px;
    margin-left: 10px;
    height: 16px;
    width: 16px;
    cursor: pointer;
  }
}
