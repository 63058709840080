@import '../../abstract/variables';
@import '../../abstract/mixins';

.flow-class-anncmnts {
    .spinner-wrapper {
        position: relative;
        @include sm {
            margin-bottom: 20px;
        }
    }
    .general-dropdown {
        margin-bottom: 20px;
        width: 120px;
        margin-left: auto; 
        margin-right: 0;
        &-menu {
            width: 100% !important;
            &-item {
                @include sm {
                    min-width: 100% !important;
                }
            }
        }
    }
}