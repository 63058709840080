@import '../../abstract/variables';
@import '../../abstract/mixins';

.base-map {
  height: 650px;
  background: rgb(236, 236, 236);
}

.base-map-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  &-quality-legend {
    .data-quality-legend {
      position: absolute;
      bottom: 95px;
      right: 49px;
      z-index: 460;
      background-color: white;
      @include sm {
        bottom: 70px !important;
        right: 65px !important;
        &-title,
        &-note {
          display: none;
        }
      }
    }

    &-water-quality-selected {
      .data-quality-legend {
        right: 345px;
        bottom: 80px;
      }
    }
  }
  .leaflet-control-zoom {
    margin-right: 49px;
    @include sm {
      margin-right: 20px;
    }
  }
}

.map-water-source {
  margin-left: $page-margin-left-right;
  padding-top: 38px;

  span {
    font-size: 30px;
    display: flex;
  }
}

.marker-cluster-custom {
  background: #63cbe8;
  border: 3px solid #ededed;
  border-radius: 50%;
  color: #ededed;
  font-weight: bold;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
}

.restriction-type-map {
  z-index: 550;
}

.flow-class-map {
  z-index: 580;
}

.map-filter-legend-container {
  position: relative;
  width: 80px;
  top: 40px;
  right: 24.5px;
  float: right;
  z-index: 400;
  display: block;
  pointer-events: none;

  @include sm {
    display: none;
  }
}
