@import '../../abstract/variables';
@import '../../abstract/mixins';

.events-marker {
  &-popup {
    background: $recreational-event;
    &-icon {
      background: $recreational-event;
      background-image: url('../../image/icons/mapMarker/events.png');
      background-repeat: no-repeat;
      background-position-x: 5px;
      background-position-y: 5px;
      background-size: 15px;
      padding: 5px;
      border-radius: 50%;
    }
  }
}
