@import '../../abstract/variables';
@import '../../abstract/mixins';

.expand-link {
  &-arrow {
    width: 35px;
    margin-left: 10px;
    -webkit-transition: transform 0.3s ease-in-out;
    -moz-transition: transform 0.3s ease-in-out;
    -ms-transition: transform 0.3s ease-in-out;
    -o-transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
  }
  &-arrow.expandable {
    transform: rotate(180deg);
    -webkit-transition: transform 0.3s ease-in-out;
    -moz-transition: transform 0.3s ease-in-out;
    -ms-transition: transform 0.3s ease-in-out;
    -o-transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
  }
  &-dropdown-menu {
    background: white;
    width: 100%;
    color: black;
    max-height: 0px;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    visibility: collapse;
  }
  &-dropdown-menu.expandable {
    max-height: 500px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    visibility: visible;
  }

  &-item {
    padding: 10px 40px;
    font-size: 14px;
    a {
      color: $text-secondary-color;
    }
  }

  &-nav-item {
    a {
      color: White;
    }
  }
}
