@import '../../../abstract/variables';
@import '../../../abstract/mixins';

.river-location-river {
  font-weight: bold;
  fill: $text-secondary-color;

  &-sm {
    font-size: map-get($text-size, 'content-body-subtitle');
  }
  &-lg {
    font-size: map-get($text-size, 'content-body');
  }
  &-xl {
    font-size: map-get($text-size, 'content-h1-subtitle');
    fill: $primary-color;
  }
  &-area {
    font-size: map-get($text-size, 'content-body-subtitle');
    font-weight: normal;
    fill: #565f64;
  }
  &-sector {
    font-size: map-get($text-size, 'content-body');
    font-weight: bold;
    fill: #ED7D31;
  }
}
