@import '../../../../abstract/variables';
@import '../../../../abstract/mixins';

.result-content {
    border-radius: 5px;
    box-shadow: 7px 7px 22px 0 rgba(187, 192, 199, 0.37);
    padding: 30px;
    margin-bottom: 15px;

    .recharts-legend-item:hover, .legend-item-0:hover {
        cursor: pointer !important;
      }
}
