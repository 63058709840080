@import '../../../abstract/variables';
@import '../../../abstract/mixins';

.expandBox-container {
  border-radius: 10px;
  width: 100%;
  padding-bottom: 5px;

  @include sm {
    cursor: pointer;
  }
}

.expandBox-title {
  &.dam-storage {
    border: none;
    .expandBox-title-expand-shape {
      width: 20px;
      height: 20px;
    }
  }
  &-container {
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 16px 24px;
    @include sm {
      padding: 8px 10px;
      justify-content: space-between;
      flex-wrap: nowrap;
    }

    &-textContainer {
      @include sm {
        font-size: 14px;
        margin-right: 10px;
      }
    }

    &-text {
      width: max-content;
    }
  }

  &-expand {
    border: none;
    margin-left: auto;
    background-color: inherit;
    display: inline-block;
    transition: transform 0.5s ease, margin 0.5s ease;
    @include sm {
      padding: 0;
    }
    &:focus {
      outline: none;
    }

    &-shape,
    &-shape-update {
      fill: white;
      width: 20px;
      height: 20px;
      @include sm {
        // width: 10px;
        // height: 10px;
        // fill: #0054a6;
      }
    }

    &-shape-update {
      @include sm {
        fill: #0054a6;
      }
    }

    &-show {
      transform: rotate(90deg);
    }

    &-collapse {
      transform: rotate(-90deg);
    }
  }
}

.expandBox-body {
  &-show {
    @include show(bottom, 0px);
  }

  &-hidden {
    @include hidden(bottom, -10px);
  }

  &-animation {
    @include easeIn;
  }
}
