@import '../../abstract/variables';
@import '../../abstract/mixins';

.modal-dialog {
  justify-content: center;
  @include sm {
    margin: 20px;
  }
}

.modal-backdrop {
  opacity: 0.8 !important;
  background-color: $modal-background-color;
}

.modal-header {
  padding-bottom: 0px;
  button {
    @include sm {
      font-size: 2.2rem;
      font-weight: normal;
      opacity: 1;
      span {
        display: block;
      }
    }
  }
}

.modal-footer {
  padding: 0;
}

.modal-title {
  padding-top: 10px;
  padding-left: 24px;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.17;
  color: $text-primary-color;

  @include sm {
    padding-left: 13px;
    font-size: 18px;
  }
}

.modal-body {
  padding: 10px 40px;
  color: $text-secondary-color;

  @include sm {
    padding: 20px 30px;
    font-size: 14px;
  }
}

.modal-content {
  border: none;
  width: auto;
  height: auto;
  @include sm {
    border-radius: 10px;
  }
}

.no-border {
  border: none;
}

.external-sites-modal {
  &-footer {
    display: flex;
    justify-content: last baseline;
    padding: 0px 30px 20px;
    color: $title-primary-color;
    text-decoration: underline;
    font-size: 16px;
    font-weight: bold;
    justify-content: unset;
    div {
      padding-right: 20px;
    }

    a {
      color: $title-primary-color;
    }
  }
}

.confirm-modal {
  .modal-footer {
    justify-content: space-between;
  }
  &-footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    align-items: center;
    width: 100%;
    padding: 16px;
  }

  &-no,
  &-yes {
    border-radius: 10px;
    height: 44px;
    border: solid 1px #88a8ce;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    @include flexCenter;
  }

  &-no {
    color: $text-primary-color;
  }
  &-yes {
    color: white;
    background: $primary-color;
  }
}

.request-modal {
  &-footer {
    padding-top: 0;
    padding-bottom: 20px;
    justify-content: center;
  }
}
.download-filters {
  &-footer {
    display: flex;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 16px;
  }
  &-signin {
    background: $primary-color;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 130px;
    height: 60px;
    padding: 10px 20px;
    font-weight: bold;
    border-radius: 7px;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    @include sm {
      margin-top: 26px;
      font-size: 16px;
      width: 104px;
      height: 40px;
      padding: 0;
      line-height: 40px;
    }
  }
  &-cancel {
    background: white;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 130px;
    height: 60px;
    color: $primary-color;
    font-weight: bold;
    border-radius: 7px;
    padding: 10px 20px;
    text-transform: uppercase;
    border: 2px;
    cursor: pointer;
    border: 2px solid $primary-color;
    font-weight: bold;
    align-self: flex-end;
  }
  @include sm {
    margin-top: 26px;
    font-size: 16px;
    width: 104px;
    height: 40px;
    padding: 0;
    line-height: 40px;
  }
}
