@import '../../abstract/variables';
@import '../../abstract/mixins';

.glossary {
  padding: 20px 0px 0px 0px;
  border-bottom: 2px solid #ccc;
  color: $text-secondary-color;
}

.glossary-title {
  padding: 2px 0px;
  font-weight: bold;
  font-size: 18px;
}

.glossary-text {
  padding: 2px 0px;
}

.glossary-link {
  color: $text-primary-color;
  text-decoration: underline;
}

#data-quality-table {
  width: 100%;

  td {
    padding: 6px;
  }
  &-status {
    &-anomaly {
      color: $status-anomaly;
    }
    &-suspect {
      color: $status-suspect;
    }
  }
}

@include sm {
  .glossary {
    padding: 0px;
  }
  .glossary:not(:nth-child(2)) {
    padding-top: 20px;
  }
  .glossary-text {
    padding: 0px;
  }  
}