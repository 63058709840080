@import '../../abstract/variables';
@import '../../abstract/mixins';

.manage-body {
  margin-top: 15px;
  &-header {
    display: grid;
    grid-template-columns: 1.5fr 1fr 0.3fr 0.4fr 0.3fr 0.4fr;
    grid-column-gap: 10px;
    padding: 14px 17px;
    align-items: center;
    background: #edeeee;
    border-radius: 5px;
    color: $text-secondary-color;
    font-weight: bold;
    @include sm {
      display: flex;
      flex-wrap: wrap;
      row-gap: 10px;
      .Gauges {
        font-size: 14px;
        flex: 2;
      }
      .Mobile,
      .Email {
        font-size: 14px;
        text-align: center;
        flex: 1;
      }
    }
  }
}

@include sm {
  .manage-body {
    margin-top: 0px;
  }
}