@import '../../abstract/variables';
@import '../../abstract/mixins';
@import './feedbackVariable.scss';

.feedback {
  &-form {
    background-color: white;
    border-radius: 10px;
    transform: translateX($form-width);
    width: 0;
    padding: 0;
    flex-direction: column;
    transition: transform 0.2s linear;

    &-show {
      transform: translate(0px);
      width: $form-width;
      padding: 40px 5px 40px 25px;
    }

    &-header {
      font-weight: bold;
      font-size: map-get($text-size, content-h1);
      color: $text-primary-color;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    &-subHeader {
      font-size: map-get($text-size, content-body);
      color: $text-secondary-color;
      margin-bottom: 10px;
    }

    &-honeypot {
      display: none;
    }

    &-submit {
      width: 45%;
      margin: 5px 0px;
      border: none;
      border-radius: 10px;
      background-color: $primary-color;
      padding: 5px 15px;
      color: $label-primary-color;
      font-size: map-get($text-size, content-body);

      &:focus {
        outline: none;
      }

      &:active {
        background-color: darken($primary-color, 10%);
      }
    }
  }
}

@include sm {
  .feedback {
    &-form-modal {
      &-background {
        width: 100vw;
        height: 100vh;
        background-color: rgba(59, 59, 59, 0.466);
        position: fixed;
        top: 0;
        left: 0;
      }
    }

    &-form {
      width: 95%;
      height: unset;
      top: 50%;
      position: fixed;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 40px 25px;

      &-header {
        margin-bottom: 10px;
      }

      &-cancel {
        position: absolute;
        width: 5vw;
        height: 5vh;
        top: 5px;
        right: 20px;
        fill: $text-secondary-color;
        cursor: pointer;
      }

      &-submit {
        margin-top: 10px;
        width: 100%;
        font-size: map-get($text-size, content-body);
      }
    }
  }
}
