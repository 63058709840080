@import '../../abstract/variables';
@import '../../abstract/mixins';

@include sm {
  .favourite-btn {
    display: flex;
    align-items: center;
    margin-top: 12px;

    &-link {
      color: $text-primary-color;
      text-decoration: underline;
    }
    &-icon {
      width: 40px;
      height: 40px;
    }
  }
}
