@import '../../abstract/variables';

.radio-selection {
  width: 160px;
  background: white;
  border-radius: 5px;
  margin-right: 24.5px;
  margin-bottom: 5px;
  padding: 10px;
  font-size: 12px;
  color: $title-tertiary-color;
  pointer-events: auto;

  label,
  input {
    cursor: pointer;
  }

  label {
    margin-bottom: 2px;
  }
}
