@import '../../abstract/variables';
@import '../../abstract/mixins';

.pump-popup-container {
  font-size: 18px;
  font-weight: bold;
}

.pump-popup-body-esid {
  color: $text-primary-color;
  padding-bottom: 10px;
}

.pump-popup-body-link {
  text-decoration: underline;
}

.pump-popup-license-row {
  display: flex;
  align-items: center;
}

.pump-popup-license-dot {
  margin-left: 8px;
  width: 10px;
}

.hidden {
  display: none;
}
