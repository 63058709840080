@import '../../../../../../abstract/variables';
@import '../../../../../../abstract/mixins';

.graph-and-download-submit-btn {
  font-size: 18px !important;
  background-color: inherit !important;
  border: 2px solid #0054a6 !important;
  color: #0054a6 !important;
  &-disabled {
    font-size: 18px !important;
    background-color: white !important;
    border: 2px solid #c9c9c9 !important;
    color: #c9c9c9 !important;
  }
}
