@import '../../../abstract/variables';
@import '../../../abstract/mixins';

.state-overview-map-legend {
  @include sm {
    display: none;
  }

  .resources-map-legend {
    right: 90px;
  }
  
  &-rainfall {
    position: absolute;
    bottom: 20px;
    right: 65px;
    z-index: 460;
  }

  &-drought-status {
    position: absolute;
    bottom: 25px;
    right: 90px;
    z-index: 460;
  }

  .water-quality-legend {
    bottom: 25px;
    right: 90px;
  }
}
