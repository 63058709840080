@import '../../../abstract/variables';
@import '../../../abstract/mixins';

.popup-body {
    font-size: 16px;
    font-weight: bold;
    color: #4b555a;
    padding-bottom: 7px;

    &-value {
        text-align: right;
        margin-right: 4px;
    }

    &-unit {
        text-align: left;
    }
}