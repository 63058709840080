@import '../../abstract/variables';
@import '../../abstract/mixins';

.river-data-report-table {
  position: relative;
  scroll-snap-type: both mandatory;
  box-sizing: border-box;

  &-info {
    font-size: 14px;
    margin-bottom: 15px;
    font-style: italic;
  }

  table {
    width: 100%;
    margin: auto;
    border-collapse: separate;
    border-spacing: 0;
    color: #4b555a;
    font-size: 14px;
    thead {
      border-bottom: 0px;
      th {
        text-align: center;
        justify-content: center;
        vertical-align: top;
      }
    }
    th,
    td {
      padding: 5px;
      vertical-align: top;
    }
    td {
      vertical-align: inherit !important;
      text-align: right;
    }
    tbody > tr:hover {
      background: $hover-color !important;
      td {
        background: $hover-color !important;
      }
    }
  }
  &-first-header {
    position: sticky;
    position: -webkit-sticky;
    left: 0px;
    background: white;
    vertical-align: inherit !important;
  }

  td {
    vertical-align: inherit !important;
    text-align: right;
  }

  td:nth-child(4n),
  td:nth-child(4n + 1) {
    background-color: $zebra-background;
  }

  table tbody tr th:first-child {
    position: sticky;
    position: -webkit-sticky;
    left: 0px;
  }
  .spinner-wrapper {
    position: unset;
  }
}

@include lg {
  .river-data-report-table {
    overflow-x: scroll;
    box-shadow: inset -7px 0 6px -7px rgba(0, 0, 0, 0.16);

    &-first-header,
    table tbody th:first-child {
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    }
    table {
      tbody tr th:first-child {
        background-color: white;
      }
    }
  }
  .shadowed {
    background-color: white;
    width: 500px;
  }
}
