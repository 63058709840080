$font-family: Arial;
$primary-color: #0054a6;
$secondary-color: #f4f4f4;
$tertiary-color: #0077a6;
$text-primary-color: #0054a6;
$text-secondary-color: #4b555a;
$title-primary-color: $primary-color;
$title-secondary-color: #a4a9ac;
$title-tertiary-color: #4b555a;
$label-primary-color: white;
$modal-background-color: #3e3e3e;
$section-background-color: #f0f0f0;
$selection-inactive-background-color: #edeeee;
$selection-active-background-color: #e5edf6;
$text-size: (
  'map-title': 32px,
  'page-h1': 28px,
  'content-h1': 24px,
  'content-h1-subtitle': 19px,
  'content-h2': 18px,
  'content-body': 16px,
  'content-body-subtitle': 14px,
  'content-body-subSubtitle': 13px,
);
$mobile-text-size: (
  'page-h1': 20px,
  'page-h2': 18px,
  'content-h1': 16px,
  'content-body': 14px,
  'content-body-subtitle': 12px,
  'content-body-subSubtitle': 11px,
);
$primary-nav-item-text: (
  'font-size': map-get($text-size, content-body),
  'color': $label-primary-color,
);
$secondary-nav-item-text: (
  'font-size': map-get($text-size, content-body),
  'color': $text-secondary-color,
);
$tertiary-nav-item-text: (
  'font-size': map-get($text-size, content-body-subtitle),
  'color': $text-secondary-color,
);
$page-margin-general: 20px;
$page-margin-left-right: 84px;
$tab-box-shadow: 5px -5px 20px -4px rgba(0, 0, 0, 0.15);
$tab-box-selected-height: 65px;

$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;
$screen-xxl-min: 1350px;

// styling for screen-sm-min
$sm-page-padding-top-down: 32px;
$sm-page-padding-left-right: 20px;

// map filter color
$dam-storage: #0054a6;
$river-gauge: #4b555a;
$environmental-flow-target: #bed12a;
$rainfall: #63cbe8;
$maintenance: #0bbca8;
$recreational-event: #a95fd8;
$constraint: #f75de0;
$pumps: #f36c21;
$order-usage: #fcb833;
$flow-class: #0054a6;
$flow-class-pump-green: #41b22d;
$flow-class-pump-red: #f32121;
$weir: #0054a6;
$drought-status: #79511a;
$flow-rate: #1c7f28;
$water-quality: #16b17b;

// drought colors
$drought-status-bg-1: #1c9300;
$drought-status-bg-2: #fcbe00;
$drought-status-bg-3: #ff5c00;
$drought-status-bg-4: #d12800;

// expansion box header colors
$restriction: #e64509;
$general-notice: $primary-color;
$scheduled-events: $primary-color;

// state overview colors
$storage-1: #084ba3;
$storage-2: #336fb5;
$storage-3: #6693c8;
$storage-4: #99b7da;
$storage-5: #ccdbed;
$ground-1: #ff5c00;
$ground-2: #fd7c33;
$ground-3: #fe9d66;
$ground-4: #febe99;
$ground-5: #ffdecc;
$surface-1: #3e7a95;
$surface-2: #6494aa;
$surface-3: #8bafbf;
$surface-4: #b1cad4;
$surface-5: #d8e4ea;

$flow-rate-map-legend: #0054a6;

// drought-flood risk colors
$drought-flood-risk-1: #004486;
$drought-flood-risk-2: #33699e;
$drought-flood-risk-3: #668eb6;
$drought-flood-risk-4: #1c9300;
$drought-flood-risk-5: #fcbe2f;
$drought-flood-risk-6: #ff5c1c;
$drought-flood-risk-7: #d12811;

// algal alert
$algal-alert-red: #ff0000;
$algal-alert-amber: #ffc100;
$algal-alert-green: #92d150;

// maintainance banner
$banenr-background: #1c9300;
$banner-text-color: white;

// table-view
$table-head-color: #99b7da;
$zebra-background: #e5edf6;
$hover-color: #e6e6e6;

// data-quality
$status-anomaly: #f21d1d;
$status-suspect: #a4a9ac;
