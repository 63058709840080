@import '../../abstract/variables';
@import '../../abstract/mixins';

.storage-body-container {
  &-intro {
    font-size: map-get($text-size, content-body);
    color: $text-secondary-color;
    display: flex;

    &-text {
      width: 60%;
      margin-right: 24px;
    }
    &-tile {
      display: flex;
      justify-content: center;
      width: 40%;
      height: 240px;

      .daily-updates-container {
        width: 100%;
        height: unset;
      }

      .daily-update-item-value {
        font-size: map-get($text-size, map-title);
      }
    }
  }

  &-sub-title {
    margin: 40px 0px 20px 0px;
  }

  &-title {
    font-size: map-get($text-size, content-h1);
    color: $primary-color;
    text-transform: uppercase;
    font-weight: bold;
  }

  &-icon {
    svg {
      fill: $primary-color;
      height: 16px;
      width: 16px;
    }
  }
}
.dam-info-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, min(47.5%, 600px));
  justify-content: space-between;
}

.storage-chart-container {
  width: 100%;

  &-title {
    font-size: map-get($text-size, content-h1);
    font-weight: bold;
    width: 100%;
    color: $primary-color;
    display: none;
  }
}

@include lg {
  .storage-body-container {
    &-intro {
      font-size: map-get($mobile-text-size, content-body);
      flex-direction: column;
      align-items: center;
      &-text {
        width: 50vw;
        margin-bottom: 20px;
      }
      &-tile {
        width: 50vw;
        .daily-updates-container {
          width: 100%;
          height: unset;
        }

        .daily-update-item-value {
          font-size: map-get($text-size, map-title);
        }
      }
    }
  }

  .dam-info-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

@include sm {
  .storage-body-container {
    &-intro {
      &-text {
        width: 100%;
      }
      &-tile {
        width: 100%;
        height: 200px;
      }
    }
  }

  .storage-chart-container {
    &-title {
      display: block;
      margin-top: $page-margin-general;
      font-size: map-get($mobile-text-size, page-h1);
    }
  }
}
