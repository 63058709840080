@import '../../abstract/variables';
@import '../../abstract/mixins';

.ci-recent-chart-tooltip {
  margin: 0px;
  padding: 10px;
  border: 1px solid #cccccc;
  background: $label-primary-color;
}

.ci-recent-chart-tooltip-item-ul {
  padding: 0px;
  margin: 0px;
}

li.list-no-style-type {
  list-style: none;
}

.custom-recharts-tooltip-item {
  display: block;
  padding-top: 4px;
  padding-bottom: 4px;
}
