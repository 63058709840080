@import '../../../../../abstract/variables';
@import '../../../../../abstract/mixins';

.station-download-filters-summary {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  &-title {
    color: $primary-color;
    font-size: map-get($text-size, 'content-body');
    font-weight: bold;
  }

  &-arrow {
    fill: $primary-color;
    transition: transform 0.5s ease;
    transform: rotate(-90deg);
    &-container {
      display: flex;
      justify-content: center;
      cursor: pointer;
      width: 100%;
      padding-bottom: 12px;
    }

    &-collapse {
      transform: rotate(90deg);
    }
  }

  &-content {
    width: 100%;
    max-height: 1000px;
    transition: max-height 0.5s ease, opacity 0.5s ease;
    opacity: 1;
    &-hidden {
      max-height: 0px;
      opacity: 0;
      z-index: -100;
    }
  }
}
