@import '../../abstract/variables';
@import '../../abstract/mixins';

@keyframes shine-lines {
  0% {
    background-position: -90px;
  }
  100% {
    background-position: 120px;
  }
}

.data-summary-table {
  &-background {
    background-color: white;
    padding: 20px 10px;
    border-radius: 5px;
  }
  &-grid {
    display: grid;
    grid-template-columns: 1.5fr repeat(7, 0.5fr);
    grid-column-gap: 10px;
    align-items: center;
    text-align: right;
  }

  &-title {
    color: #0054a6;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 13px;
    margin-bottom: 5px;
    &-main-date {
      @include sm {
        font-weight: normal;
        font-style: italic;
        font-size: 12px;
      }
    }
  }

  &-header {
    font-weight: bold;
    text-transform: uppercase;
    font-size: map-get($text-size, content-body);
    border-bottom: 1px solid black;
    padding-bottom: 15px;
    margin-bottom: 15px;
    &-title {
      color: $text-primary-color;
      padding-left: 5px;
      text-align: left;
      display: flex;
      align-items: flex-end;
    }
  }

  &-content {
    padding: 10px 0px;
    &-header {
      color: $text-secondary-color;
      font-weight: bold;
      padding-left: 5px;
      text-align: left;
    }
  }
}

@include md {
  .data-summary-table {
    &-grid {
      grid-template-columns: 1fr repeat(7, 0.5fr);
      grid-column-gap: 2px;
    }

    &-title {
      margin-bottom: 5px;
    }
  }
}

@include sm {
  .data-summary-table {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    &-background {
      background-color: #fafafa;
      padding: 1px 0px 0px 0px;
    }

    &-grid {
      width: 80px;
      display: grid;
      grid-template-columns: 0.8fr;
      grid-template-rows: 1fr repeat(7, 0.4fr);
      grid-row-gap: 5px;
      align-items: center;
    }

    &-header {
      font-size: 13px;
      margin-bottom: 0px;
      text-transform: uppercase;
      border-bottom: none;
      padding: 0px;
    }

    &-content {
      font-size: 13px;
      border-top: none;
      padding: 0px;
      &-header {
        text-align: right;
        height: 100%;
        display: flex;
        align-items: flex-end;
      }
    }
    //full-mobile
    &-title {
      display: flex;
      font-weight: bold;
      text-transform: unset;
      font-size: 13px;
      color: $text-primary-color;
      margin: 10px 0px;
      justify-content: space-between;
      align-items: center;
      &-icon-block {
        display: flex;
        align-items: center;
        gap: 5px;
        // alert-link style from dataTable
        .alert-link {
          margin: 0;
        }
      }
    }

    &-title-1 {
      font-weight: bold;
      text-transform: unset;
      font-size: 13px;
      color: #4b555a;
    }
    &-mobile-hide {
      display: none;
    }
  }
}
