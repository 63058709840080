@import '../../abstract/variables';
@import '../../abstract/mixins';

.losses-chart {
  border-radius: 10px;
  background: rgba(0, 75, 163, 0.05);
  margin: 20px 0px 20px 0px;
}

.losses-chart-title {
  font-weight: bold;
  color: $label-primary-color;
  background: #707070;
  border-radius: 10px 10px 0 0;
  font-size: map-get($text-size, content-h1);

  span {
    padding: 22px;
    @include textVerticalCenter;
  }
}

.singaling-icon {
  padding-left: 22px;
}

.losses-chart-body {
  padding: 30px 22px 30px 22px;
  height: auto;
}

.eosSubContentHeading {
  padding-top: 1.5em;
}
