@import '../../abstract/variables';
@import '../../abstract/mixins';

.alert-response {
  top: 0;
  left: 0;
  justify-content: center;
  text-align: center;
  align-items: center;
  background: white;
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 10px;

  &-show {
    text-align: center;
    margin-bottom: 10px;
  }

  &-title-text {
    font-size: map-get($text-size, content-h1);
    color: $text-primary-color;
    font-weight: bold;
    @include sm {
      font-size: 14px;
      color: $text-primary-color;
      font-weight: bold;
    }
  }

  &-sub-text {
    margin-top: 10px;
    p {
      margin-bottom: 0;
    }
    span {
      font-weight: bolder;
    }
  }

  &-body-text {
    color: $text-secondary-color;
    font-weight: 100;
    @include sm {
      font-size: 14px;
      color: $text-secondary-color;
    }
  }

  &-confirmation {
    display: flex;
    margin: 0 auto;
    width: 50%;
    justify-content: center;
    column-gap: 10px;
    padding-top: 20px;
    padding-bottom: 10px;
    @include sm {
      width: auto;
      padding: 5;
    }
  }

  &-button {
    margin-top: 30px;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    text-transform: uppercase;
    border-radius: 4px;
    padding: 8px 16px;
    text-align: center;
    cursor: pointer;
    width: 100px;
    font-weight: bold;

    &-ok {
      background: $primary-color;
      color: white;
      &.remove {
        margin: 0;
      }
    }
    &-cancel {
      background: white;
      color: $primary-color;
      border-style: solid;
      border-color: $primary-color;
      &.remove {
        margin: 0;
      }
    }
  }
}
