@import '../../abstract/variables';
@import '../../abstract/mixins';

.map-popup {
  min-width: 250px;

  &-body {
    min-height: 130px;
    padding: 15px 20px;
    font-weight: bold;
    font-size: 1.125rem;
    color: $text-secondary-color;
  }
  .row {
    align-items: center;
  }
  &-container {
    padding: 15px 20px;
    
    .chart-icon {
      color: $text-primary-color;
      cursor: pointer;
      text-decoration: underline;
      font-size: 16px;
    }
  }

  &-body-title {
    font-size: 18px;
    font-weight: bold;
    color: $text-primary-color;
    margin-bottom: 16px;
  }

  &-body-timestamp {
    font-size: 13px;
    font-style: italic;
    font-weight: normal !important;
  }

  &-body-content {
    font-weight: normal;
    font-size: 0.9rem;
  }

  &-link {
    color: $text-primary-color;
    text-decoration: underline;
    align-self: flex-end;
    text-align: right;
    margin-top: 15px;
    cursor: pointer;
    font-size: 16px;
  }

  &-environment {
    width: 380px;
  }

  &-header {
    padding: 15px 20px;
    color: white;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 12px 12px 0px 0px;
  }

  &-view-details {
    text-align: right;
    padding-top: 15px;
  }

  &-view-details-gauge {
    display: flex;
    justify-content: flex-end;
    align-items: bottom;
    align-items: center;
    margin-top: 10px;
  }

  &-value {
    text-align: right;
    margin-right: 4px;
  }

  &-unit {
    text-align: left;
  }

  .chart-icon {
    padding: 0px;
    text-transform: none;
    &:hover {
      background: none;
      text-decoration: underline;
    }
  }
}

.station-data-popup-body {
  font-size: 16px;
  font-weight: bold;
  color: #4b555a;
  padding-bottom: 7px;
}

.leaflet-popup-content-wrapper {
  padding: 0px;
  overflow: visible;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.leaflet-popup-content {
  margin: 0px;
  width: 100%;
}
.leaflet-container a.leaflet-popup-close-button {
  color: white;
  font-size: 1.2rem;
  position: absolute;
  top: 12px;
  right: 12px;
}

.pump {
  background-color: $pumps;
}
.monitoring-bore {
  background-color: $river-gauge;
}
.outage {
  background-color: $maintenance;
}
.recreation {
  background-color: $recreational-event;
}
.constraints {
  background-color: $constraint;
}
.order-usage {
  background-color: $order-usage;
}
.flow-class {
  background-color: $flow-class;
}

.null-info {
  font-style: italic;
  font-weight: normal;
}

@include sm {
  .map-popup {
    &-body-title {
      font-size: 20px;
    }

    &-body-timestamp {
      padding-top: 10px;
    }

    &-container {
      padding: 0px;
    }

    &-header {
      border-radius: 10px 10px 0px 0px;
    }
    .modal {
      padding-right: 0px !important;
    }
    .modal-content {
      border-radius: 10px;
      border: none;
      background-color: unset;
    }
    .modal-header {
      padding: 0px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 10px 10px 0px 0px;
    }
    .modal-title {
      width: 100%;
      padding: 0px;
      font-size: 28px;
      color: white;
    }
    .close {
      font-size: 30px;
      color: white;
      background: unset;
      opacity: 1 !important;
      padding: 0px;
      margin: 0px;
      position: absolute;
      right: 15px;
      align-content: center;
    }

    .modal-body {
      background-color: white;
      border-radius: 0px 0px 10px 10px;
    }
  }
}
