@import '../../../../../abstract/variables.scss';
@import '../../../../../abstract/mixins.scss';

.ratings-curve {
  margin-top: 15px;

  &-msg {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: map-get($text-size, 'content-h2');
    font-weight: bold;
    color: $primary-color;
    text-align: center;
  }

  &-btns {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
  .spinner-wrapper {
    position: relative;
  }

  &-site-data {
    table {
      th {
        padding-right: 10px;
      }
    }
    margin-bottom: 20px;
  }
}
