@import '../../abstract/variables';
@import '../../abstract/mixins';

.user-profile-btn {
  color: white;
  font-weight: bold;
  width: max-content;
  padding: 20px;

  .expand-link {
    &-dropdown-menu {
      right: 10px;
    }
    &-item {
      font-weight: normal;
    }
    &-item:last-child {
      border-top: 1px solid #edeeee;
      padding-bottom: 0px;
    }
  }
}
