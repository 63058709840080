@import '../../abstract/variables';
@import '../../abstract/mixins';

.container {
  display: block;
  width: 624px !important;
  height: 301px;
  border-radius: 10px;
}
.centered {
  text-align: center;
  padding: 0px;
  justify-content: center;
}
.noVerticalPadding {
  padding-bottom: 0;
}
.icon {
  padding: 5px;
  &-success {
    color: $text-primary-color;
  }
  &-failed,
  &-forbidden {
    color: $flow-class-pump-red !important;
    fill: $flow-class-pump-red !important;
  }
  &-duplicate {
    color: $flow-class-pump-red !important;
    fill: $flow-class-pump-red !important;
  }
  &-loading {
    color: $text-primary-color !important;
  }
}
.item {
  padding: 5px;
}
.cls-1 {
  fill: none;
}

.title-text {
  font-family: Arial;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $text-primary-color;
}

.body-text {
  margin: 11.5px 0 28px;
  font-family: Arial;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: $text-secondary-color !important;
}

.okButton {
  width: 101px;
  height: 44px;
  padding: 10px;
  border-radius: 10px;
  border-width: 0;
  font-family: Arial;
  font-size: 18px;
  font-weight: bold;
  color: white;
  background-color: $flow-class;
}
