@import '../../abstract/variables';
@import '../../abstract/mixins';

.general-map-popup {
  &-view-details {
    padding-top: 0px;
  }
  &-grouped {
    font-size: 16px;
    &-sub-title {
      color: $text-primary-color;
    }
  }
  &-grouped:not(:first-child) {
    margin-top: 10px;
  }
}
