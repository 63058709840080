@import '../../../../../../abstract/variables';
@import '../../../../../../abstract/mixins';

.graph-and-download-select {
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
  &-title {
    color: #0054a6;
    font-size: 16px;
    font-weight: bold;

    p {
      margin-bottom: 8px;
    }
  }

  &-filters {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    p {
      margin: unset;
      font-size: 16px;
    }
    .graph-and-download-dropdown,
    .graph-and-download-date-picker {
      margin: 0px 14px 5px 14px;
    }
    .graph-and-download-dropdown:first-child {
      margin-left: 0px !important;
    }
    .checkbox-list-dropdown-btn-grp {
      .general-button {
        font-size: 12px;
        padding: 5px;
      }
    }
  }
}
