@import '../../../../abstract/variables.scss';
@import '../../../../abstract/mixins.scss';

.video-item {
  .modal-content {
    width: 100%;
    .modal-body {
      .video-item-vid-wrapper {
        width: 100%;
        margin: 0px;
      }
    }
  }
  &-preview {
    margin: 20px 0px;
    width: 250px;

    &-container {
      position: relative;
      display: inline-block;
      box-shadow: inset 0px 0px 30px black;
      transition: box-shadow 0.3s ease;

      &:hover {
        box-shadow: none;
      }
    }

    &-img {
      width: 100%;
      cursor: pointer;
      opacity: 0.8;
    }

    &-play-btn {
      cursor: pointer;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $primary-color;
      border-radius: 100%;
      width: 50px;
      height: 50px;
      &-triangle {
        border-style: solid;
        border-width: 37px;
        border-color: $primary-color white;
        box-sizing: border-box;
        width: 20px;
        height: 25px;
        border-width: 13px 0px 13px 17px;
        margin-left: 10px;
      }
    }
  }
  &-title {
    margin-top: 6px;
    font-size: 18px;
    font-weight: bold;
    color: $text-secondary-color;
    text-transform: uppercase;
  }

  &-vid {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 98%;

    &-wrapper {
      width: 943px;
      padding-bottom: 56.29%;
      height: 0;
      position: relative;
      margin: 0px 10px 30px 10px;
    }
  }
}

@include md {
  .video-item {
    &-preview {
      width: 280px;
    }
  }
}

@include sm {
  .video-item {
    &-preview {
      width: 100%;
      margin: 0px 0px 20px 0px;
    }
    &-title {
      font-size: 14px;
    }
  }
}
