@import '../../abstract/variables';
@import '../../abstract/mixins';

.data-summary-header {
  .expandBox-container {
    padding: 0px;
  }

  .expandBox-title-container {
    display: flex;
    justify-content: space-between;
  }

  .expandBox-title-container-textContainer {
    width: 93%;
  }

  .expandBox-title-expand {
    display: none;
    cursor: default;
  }
}

@include sm {
  .expandBox-title-container-textContainer {
    width: 80% !important;
  }
  .expandBox-title-container {
    padding: 8px 10px;
    margin-bottom: 0px;
  }
}
