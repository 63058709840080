@import '../../abstract/variables';
@import '../../abstract/mixins';

@mixin headerColumns {
  align-items: center;

  .priority-1 {
    width: 15%;
  }
  .priority-2 {
    width: 35%;
  }
  .priority-3 {
    width: 10%;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .priority-4,
  .priority-5,
  .priority-6 {
    padding: 0px 8px;
    width: calc(15% - 8px);
    margin-left: 4px;
    margin-right: 4px;
    text-align: center;
  }
  .priority-7 {
    width: calc(17.5% - 8px);
    margin-left: 4px;
    margin-right: 4px;
    padding-left: 6px;
    text-align: center;
  }
  .priority-8 {
    width: 10%;
    text-align: center;
  }

  @include md {
    .priority-2 {
      padding-right: 3%;
    }
    .priority-3,
    .priority-6,
    .priority-8 {
      width: 0px;
      display: none;
    }
    .priority-6 {
      width: 15%;
    }
  }

  @include sm {
    .priority-1 {
      width: 25%;
    }
    .priority-2 {
      padding-right: 5px;
    }
    .priority-4,
    .priority-6 {
      width: 20%;
      padding-left: 0px;
      margin-left: 2px;
      margin-right: 2px;
      padding-right: 5px;
    }
    .priority-6,
    .priority-7 {
      width: 0px;
      display: none;
    }
  }
}

.river-data-summary-header {
  display: flex;
  width: 100%;
  font-size: 14px;
  cursor: default;
  @include headerColumns;
}

@include sm {
  .river-data-summary-header {
    width: 97%;
    font-size: 13px;
    justify-content: space-between;
  }
}
