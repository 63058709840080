@import '../../abstract/variables';
@import '../../abstract/mixins';

.water-sharing-plans {
  $innerHorizontalPadding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 40px;

  &-title {
    margin-bottom: 20px;
  }

  &-inner {
    display: flex;
    &-right {
      display: block;
      padding-left: 24px;
      padding-top: 10px;
    }
  }

  .back-link {
    padding-bottom: 20px;
  }

  &-header {
    font-size: map-get($text-size, content-h1);
    color: $label-primary-color;
    background-color: $primary-color;
    padding: 18px $innerHorizontalPadding;
    border-radius: 10px 10px 0px 0px;
    font-weight: bold;
  }

  &-content {
    background-color: #d4e2f5c5;
    flex-grow: 1;
    border-radius: 0px 0px 10px 10px;
    padding: $innerHorizontalPadding;

    &-searchbox {
      background: white;
      border: solid 0.8px $primary-color;
      border-radius: 10px;
      width: 100%;
      height: 45px;
      font-size: map-get($text-size, content-body);
      background-image: url('../../image/icons/searchIcon.svg');
      background-repeat: no-repeat;
      padding-left: 40px;
      background-size: 25px;
      background-position: 8px;

      &:focus {
        outline: none;
      }
    }
  }

  &-show {
    @include show(bottom, 0px);
  }

  &-hidden {
    @include hidden(bottom, -100px);
  }

  &-easein {
    @include easeIn;
  }

  &-expand {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &-expand-button {
    padding: 0;
    border: none;
    background-color: inherit;
    color: $tertiary-color;
    text-decoration: underline;

    &:focus {
      outline: none;
    }
  }
}

@include sm {
  .water-sharing-plans {
    width: 100%;
    margin-top: 40px;
    min-width: unset;

    &-title {
      margin-bottom: 20px;
    }

    &-inner {
      display: block;
      &-right {
        margin-top: 24px;
      }
    }

    &-header {
      line-height: 1.13;
      font-size: map-get($mobile-text-size, content-h1);
      padding: 18px 22px;
      text-transform: uppercase;
    }

    &-content {
      background-color: #d4e2f5c5;
      flex-grow: 1;
      border-radius: 0px 0px 10px 10px;
      padding: 20px;

      &-searchbox {
        font-size: map-get($mobile-text-size, content-h1);
      }
    }
    &-expand {
      font-size: map-get($mobile-text-size, content-body);
    }
  }
}

@include md {
  .water-sharing-plans {
    width: 100%;
    margin-top: 40px;
    min-width: unset;

    &-title {
      margin-bottom: 20px;
    }

    &-inner {
      display: block;
      &-right {
        padding: 0;
      }
    }

    &-header {
      line-height: 1.13;
      font-size: map-get($mobile-text-size, content-h1);
      padding: 18px 22px;
      text-transform: uppercase;
    }

    &-content {
      background-color: #d4e2f5c5;
      flex-grow: 1;
      border-radius: 0px 0px 10px 10px;
      padding: 20px;

      &-searchbox {
        font-size: map-get($mobile-text-size, content-h1);
      }
    }
    &-expand {
      font-size: map-get($mobile-text-size, content-body);
    }
  }
}
