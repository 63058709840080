@import '../../abstract/variables';
@import '../../abstract/mixins';

.heading {
  padding-top: 8px;
  font-size: map-get($text-size, content-h2);
  font-weight: bold;
}

.desc {
  font-size: map-get($text-size, content-body);
  padding-top: 8px;
  padding-bottom: 20px;
  color: $text-secondary-color;
  @include sm {
    padding-top: 0px;
    padding-bottom: 15px;
  }
}

.subDesc {
  font-size: map-get($text-size, content-body);
  padding-top: 8px;
  padding-bottom: 10px;
}

.ul li {
  list-style-type: disc;
}

@include sm {
  .heading {
    font-size: map-get($mobile-text-size, content-h2);
  }

  .desc {
    font-size: map-get($mobile-text-size, content-body);

    padding-top: 0px;
    padding-bottom: 15px;
  }

  .subDesc {
    font-size: map-get($mobile-text-size, content-body);
  }
}
