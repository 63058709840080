@import '../../../abstract/variables';
@import '../../../abstract/mixins';

$disabled-color: #a8a8a8;
.checkbox-list {
  background: white;
  overflow-x: auto;
  border: 1px solid #d4d4d4;
  border-radius: 10px;
  width: 100%;
  padding: 10px;
  max-height: 250px;

  &-msg {
    font-style: italic;
  }

  &-option {
    line-height: 130%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    &-selected {
      .checkbox-list-option {
        &-lbl {
          color: $primary-color !important;
        }
      }
    }

    &-disabled {
      .checkbox-list-option {
        &-lbl {
          cursor: default;
          color: $disabled-color;
        }

        &-btn {
          cursor: default;
          accent-color: $disabled-color;
        }
      }
    }

    &-hide {
      display: none;
    }

    &-btn {
      cursor: pointer;
      margin-right: 15px;
      accent-color: $primary-color;
    }

    &-lbl {
      cursor: pointer;
      color: $title-tertiary-color;
      margin: unset;
      width: 100%;
      height: 100%;
    }

    &:last-child {
      margin-bottom: 0px;
    }
  }
}
