@import '../../abstract/variables';
@import '../../abstract/mixins';

.login-modal-body {
  text-align: center;
  font-weight: 100;
  padding-bottom: 10px;

  &-content {
    color: $text-secondary-color;
    font-size: 18px;
    @include sm {
      font-size: 16px;
    }
  }
  &-body {
    color: $text-primary-color;
    font-size: 24px;
    font-weight: normal;
    @include sm {
      font-size: 16px;
    }
  }

  &-link-container {
    text-decoration: underline;
    font-weight: bold;
    a {
      color: $text-primary-color;
    }
    @include sm {
      text-decoration: underline;
      a {
        color: $text-primary-color;
      }
    }
  }

  hr {
    width: 280px;
    border-top: 1px solid #707070;
  }
}

.login-button {
  margin-top: 30px;
  margin-bottom: 20px;
  overflow: hidden;
  width: 150px;
  margin-left: auto;
  margin-right: auto;
}
