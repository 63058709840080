@import '../../../abstract/variables';
@import '../../../abstract/mixins';

.pollution-monitoring-body {
  width: 100%;
  position: relative;
  .spinner-wrapper {
    height: 200px;
  }

  &-table {
    width: 100%;
  }
  &-header {
    box-shadow: inset 0 -2px 0 black;
    background: white;
    z-index: 1;
    position: sticky;
    top: 0px;
    &-item {
      color: $text-secondary-color;
      font-weight: bold;
      font-size: map-get($text-size, 'content-h2');
      padding: 9px 6px;
      &:first-of-type {
        text-align: left;
      }
    }
  }

  &-row {
    &-item, &-item-notes {
      font-size: map-get($text-size, 'content-body');
      color: $text-secondary-color;
      padding: 6px;

      &:first-of-type {
        text-align: left;
      }
      
      &-notes {
        font-weight: bold;
      }
    }

    &:hover {
      background-color: lighten(black, 90%);
    }
  }

  &-note {
    margin-top: 12px;
    p {
      margin: 6px 0px;
      font-size: map-get($text-size, 'content-body');
      color: $text-secondary-color;
    }
  }
}
