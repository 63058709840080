@import '../../abstract/variables';
@import '../../abstract/mixins';

.info-icon-modal {
  text-transform: initial;
  font-size: initial;
  font-weight: initial;

  &-icon {
    svg {
      fill: $primary-color;
      height: 16px;
      width: 16px;
    }
  }

  &-text {
    white-space: nowrap;
  }
}
