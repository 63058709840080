@import '../../../../../../abstract/variables';
@import '../../../../../../abstract/mixins';

.station-select-input {
  padding: 10px 0px 10px 40px;
  width: 100%;
  font-size: 16px;

  border: 1px solid #0054a680;
  border-radius: 10px;

  background-image: url('../../../../../../image/icons/search.svg');
  background-size: 25px;
  background-repeat: no-repeat;
  background-position: left top;
  background-position-x: 8px;
  background-position-y: 10px;

  &-chip {
    &-container {
      .chip {
        font-size: 14px;
      }
      margin-top: 8px;
    }

    &-item {
      margin-right: 8px;
      margin-bottom: 8px;
    }
  }

  &-input {
    display: block;
    width: 100%;
    height: 100%;
    border: none;
    appearance: none;
    background-color: inherit;
    &:focus,
    &:focus-visible {
      outline: none;
    }
  }
}
