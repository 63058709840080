@import '../../abstract/variables';
@import '../../abstract/mixins';

.conditions {
  border-radius: 10px;
  padding-top: 1.8rem;

  &-dealing-sentence {
    margin-bottom: 20px;
  }
  &-dealing-sentence:last-child {
    margin-bottom: 0px;
  }
}

.dealing-rules {
  .conditions-inner-body {
    margin-top: 0px !important;
  }
}
.conditions-title {
  font-weight: bold;
  color: $label-primary-color;
  background: $primary-color;
  border-radius: 10px;
  font-size: map-get($text-size, content-h1);

  span {
    padding: 10px 0 10px 14px;
    @include textVerticalCenter;
  }
}

.singaling-icon {
  padding-left: 22px;
}

.conditions-body {
  padding: 30px 15px 10px 15px;
  height: auto;
}

.conditions-inner-body {
  margin-top: 30px;
  &-header {
    font-size: map-get($text-size, content-h2);
    font-weight: bold;
    color: $text-primary-color;
  }
  &-description {
    margin-top: 20px;
  }
  &-category {
    color: $text-secondary-color;
    padding-bottom: 20px;
  }

  &-class {
    color: $text-secondary-color;
    font-weight: bold;
    padding-top: 10px;

    &-content {
      color: $text-secondary-color;
    }
  }

  &-category-name {
    color: $text-secondary-color;
    font-weight: bold;
    font-size: 16px;
  }

  &-licence {
    color: $text-primary-color;
    font-weight: bold;
    font-size: map-get($text-size, content-h2);
    &-arrow {
      fill: $text-secondary-color;
    }
  }

  &-content {
    margin-left: 20px;
    .expandBox-container:first-child {
      border-top: 2px solid #edeeee;
      margin-top: 20px;
    }
    .expandBox {
      &-container {
        padding: 10px 0px;
        
        border-bottom: 2px solid #edeeee;
        border-radius: 0px;
      }
  
      &-title-container {
        padding: 6px 0px;
      }
    }
  }
  
}

.conditions-inner-body-class-content-condition {
  padding-top: 10px;
}

.info-icon {
  padding-left: 10px;
  background-color: $primary-color;
  padding: 0px 10px;
  border-width: 0px;
}

@include sm {
  .conditions-title {
    font-size: map-get($mobile-text-size, content-h1);
  }

  .conditions-body {
    font-size: map-get($mobile-text-size, content-body);
    &-intro {
      padding-bottom: 10px;
    }
    padding: 30px 10px 10px 10px;
  }

  .conditions-inner-body {
    margin-top: 10px;
    &-header {
      font-size: 16px;
      font-weight: bold;
      color: $text-primary-color;
    }
    .expandBox {
      &-container {
        padding: 5px 0px;

        .conditions-inner-body {
          &-category-name,
          &-licence {
            font-size: map-get($mobile-text-size, content-h1);
          }
        }
      }
    }
  }
}
