@import '../../abstract/variables';
@import '../../abstract/mixins';

.update-tab-base {
  @media (min-width: $screen-lg-min) {
    height: 33.666%;
  }
  cursor: pointer;
  border-bottom: 4px solid white;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    @media (min-width: $screen-lg-min) {
      right: -40px;
    }
    top: 25%;
    width: 0;
    height: 0;
    border-right: 20px solid transparent;
    border-bottom: 30px solid transparent;
    border-top: 30px solid transparent;
    clear: both;
  }
}

.update-tab {
  @extend .update-tab-base;
  background: #f4f4f4;
}

.update-tab-selected {
  @extend .update-tab-base;
  background: #e5edf6;
  transition: background 0.5s;

  @media (min-width: $screen-lg-min) {
    &:after {
      border-left: 20px solid #e5edf6;
      transition: border-left 0.1s;
    }
  }
}

.update-tab-content-base {
  padding: 10px 20px;
  word-wrap: break-word;
  line-height: 25px;

  .update-heading {
    display: flex;
    text-transform: uppercase;
    color: #a4a9ac;
    font-weight: bold;
    font-size: 14px;
  }

  .update-title {
    font-weight: bold;
    font-size: map-get($text-size, content-h1-subtitle);
  }

  .update-tab-body {
    font-size: map-get($text-size, content-body-subtitle);
    a {
      text-decoration: underline;
    }
    line-height: 22px;
    word-wrap: break-word;
    &-content-readmore {
      a {
        text-decoration: none !important;
      }
    }
    @media (min-width: $screen-lg-min) {
      display: flex;
      justify-content: space-between;
      &-content {
        display: flex !important;
        flex-direction: row;
        overflow: hidden;
        white-space: nowrap;
        display: block;
      }
      &-content-text {
        width: 75%;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
      }
      &-content-readmore {
        display: block !important;
        width: 25% !important;
      }
    }
    @media (min-width: $screen-md-min) {
      &-content-readmore {
        display: none;
      }
    }
    @media (min-width: $screen-sm-min) {
      &-content-readmore {
        display: none;
      }
    }
  }
  .update-tab-link-content {
    min-width: 105px;
  }
  .update-tab-link {
    color: $text-secondary-color;
  }
  .update-tab-link:hover {
    color: $text-secondary-color;
    font-weight: bold;
    text-decoration: underline;
  }
  .update-tab-link-selected:hover {
    color: black;
    font-weight: bold;
    text-decoration: underline;
  }
}

.update-tab-content {
  @extend .update-tab-content-base;
  color: $title-tertiary-color;
  height: 100%;
}

.update-tab-content-selected {
  @extend .update-tab-content-base;
  color: black;
}

.update-tab-link-selected {
  color: black;
}
