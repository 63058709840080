@import '../../abstract/variables';
@import '../../abstract/mixins';

.status-item {
  &-title {
    font-size: map-get($text-size, content-h2);
    font-weight: bold;
    text-transform: uppercase;
    color: $title-tertiary-color;
    margin-bottom: 6px;
    display: inline-block;
  }

  &-status {
    display: inline-block;
    color: $label-primary-color;
    font-size: map-get($text-size, content-body);
    font-weight: bold;
    padding: 3px 16px;
    border-radius: 20px;

    &-1 {
      @extend .status-item-status;
      background-color: $drought-status-bg-1;
    }
    &-2 {
      @extend .status-item-status;
      background-color: $drought-status-bg-2;
    }
    &-3 {
      @extend .status-item-status;
      background-color: $drought-status-bg-3;
    }
    &-4 {
      @extend .status-item-status;
      background-color: $drought-status-bg-4;
    }
    &-dfri-1 {
      @extend .status-item-status;
      background-color: $drought-flood-risk-1;
    }
    &-dfri-2 {
      @extend .status-item-status;
      background-color: $drought-flood-risk-2;
    }
    &-dfri-3 {
      @extend .status-item-status;
      background-color: $drought-flood-risk-3;
    }
    &-dfri-4 {
      @extend .status-item-status;
      background-color: $drought-flood-risk-4;
    }
    &-dfri-5 {
      @extend .status-item-status;
      background-color: $drought-flood-risk-5;
    }
    &-dfri-6 {
      @extend .status-item-status;
      background-color: $drought-flood-risk-6;
    }
    &-dfri-7 {
      @extend .status-item-status;
      background-color: $drought-flood-risk-7;
    }

    &-container {
      margin-bottom: 12px;
    }
  }

  &-desc {
    font-size: map-get($text-size, content-body);
    color: $text-secondary-color;
    margin-bottom: 12px;
  }
}

@include sm {
  .status-item {
    &-title {
      font-size: map-get($mobile-text-size, content-h1);
      font-weight: bold;
      text-transform: uppercase;
      color: $title-tertiary-color;
      margin-bottom: 5px;
    }
    &-status {
      font-size: map-get($mobile-text-size, content-body);
      &-container {
        margin-bottom: 15px;
      }
    }

    &-desc {
      font-size: map-get($mobile-text-size, content-body);
    }
  }
}
