@import '../../abstract/variables';
@import '../../abstract/mixins';

@include sm {
  .favourites-page {
    min-height: calc(100vh - 190px);
    padding: 40px 18px;
  }
  .favourites {
    &-title {
      @include componentTitle;
    }
    &-header {
      display: flex;
    }

    &-list {
      color: $title-tertiary-color;
      font-size: 16px;
      overflow-y: auto;
      max-height: 90vh;
      padding-left: 5px;
      margin-top: 15px;

      &-item {
        height: 56px;
        border-top: solid 1px #edeeee;
        display: flex;
        align-items: center;
        color: $text-secondary-color;

        span {
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          display: block;
        }

        &-button {
          border: none;
          background-color: inherit;
          transform: rotate(-180deg);
          height: 30px;
        }

        &-remove {
          &-button {
            border: none;
            background-color: inherit;
            height: 40px;
          }
          &-icon {
            padding-bottom: 5px;
            img {
              filter: invert(82%) sepia(1%) saturate(1151%) hue-rotate(159deg) brightness(86%)
                contrast(78%);
              width: 16px;
              height: 19px;
            }
          }
        }

        &-arrow {
          fill: $primary-color;
          height: 100%;
        }
      }

      &-type {
        margin-bottom: 15px;
        span {
          text-transform: capitalize;
          font-size: 16px;
          color: $text-primary-color;
          font-weight: bold;
        }
      }

      &-group {
        padding-bottom: 30px;
      }

      &-item:last-child {
        border-bottom: solid 1px #edeeee;
      }
    }

    &-subheader {
      display: flex;
      align-items: center;
      &-right {
        display: inline-block;
        padding: 5px;
        text-align: end;
        padding-bottom: 10px;
      }
    }

    &-button {
      padding: 0;
      border: none;
      background-color: inherit;
      color: $tertiary-color;
      text-decoration: underline;
      display: inline-block;
      padding-inline-start: 5px;
      padding-inline-end: 5px;

      &:focus {
        outline: none;
      }
    }
  }
}
