@import '../../../abstract/variables';
@import '../../../abstract/mixins';

.selection-btn {
  padding: 10px 20px 10px 20px;
  border-radius: 30px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: white;
  color: $primary-color;
  display: flex;
  align-items: center;
}

.drop-down-content {
  z-index: 10;
  text-transform: uppercase;
  padding: 20px;
  overflow-x: auto;
  border-radius: 10px;
  font-size: 18px;
  max-height: 674px;
  min-width: 100%;
}

.btn-primary {
  border-color: white;
}

.btn-group .btn-primary:first-child {
  border-right-color: $primary-color;
}

.btn-group .btn-primary:last-child {
  border-left-color: $primary-color;
}

.btn-primary-river-data:last-child {
  border-color: $primary-color;
}

.btn-group .btn-primary:not(:first-child):not(:last-child) {
  border-left-color: $primary-color;
  border-right-color: $primary-color;
}

.btn-group > .btn-group:not(:first-child),
.btn-group > .btn:not(:first-child) {
  margin-left: 0px;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle,
.btn-primary:hover {
  background-color: $primary-color;
  color: white;
  border-color: $primary-color;
}

.btn-group-toggle {
  height: 100%;

  &-disabled {
    pointer-events: none;
    background-color: darkgray;
    opacity: 0.4;
    border-radius: 30px;
  }
}

.selection-btns-popup-text {
  position: absolute;
  padding: 5px 10px;
  background: white;
  z-index: 1000;
  box-shadow: $tab-box-shadow;
  border-radius: 15px;
}

@include sm {
  .selection-btn {
    padding: 5px 10px;
    font-size: 14px;
  }

  .drop-down-dams-menu-icon {
    height: 16px;
    margin-left: 6px;
  }

  .drop-down-content {
    min-width: unset;
  }
}
