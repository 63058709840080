@import '../../../abstract/variables';
@import '../../../abstract/mixins';

.error-modal {
  &-container {
    position: absolute;
    width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
    top: 0;
    z-index: 1001;
    transition: display 0.5s;
  }

  &-item {
    display: flex;
    margin: 0 auto;
    width: 400px;
    border-radius: 10px;
    background: white;
  }

  &-content {
    padding: 20px 30px;
  }

  &-header {
    @include spaceBetween;
    font-size: 24px;
    color: $text-primary-color;
    font-weight: bold;
  }

  &-title {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.17;
    color: $text-primary-color;
  }

  &-body {
    padding: 20px 0;
  }

  &-refresh-btn {
    span {
      cursor: pointer;
      color: -webkit-link;

      &:hover {
        text-decoration: underline;
        font-weight: bold;
      }
    }
  }
}
