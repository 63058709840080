@import '../../abstract/variables';
@import '../../abstract/mixins';

.allocation-row {
  background-color: #fafafa;
  display: flex;
  padding-left: 1.2em;
  min-height: 35px;
  border-radius: 10px 0 0 10px;
  margin-bottom: 8px;
  cursor: pointer;
}

.allocation-row:last-child {
  margin-bottom: 0px;
  border-radius: 10px 10px 10px 10px;
}

.allocation-row div:nth-child(2) {
  background-color: #fafafa;
}
.allocation-row div:nth-child(3) {
  background-color: #fafafa;
  border-right: 30px solid white;
}

.allocation-row-selected:last-child {
  margin-bottom: 0px;
}

.div-container {
  display: flex;
}

.div-container1 {
  display: flex;
}

.div-header,
.div-left-container {
  width: 62%;
}

.allocation-row-selected {
  display: flex;
  margin-bottom: 8px;
  background-color: rgba(0, 84, 166, 0.1);
  border-radius: 10px 0 0 10px;
  padding-left: 1.2em;
  min-height: 35px;
  font-weight: bold;
  transition: background 0.5s;
}

.div-header1 {
  display: flex;
  width: 62%;
  text-transform: uppercase;

  #{&}-title-icon {
    fill: $primary-color;
  }

  #{&}-title-text {
    margin-right: 12px;
    text-transform: uppercase;
  }

  #{&}-title {
    width: 40%;
    color: $text-primary-color;
    padding: 10px 20px 5px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
}

.div-perc {
  max-width: 137px;
  text-align: right;
}

.div-usage {
  max-width: 143px;
  text-align: right;
}

.div-left-container {
  width: 62%;
}

.allocation-info {
  padding-right: 10px;
  width: 38%;
  background-color: rgba(0, 84, 166, 0.1);
  border-radius: 0 15px 15px 0;

  &-title {
    width: 40%;
    padding-top: 0.4rem;
  }

  &-container {
    margin-left: 22px;
    margin-bottom: 20px;
    margin-top: 40px;
    width: 90%;

    &-title {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      font-weight: bold;
      padding-bottom: 16px;

      &-text {
        margin-right: 12px;
      }
    }
    &-subtitle {
      color: $text-secondary-color;
      font-weight: bold;
      font-size: 12px;
      text-decoration: underline;
      font-style: italic;

      &:hover {
        cursor: pointer;
        text-decoration: none;
      }
    }
    &-note {
      margin-top: 16px;
      color: $text-secondary-color;
      font-size: 12px;
      font-style: italic;
    }
    &-url {
      padding-top: 16px;
    }
  }
}

.allocation-link-item {
  color: $text-primary-color;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
}

.div-allocation-table {
  margin-top: 10px;
}

.div-header1-title-icon-modal {
  &-title {
    font-size: map-get($text-size, content-h1);
  }

  &-content {
    font-size: map-get($text-size, content-body);
    color: black;
    font-weight: normal;
  }
}

.allocation-modal {
  &-title {
    color: $text-primary-color;
    font-weight: bold;
    margin-bottom: 10px;
    font-size: map-get($text-size, content-h1);
  }

  &-body {
    font-weight: normal;
    font-size: map-get($text-size, content-body);
    margin-bottom: 20px;
  }
}

.update-null-state {
  margin-top: 20px;
  color: $text-secondary-color;
  font-size: map-get($mobile-text-size, page-h2);
}

@media (max-width: $screen-sm-min) {
  .div-allocation-table {
    border-top: 1px solid rgba($color: #808080, $alpha: 0.5);
    width: 100%;
  }
  .div-container {
    display: flex;
    align-items: left;
  }
  .div-header1 {
    display: flex;
    width: 100%;
    padding: 10px 5px 20px 5px;

    #{&}-title {
      font-size: 12px;
      color: $title-secondary-color;
      padding: 0;
      text-transform: none;
      flex-direction: column;
      align-items: flex-start;
      margin-top: auto;
      margin-bottom: auto;
    }
    #{&}-title-text {
      margin-right: 0px;
      text-transform: none;
      order: 1;
    }

    #{&}-title-icon {
      fill: $title-secondary-color;
    }

    .info-button {
      padding: 0px;
    }

    .rest-header {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  .allocation-info {
    transition: all 0.35s;
    display: block;
    width: 100%;
    background-color: #f7fafc;
    border-radius: 0 0 15px 15px;
    font-size: 13px;
    margin-bottom: 5px;

    &-container {
      width: 100%;
      margin: 0px;
      padding: 10px;
      &-subtitle {
        font-size: 12px;
      }

      &-title {
        font-weight: bold;
        padding-bottom: 10px;
      }

      &-url {
        padding-top: 15px;
      }

      &-body {
        padding-top: 5px;
      }
    }
    &-title {
      font-size: 13px;
      padding: 0;
      span {
        height: 100%;
        display: inline-flex;
        align-items: center;
      }
    }
  }

  .div-header,
  .div-left-container {
    width: 100%;
  }

  .allocation-row-header {
    display: flex;
    background-color: #edeeee;
    width: 100%;
    font-size: 15px;
    min-height: 35px;
    border-radius: 10px 10px 10px 10px;
  }

  .allocation-row-header .expandBox-title-container-textContainer {
    width: 85%;
  }

  .allocation-row-header .expandBox-title-container {
    padding: 5px;
  }

  .allocation-row {
    display: flex;
    background-color: #edeeee;
    cursor: pointer;
    width: 100%;
    text-align: left;
    font-size: 15px;
    padding-left: 0.2em;
    min-height: 35px;
    border-radius: 10px 10px 10px 10px;
    margin-bottom: 5px;
  }

  .allocation-header-selected {
    color: $text-primary-color;
    background-color: #e5edf6;
    font-weight: bold;
    border-radius: 10px 10px 0px 0px;
  }

  .allocation-row:last-child {
    margin-bottom: 0px;
    border-radius: 0;
  }

  .allocation-row-selected div:first-child,
  .allocation-row div:first-child {
    width: 50%;
  }

  .allocation-row div:nth-child(2),
  .allocation-row-selected div:nth-child(2) {
    width: 35%;
    background-color: unset;
  }

  .allocation-row div:last-child,
  .allocation-row-selected div:last-child {
    width: 15%;
    background-color: unset;
  }

  .allocation-row div:nth-child(3) {
    border-right: 0;
  }
  .div-header1 div:nth-child(2),
  .div-header1 div:nth-child(3) {
    text-align: left;
    width: 28%;
    padding-right: 10px;
  }

  .div-header1-title-icon-modal {
    &-title {
      font-size: map-get($mobile-text-size, content-h1);
    }

    &-content {
      font-size: map-get($mobile-text-size, content-body);
    }
  }

  .allocation-modal {
    &-title {
      font-size: map-get($mobile-text-size, content-h1);
    }

    &-body {
      font-size: map-get($mobile-text-size, content-body);
    }
  }
  .allocation-body-arrow {
    fill: black;
  }
  .allocation-body-arrow-selected {
    fill: $primary-color;
  }
  .update-null-state {
    margin-top: 0px;
    color: $text-secondary-color;
    font-size: 14px;
  }
}
