@import '../../abstract/variables';
@import '../../abstract/mixins';

.update-container {
  margin-top: 533px;
}

.archived-updates {
  &-result-title {
    margin-top: 30px;
    font-size: 20px;
    font-weight: bold;
    color: $primary-color;
    text-transform: uppercase;
  }
}

@include sm {
  .archived-updates {
    &-body {
      max-width: 320px;
    }
    .update-item {
      max-width: unset;
      margin-right: 0px;
    }
  }
}
