@import '../../abstract/variables';
@import '../../abstract/mixins';

.snapshot-body {
  padding-bottom: 30px;

  .data-summary {
    background-color: #e5edf6;
    &-arrow {
      fill: #0054a6;
    }
    &-content {
      padding: 20px 20px 20px 20px;
    }

    &-non-clickable {
      .expandBox-title-container {
        cursor: default !important;
      }
    }
  }

  .expandBox {
    &-container {
      .data-summary {
        background: #edeeee;
      }
      .data-summary-selected {
        background: #e5edf6;
        .snapshot-body-header-left {
          color: #0054a6;
        }
      }
    }
    &-title-container {
      cursor: pointer;
      flex-wrap: unset;

      &-textContainer {
        width: 100% !important;
      }
    }
  }

  &-subheader {
    display: flex;
    align-items: center;
    &-right {
      display: inline-block;
      padding: 5px;
      text-align: end;
      padding-bottom: 10px;
    }
  }

  &-button {
    padding: 0;
    border: none;
    background-color: inherit;
    color: $tertiary-color;
    text-decoration: underline;
    display: inline-block;
    padding-inline-start: 5px;
    padding-inline-end: 5px;

    &:focus {
      outline: none;
    }
  }
  .spinner-wrapper {
    position: static;
    margin-top: 100px;
  }

  &-search-box {
    background: white;
    border: solid 0.8px $primary-color;
    border-radius: 10px;
    width: 40vw;
    height: 45px;
    font-size: map-get($text-size, content-body);
    background-image: url('../../image/icons/searchIcon.svg');
    background-repeat: no-repeat;
    padding-left: 40px;
    background-size: 25px;
    background-position: 8px;
    margin: 20px 0px;

    &:focus {
      outline: none;
    }
  }

  &-header {
    display: flex;
    color: $text-secondary-color;
    align-items: center;

    &-left {
      display: flex;
      align-items: center;
      font-size: map-get($text-size, 'content-body');
      width: 45%;

      .view-details a {
        margin-left: 8px;
        font-size: map-get($text-size, 'content-body-subtitle');
        font-weight: normal;
        color: $primary-color;
      }
    }
    &-right {
      display: flex;
      justify-content: flex-end;
      text-align: end;
      width: 54%;

      &-container {
        display: flex;
        width: 25%;
        align-items: center;
        justify-content: flex-end;
      }

      &-title {
        font-weight: normal;
      }

      &-value {
        font-weight: normal;
        text-align: right;
        // white-space: pre;
      }
    }
  }

  &-content {
    margin-bottom: 40px;

    &-title {
      display: flex;
      border-bottom: 1px solid #707070;

      &-item:first-child {
        width: 20%;
        color: #0054a6;
        text-align: start;
        justify-content: flex-start;
      }
      &-item {
        justify-content: center;
        display: flex;
        text-align: end;
        width: 10%;
        padding: 5px;

        .col-5 {
          text-align: left;
        }

        &-header {
          font-weight: bold;
        }
      }

      &-item-drought {
        width: 100%;
        color: #0054a6;
        text-align: start;
        justify-content: flex-start;
      }

      &-icon {
        fill: $primary-color;
        width: 16px;
        height: 16px;
        margin-bottom: 5px;
      }
    }
    &-value {
      display: flex;

      &-item {
        color: #4b555a !important;
        font-weight: normal;
        padding: 5px;
      }

      &-item-drought {
        padding: 5px 0px 0px 0px;
        width: 100% !important;
      }

      &-bar {
        padding: 7px 15px;
        width: 45%;
      }
    }
  }

  &-table {
    width: 100%;
    font-size: map-get($text-size, 'content-body');

    &-bar {
      width: 50% !important;
      padding-left: 5vw;
    }

    &-header {
      display: flex;
      align-items: flex-end;
      width: 98%;
      padding: 22px;

      &-left {
        font-size: map-get($text-size, 'content-body');
        width: 45%;
      }

      &-right {
        display: flex;
        text-align: end;
        align-items: flex-end;
        width: 54%;
        justify-content: flex-end;

        &-title {
          width: 25%;
          padding: 0px 10px;
        }
      }
    }
  }
}

.data-summary {
  background: #e5edf6;
}

@include md {
  .snapshot-body {
    padding-bottom: 30px;

    &-table-header {
      font-size: 14px;
    }

    &-content {
      &-value-bar {
        width: 35%;
      }
      &-title-item {
        width: 15%;
      }
    }

    .expandBox {
      &-title-container {
        padding: 16px;
      }
      &-title-expand {
        margin-bottom: 0px;
      }
    }
  }
}

@include sm {
  .snapshot-body {
    min-height: 70vh;
    &-header {
      display: grid;
      &-left {
        display: flex;
        align-items: center;
        width: 100%;

        &-title {
          box-sizing: border-box;
          width: 100%;
          font-size: 16px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        font-size: map-get($mobile-text-size, 'content-body-subtitle');

        .view-details a {
          margin-left: 8px;
          font-size: map-get($mobile-text-size, 'content-body-subtitle');
          font-weight: normal;
          color: $primary-color;
        }
      }
      &-right {
        font-size: 13px;
        text-align: start;
        align-items: flex-start;
        width: 100%;
        justify-content: flex-start;
        margin-top: 10px;

        &-container {
          padding: 0px;
          padding-right: 5px;
          align-items: flex-start;
          justify-content: left;
          width: 33.333%;
        }
        &-value {
          text-align: left;
        }
      }
    }

    .expandBox {
      &-title-container {
        padding: 20px 10px;

        &-textContainer {
          width: 90% !important;
        }
      }
    }

    &-search-box {
      width: 100%;
    }

    &-table {
      width: 100%;
      font-size: map-get($mobile-text-size, 'content-body-subtitle');
      &-header {
        font-size: 14px;
        &-right-title {
          width: 30%;
        }
      }
    }
    .data-summary {
      &-content {
        padding: 5px 13px 5px 10px;
      }
    }

    &-content {
      margin-bottom: 30px;
      &-value-bar {
        width: 35%;
      }
      &-title-item {
        width: 25%;
      }
      &-title {
        &-item:first-child {
          width: 50%;
        }
        &-item-drought {
          padding: 5px 0px 0px 0px;
          width: 100% !important;
        }
      }
    }
  }
}
