@import '../../abstract/variables';
@import '../../abstract/mixins';
.spinner-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  top: 0;
  z-index: 500;
}
.spinner-wrapper-static {
  position: static;
  width: 100%;
  display: flex;
  align-items: center;
  top: 0;
  z-index: 500;
}

.map-spinner-item {
  display: flex;
  margin: 0 auto;
}
