@import '../../abstract/variables';
@import '../../abstract/mixins.scss';

.plan-search-result {
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: $page-margin-general;

  &::-webkit-scrollbar {
    width: 14px;
  }

  &::-webkit-scrollbar-thumb {
    border: 2px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 5px;
    background-color: rgb(148, 147, 147);
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  &-container {
    width: 100%;
  }

  &-section-header,
  &-plans {
    position: relative;
    top: 100;
  }

  &-section-header {
    width: 100%;
    font-weight: bold;
    font-size: map-get($text-size, content-h2);
    color: $label-primary-color;
    background-color: $primary-color;
    padding: 18px 22px;
    border-radius: 10px;
    margin-top: 25px;
    margin-bottom: 18px;

    &:first-child {
      margin-top: 0;
    }
  }

  &-plans {
    display: block;
    margin-bottom: 15px;
    padding: 0px 22px;
    color: $text-primary-color;
    text-decoration: underline;
    line-height: 1.14;
    font-size: map-get($text-size, content-body);
  }

  &-fail {
    padding: 12px;

    &-title {
      font-size: map-get($text-size, content-h1);
      color: $text-primary-color;
      font-weight: bold;
    }

    &-msg {
      color: $text-secondary-color;
      font-weight: bold;
    }

    &-reload {
      border: none;
      background-color: inherit;
      padding: 0;
      color: $tertiary-color;
      font-weight: bold;
      text-decoration: underline;
      margin-top: 6px;
    }
  }
  &-text {
    font-size: map-get($text-size, content-h2);
    color: $text-secondary-color;
    font-weight: bold;
    padding: 18px;
    @include sm {
      text-align: center;
      padding: 0;
    }
  }
  &-disabled {
    cursor: default;
    color: $text-secondary-color;
    text-decoration: none;

    &:hover {
      color: $text-secondary-color;
      text-decoration: none;
    }
  }
}

@include sm {
  .plan-search-result {
    &-container {
      .expandBox-title-container-textContainer {
        width: 80% !important;
      }
    }
    &-section-header {
      font-size: map-get($mobile-text-size, content-h1);
      padding: 12px 22px;
      margin-top: 20px;
      margin-bottom: 15px;
    }

    &-plans {
      margin-bottom: 24px;
      padding: 0px 10px;
      font-size: map-get($text-size, content-body);
    }

    &-fail {
      &-title {
        font-size: map-get($mobile-text-size, content-h1);
      }
    }

    &-text {
      font-size: map-get($mobile-text-size, content-body);
      text-align: center;
      padding: 0;
    }

    &-mobile-header {
      margin: 0;
    }

    &-section-header {
      font-size: map-get($mobile-text-size, content-h2);

      .expandBox-title-container {
        padding: 0px;
      }
    }
  }
}
