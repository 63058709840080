@import '../../../../../abstract/variables.scss';
@import '../../../../../index.scss';

.graph-and-download-graph-item {
  &-header {
    .toggle-button {
      display: flex;
      align-items: center;
      justify-content: center;
      &-button {
        height: 33px;
        font-size: 14px;
      }
    }
    
    &-label {
      color: $primary-color;
      font-size: map-get($text-size, 'content-body');
      font-weight: bold;
    }
    &-btn {
      padding: 5px 10px;
      background-color: unset;
      border: 2px solid $primary-color;
      border-radius: 5px;
      color: $primary-color;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      min-width: 80px;
      a {
        display: block !important;
      }

      &-hidden {
        background-color: #c9c9c9 !important;
        border: 1px solid #c9c9c9 !important;
        pointer-events: none;
        color: white;
      }
    }
  }
  &-header:nth-child(n) {
    margin-top: 30px;
  }
  &-header:first-child() {
    margin-top: 10px;
  }
}
