@import '../../../../abstract/variables';
@import '../../../../abstract/mixins';

.download-check-box {
  &.waterSource {
    overflow-y: scroll;
  }

  &-option {
    line-height: 130%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &-hide {
      display: none;
    }

    &-btn {
      cursor: pointer;
      margin-right: 15px;
      accent-color: $primary-color;
    }

    &-lbl {
      cursor: pointer;
      color: $title-tertiary-color;
      margin: unset;
    }

    &:last-child {
      margin-bottom: 0px;
    }

    &.selected-option {
      .download-check-box-option-lbl {
        color: $primary-color;
      }
    }
  }
}
