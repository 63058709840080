@import '../../abstract/variables';
@import '../../abstract/mixins';

.salinity-body {
  min-height: 310px;
  display: flex;
  &-scheme-title {
    font-size: 24px;
    color: $text-primary-color;
    text-transform: uppercase;
    font-weight: bold;
    text-size-adjust: auto;
    margin-bottom: 30px;
  }

  &-reports {
    width: 100%;
  }
  &-reports-item {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;

    a {
      color: $text-primary-color;
      font-weight: normal;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
  &-msg {
    font-style: italic;
  }

  &-dropdown {
    .general-dropdown {
      margin-bottom: 20px;
      .expandBox-container {
        width: 110px;
      }
    }
    .general-dropdown-menu {
      width: 150px;
      max-height: 300px;
    }
  }
}

@include sm {
  .salinity-body {
    min-height: unset;
    &-scheme-title {
      font-size: 18px;
    }
    .row {
      flex-wrap: nowrap;
    }
    .general-dropdown {
      .expandBox-container {
        width: 120px;
      }
      &-button {
        color: white;
        background-color: $primary-color;
      }
      &-menu-item {
        min-width: 100px;
      }
    }
  }
}
