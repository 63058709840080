@import '../../abstract/variables';
@import '../../abstract/mixins';

.data-summary-bar {
  &-wrapper {
    height: 20px;
    width: 100%;
    border-radius: 5px;
    display: flex;
    overflow: hidden;
  }

  &-full {
    width: 100%;
  }

  &-half {
    width: 50%;
  }

  &-quarter {
    width: 25%;
  }

  &-border {
    border: 1px solid #707070;
  }

  &-noBorder {
    border: none;
  }

  &-cell {
    height: 100%;
    &-noBorder {
      border-radius: 0px 5px 5px 0px;
    }
  }
}
