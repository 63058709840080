@import '../../../abstract/variables';
@import '../../../abstract/mixins';

.ivt-applications-table {
  height: 100%;
  margin-top: 15px;

  &-component {
    font-size: 14px;
  }

  table {
    table-layout: fixed;
    width: 100%;
  }

  thead {
    display: table-header-group;
  }

  &-app-id {
    padding: 0.5em 2.8em !important;
  }
  th {
    border-bottom: 1px solid $title-tertiary-color;
    color: $title-tertiary-color;
  }

  th,
  td {
    text-align: right;
    padding: 0.5em 0.5em;
  }

  tbody td {
    border-bottom: 1px solid $title-secondary-color;
  }

  tbody > tr:hover {
    background: $secondary-color;
  }

  tr > th:first-child,
  tbody td:first-child,
  tbody td:first-child:hover {
    background: none !important;
  }

  tr > td,
  tr > th {
    width: 10%;
    overflow-wrap: break-word;
  }
  tr > th:first-child {
    width: 1%;
  }
  tr > th:last-child {
    width: 1%;
  }
  tr > td:nth-child(2),
  tr > th:nth-child(2) {
    text-align: left !important;
  }

  tr > th:nth-child(2),
  tr > td:first-child {
    width: 10%;
  }

  thead th:nth-child(2) {
    color: $text-primary-color;
  }

  &-title {
    display: flex;
    align-items: center;
    margin: 32px 0px 6px 0px;
    &-text {
      font-size: map-get($text-size, content-h2);
      color: $text-primary-color;
      font-weight: bold;
    }

    &-icon {
      fill: $primary-color;
      width: 16px;
      height: 16px;
    }
  }
}

@include md {
  .ivt-applications-table {
    font-size: 14px;

    tr > td,
    tr > th {
      width: 12.5%;
    }

    tr > td:nth-child(2),
    tr > th:nth-child(2) {
      width: 25%;
    }

    &-dam {
      padding: 0.5em 2em !important;
    }

    &-title {
      display: flex;
      align-items: center;
      margin: 32px 0px 6px 0px;
      &-text {
        font-size: map-get($text-size, content-h2);
        color: $text-primary-color;
        font-weight: bold;
      }

      &-icon {
        fill: $primary-color;
        width: 16px;
        height: 16px;
      }
    }
  }
}

@include sm {
  .ivt-applications-table {
    overflow: auto;
    width: auto;

    table {
      width: auto;
      max-height: 300px;
    }
    thead th {
      position: -webkit-sticky; /* for Safari */
      position: sticky;
      top: 0;
    }
    tr > th:first-child {
      text-align: left;
    }
    tbody th,
    tbody td:first-child {
      position: -webkit-sticky; /* for Safari */
      position: sticky;
      left: 0;
    }

    thead th:first-child {
      color: $text-primary-color;
      left: 0;
      z-index: 2;
    }

    thead th:nth-child(2) {
      color: $title-tertiary-color;
    }

    tbody > tr:hover {
      background: none;
    }

    thead th,
    tbody td:first-child {
      background: $secondary-color;
      border-bottom: 1px solid white;
      border-right: 1px solid white;
      z-index: 1;
    }

    tbody th {
      background: #fff;
      border-right: 1px solid #ccc;
      box-shadow: 1px 0 0 0 #ccc;
    }

    table {
      border-collapse: collapse;
    }

    td,
    th {
      padding: 0.5em;
    }
    &-title {
      display: flex;
      align-items: center;
      margin: 32px 0px 6px 0px;
      &-text {
        font-size: map-get($text-size, content-h2);
        color: $text-primary-color;
        font-weight: bold;
      }

      &-icon {
        fill: $primary-color;
        width: 16px;
        height: 16px;
      }
    }
  }
}
