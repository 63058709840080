@import '../../abstract/variables';
@import '../../abstract/mixins';

.general-link {
  font-size: map-get($text-size, content-body);
  color: $text-primary-color;
  text-decoration: underline;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  column-gap: 5px;
  svg.svg-inline--fa {
    height: 16px;
    width: 16px;
  }
}

.general-link:hover {
  color: $text-primary-color;
  text-decoration: underline;
  font-weight: bold;
}

@include sm {
  .general-link {
    font-size: map-get($mobile-text-size, content-body);
  }
}
