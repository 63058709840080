@import '../../abstract/variables';
@import '../../abstract/mixins';

.updates {
  &-empty {
    &-container {
      margin: 20px 0px;
      text-align: center;
      display: flex;
      align-items: center;
    }
    &-item {
      &-icon {
        fill: $text-secondary-color;
        width: 100px;
        height: 100px;
      }

      &-text {
        color: $text-secondary-color;
        font-weight: bold;
        font-size: map-get($text-size, content-h1);
      }
    }
  }
  .updates-title {
    @include spaceBetween;
    margin-bottom: 21.8px;
  }

  .all-updates-link {
    color: $text-primary-color;
    text-decoration: underline;
    align-self: flex-end;
  }

  #{&}-container {
    @include spaceBetween;
    @media (min-width: $screen-lg-min) {
      height: 345px;
    }
  }

  #{&}-left {
    width: 100%;
  }

  @media (min-width: $screen-lg-min) {
    #{&}-left {
      width: 50%;
    }
  }

  #{&}-right {
    width: 0%;
    height: 100%;
    background: lightgray;
    overflow: hidden;
  }

  @media (min-width: $screen-lg-min) {
    #{&}-right {
      width: 50%;
      height: 100%;
      background: lightgray;
      overflow: hidden;
    }
  }

  #{&}-img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
  }
}

@include sm {
  .updates {
    &-empty {
      &-container {
        flex-direction: column;
      }

      &-item {
        &-icon {
          width: 50px;
          height: 50px;
        }

        &-text {
          width: 300px;
          font-size: map-get($mobile-text-size, content-h1);
        }
      }
    }
  }
}
