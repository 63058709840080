@import '../../abstract/variables';
@import '../../abstract/mixins';

.map-pin-location {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 25px;
  z-index: 400;
  display: block;
  padding: 18px 30px;
  width: 400px;
  height: 100px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  color: $text-secondary-color;
  pointer-events: auto;

  &-address {
    margin: 0 auto;
    font-size: 16px;
  }

  &-coordinates {
    font-size: 12px;
  }

  .disabled {
    pointer-events: none;
    filter: sepia(100%);
    a {
      text-decoration: none;
    }
  }

  .loading-bar {
    background: white;
    height: 48px;
    width: 100%;
    position: relative;
  }
  .loading-bar::after {
    content: '';
    position: absolute;
    height: 100%;
    background-image: linear-gradient(to right, white, #ededed);
    animation: progress 1.5s infinite ease-in-out;
  }

  @keyframes progress {
    0% {
      left: 0;
      width: 0;
    }
    50% {
      width: 100%;
    }
    100% {
      right: 0;
      width: 0;
    }
  }

  &-err-msg {
    font-style: italic;
    font-size: 14px;
    height: 48px;
  }

  &-navigate {
    flex-direction: column;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;

    &-icon {
      display: inline-block;
    }

    img {
      width: 35px;
    }
    a {
      display: block;
      font-size: 16px;
      text-decoration: underline;
      color: $text-primary-color;
    }
  }

  &-close {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 30px;
  }
}

@include sm {
  .map-pin-location {
    width: 80%;
    height: unset;
    bottom: 20px;
    right: 15%;
    padding: 10px 20px;

    &-close {
      font-size: 22px;
      top: 10px;
      right: 15px;
    }

    &-address {
      font-size: 14px;
    }

    &-coordinates {
      font-size: 12px;
    }

    &-navigate {
      img {
        width: 30px;
      }
      a {
        font-size: 14px;
      }
    }

    &-err-msg {
      height: 41.5px;
    }

    .loading-bar {
      height: 41.5px;
    }
  }
}
