@import '../../../../../abstract/variables';
@import '../../../../../abstract/mixins';

.station-download-filters {
  margin-top: 10px;
  padding: 12px;
  padding-bottom: 0px;
  border-radius: 10px;
  background-color: #fafafa;

  &-title {
    color: #4b555a;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    .graph-and-download-dropdown {
      font-weight: normal;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

}
