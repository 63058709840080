@import '../../abstract/variables';
@import '../../abstract/mixins';

.legend-surface {
  border-radius: 5px;
}

.legend-item {
  margin-right: 15px;
}

.legend-label {
  margin-left: map-get($mobile-text-size, content-body);
}

.customized-legend {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

@include sm {
  .legend-label {
    margin-left: 0px;
    font-size: map-get($mobile-text-size, content-body-subtitle);
  }
  .legend-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
