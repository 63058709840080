@import '../../abstract/variables';
@import '../../abstract/mixins';

.accounting-rules {
  border-radius: 10px;
  padding-top: 1.8rem;

  &-title {
    display: flex;
    font-weight: bold;
    color: $label-primary-color;
    background: $primary-color;
    border-radius: 10px;
    font-size: map-get($text-size, content-h1);

    span {
      padding: 10px 0 10px 14px;
      @include textVerticalCenter;
    }
  }

  &-body {
    padding: 40px 15px 20px 15px;
    height: auto;

    &-licence {
      color: $text-primary-color;
      font-weight: bold;
      font-size: map-get($text-size, content-h2);

      &-arrow {
        fill: $primary-color;
      }
    }

    .expandBox {
      &-container {
        padding: 10px 0px;
        &:first-of-type {
          padding-top: 0px;
        }
        border-bottom: 2px solid #edeeee;
        border-radius: 0px;
      }

      &-title-container {
        padding: 6px 0px;
      }
    }

    &-rules {
      padding: 4px 0px;

      &-title {
        color: $text-secondary-color;
        font-weight: bold;
      }

      &-content {
        color: $text-secondary-color;
        div {
          padding-bottom: 10px;
        }

        div:last-of-type {
          padding-bottom: 0px;
        }
      }
    }
  }
}

@include sm {
  .accounting-rules {
    border-radius: 10px;
    padding-top: 1.8rem;

    &-title {
      font-size: map-get($mobile-text-size, content-h1);
    }

    &-body {
      padding: 20px 15px 0px 15px;
      font-size: map-get($mobile-text-size, content-body);

      .expandBox {
        &-container {
          padding: 10px 0px;
          &:first-of-type {
            padding-top: 0px;
          }
        }
      }
    }
  }
}
