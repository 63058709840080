@import '../../../../abstract/variables';
@import '../../../../abstract/mixins';

.dropdown-toggle::after {
  all: unset;
}

.input-dropdown {
  @include textVerticalCenter;
  margin-top: 11px;
  flex-wrap: wrap;

  &-input {
    pointer-events: auto;
    margin-bottom: 10px;
    background: white;
    border: none;
    border-bottom: solid 0.8px $primary-color;
    width: 100%;
    height: 35px;
    font-size: 16px;
    padding-left: 5px;

    &:focus {
      outline: 0;
    }

    &.archived {
      border: none;
      background-color: #edeeee;
      border-radius: 0;
      background-image: url('../../../../image/icons/searchGray.svg');
      margin-bottom: 10px;
    }
  }

  span {
    display: inline;
  }

  @include sm {
    margin-top: 0px;
  }

  &-btn {
    @include textVerticalCenter;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    pointer-events: auto;
    white-space: normal;
    text-align: left;
  }

  &-menu {
    font-style: map-get($text-size, content-h2);

    &-btn {
      color: $text-primary-color;
      font-size: map-get($text-size, content-h2);
      font-weight: bold;
      line-height: 22px;
    }

    &-icon {
      background: $primary-color;
      border-radius: 5px;
      height: 17px;
      width: 17px;
      margin-left: 11px;
    }

    &-content {
      background: white;
      font-size: 16px;
      overflow-x: auto;
      border-radius: 10px;
      max-height: 55vh;
      color: $text-primary-color;
      line-height: 32px;
      padding: 10px;
      display: block;
      position: unset;
      height: 250px;

      &.archived {
        border: none;
        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          background: white;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $primary-color;
          border-radius: 5px;
        }

        //Fire fox
        scrollbar-width: 10px;
        scrollbar-color: $primary-color white;
      }
    }

    &-item {
      line-height: 20px;
      padding: 8px;
      cursor: pointer;
      align-items: center;
      border-radius: 5px;

      &:hover,
      &:active,
      &:focus {
        background: #f0f0f0;
      }
    }
    &-warning {
      font-style: italic;
      color: $text-secondary-color;
    }
  }
}
