@import '../../abstract/variables';
@import '../../abstract/mixins';

.notification,
.notification-show {
  padding: 30px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  width: 80vw;
  height: auto;
  margin: -20px 10vw;
  font-size: 16px;
  font-weight: bold;
  color: $text-primary-color;
  position: absolute;
  transition: opacity 0.5s linear;
  opacity: 0;
  display: none;
}

.notification-show {
  opacity: 1;
  display: block;
}
