@import '../../abstract/variables';
@import '../../abstract/mixins';

.empty-chart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  &-icon {
    fill: $primary-color;
    width: 4vw;
    height: 4vw;
  }
  &-text {
    margin-top: 12px;
    color: $primary-color;
    font-size: map-get($text-size, page-h1);
  }
}
