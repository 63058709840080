@import '../../abstract/variables';
@import '../../abstract/mixins';

.river-data-summary-table {
  position: relative;
  min-height: 150px;
}

@include sm {
  .river-data-summary-table {
    .data-summary-table-title {
      color: #4b555a;
      .chart-icon {
        padding: 0;
      }
    }
  }
}
