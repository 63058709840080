@import '../../abstract/variables';
@import '../../abstract/mixins';

@mixin scale-label-base {
  height: 10px;
  min-width: 30px;
  display: flex;
  align-items: center;
}
@mixin rectangle($zIndex, $size, $color1: white, $color2: none, $height: none) {
  width: $size;
  height: $height;
  @if $color2 != none {
    background: linear-gradient(to right, $color1 50%, $color2 50%);
  } @else {
    background: $color1;
  }
}

@mixin colorRectangle(
  $color1-1,
  $color2-1,
  $color3-1,
  $color4-1,
  $color5-1,
  $color1-2: none,
  $color2-2: none,
  $color3-2: none,
  $color4-2: none,
  $color5-2: none
) {
  @if $color1-1 != none {
    &-1 {
      @include rectangle(2, 70px);
      &-color {
        @include rectangle(2, 70px, $color1-1, $color1-2, 2px);
      }
    }
  }
  &-2 {
    @include rectangle(3, 70px);
    &-color {
      @include rectangle(3, 70px, $color2-1, $color2-2, 4px);
    }
  }
  &-3 {
    @include rectangle(4, 70px);
    &-color {
      @include rectangle(4, 70px, $color3-1, $color3-2, 8px);
    }
  }
  &-4 {
    @include rectangle(5, 70px);
    &-color {
      @include rectangle(5, 70px, $color4-1, $color4-2, 12px);
    }
  }
  &-5 {
    @include rectangle(6, 70px);
    &-color {
      @include rectangle(6, 70px, $color5-1, $color5-2, 16px);
    }
  }
}

.flow-rate-map-legend {
  width: 372px;
  height: 160px;
  background: white;
  border-radius: 10px;
  position: absolute;
  bottom: 80px;
  right: 50px;
  flex-wrap: wrap;
  z-index: 460;
  padding: 16px 18px;
  font-size: 16px;
  font-weight: bold;

  &-scale {
    font-weight: normal;
    font-size: 12px;

    &-label {
      margin-top: 10px;
      font-size: 14px;
      display: flex;
      justify-content: center;
    }

    &-left {
      height: 20px;
      display: flex;
      &-label {
        @include scale-label-base;
        justify-content: center;
        padding-top: 10px;
        margin-left: 10px;
        margin-right: 32px;
      }
    }

    &-right {
      display: flex;
      align-items: flex-end;
      padding-left: 30px;
      padding-top: 20px;

      &-rectangle {
        hr {
          z-index: 10;
          height: 5px;
          border: 0;
          top: 120px;
          border-right: 1px solid #a4a9ac;
          margin: 0px;
        }
      }

      &-flow-rate {
        @include colorRectangle(
          none,
          $flow-rate-map-legend,
          $flow-rate-map-legend,
          $flow-rate-map-legend,
          $flow-rate-map-legend
        );
      }
    }
  }
}
