@import '../../../abstract/variables';
@import '../../../abstract/mixins';

.data-quality-mobile-legend {
  border-radius: 10px;
  padding: 12px;
  background-color: #fafafa;
  width: 130px;

  &-title {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 4px;
    color: $text-secondary-color;
  }

  &-status {
    &-item {
      display: flex;
      font-size: 12px;
      color: $text-secondary-color;
      align-items: center;
    }
    &-icon {
      border-radius: 25px;
      width: 12px;
      height: 7px;
      margin-right: 8px;
    }

    &-normal {
      @extend .data-quality-mobile-legend-status-item;
      &-icon {
        @extend .data-quality-mobile-legend-status-icon;
        background-color: #707070;
      }
    }

    &-anomaly {
      @extend .data-quality-mobile-legend-status-item;
      &-icon {
        @extend .data-quality-mobile-legend-status-icon;
        background-color: #ef4848;
      }
    }
  }
}
