@import '../../abstract/variables';
@import '../../abstract/mixins';

.historical-data {
  &-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 30px;
    margin-top: 50px;
  }
}
