@import '../../../abstract/variables';
@import '../../../abstract/mixins';

.state-overview-storage {
  &-header {
    &-storage {
      color: $primary-color;
    }
  }
}
