@import '../../../../../abstract/variables.scss';
@import '../../../../../abstract/mixins.scss';

.graph-and-download-graph {
  margin-top: 15px;
  .recharts-text {
    font-size: 14px;
  }
  .disabled {
    pointer-events: none;
    fill: #a4a9ac;
    a {
      color: #a4a9ac;
    }
  }

  &-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-label {
      color: $primary-color;
      font-size: map-get($text-size, 'content-h2');
      font-weight: bold;
    }
    &-btns {
      display: flex;
    }
    &-btn,
    &-btn-primary {
      padding: 5px;
      background-color: unset;
      border: 2px solid $primary-color;
      border-radius: 5px;
      color: $primary-color;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      min-width: 80px;
      a {
        display: block !important;
      }
      &-disabled {
        cursor: default;
        pointer-events: none;
        border: 2px solid $text-secondary-color;
        color: $text-secondary-color;
      }
    }
    &-btn-primary {
      background-color: $primary-color;
      a {
        color: white !important;
      }
    }
  }

  &-empty-msg {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: map-get($text-size, 'content-h2');
    font-weight: bold;
    color: $primary-color;
    text-align: center;
  }

  .spinner-wrapper {
    position: unset;
    height: 300px;
  }
}
