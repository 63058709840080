.disclaimer-button {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.disclaimer-icon {
  cursor: pointer;
  height: 20px;
  width: 20px;
}
