@import '../../abstract/variables';
@import '../../abstract/mixins';

.waternsw-logo {
  display: flex;
  padding: 8px 0px;

  @include sm {
    padding: 8px;
  }
}

.waternsw-logo-svg {
  max-width: 160px;

  @include sm {
    max-width: 130px;
  }
}
