@import '../../abstract/variables';
@import '../../abstract/mixins';

.chart-download-link {
  a {
    color: $text-primary-color;
  }
  font-size: 16px;
  // width: 130px;
  border-radius: 5px;
  padding: 10px;
  margin-right: 0;
  margin-top: 5px;

  &-disabled {
    color: $title-tertiary-color !important;
    pointer-events: none;
  }

  &-disabled-icon {
    fill: $title-tertiary-color !important;
  }
}
