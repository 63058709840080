@import '../../abstract/variables';
@import '../../abstract/mixins';

.water-quality-legend {
  position: absolute;
  bottom: 80px;
  right: 85px;
  border-radius: 10px;
  background-color: white;
  padding: 15px;
  border: solid 1px rgba(0, 0, 0, 0);
  z-index: 500;
  @include sm {
    display: none;
  }
  .close {
    position: relative;
    float: right;
    right: -8px;
    top: -10px;
    right: -5px;
    font-size: 18px;
  }
  .section {
    &-title {
      font-family: Arial;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: var(--black);
      margin-bottom: 5px;
    }
    &-row {
      display: grid;
      grid-template-columns: 0.5fr 4fr !important;
      align-items: center;
      .icon {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .icon-dark-blue {
        background-color: #002a53;
      }
      .icon-light-blue {
        background-color: #398cdd;
      }
      .icon-grey {
        background-color: #7790a8;
      }
      .icon-red {
        background-color: #ff0000;
      }
      .icon-amber {
        background-color: #ffc100;
      }
      .icon-green {
        background-color: #92d150;
      }
      img {
        width: 9px;
        height: 12px;
        padding: 0.5px;
      }
      &-text {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: normal;
        text-align: left;
        margin: 5px;
      }
    }
  }
}
