@import '../../abstract/variables';
@import '../../abstract/mixins';

.river-schematic {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #fafafa;
  &-title {
    fill: #4b555a;
    font-weight: bold;
    font-size: map-get($text-size, 'content-h1');
  }
}
