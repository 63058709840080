@import '../../abstract/variables';
@import '../../abstract/mixins';

@include sm {
  .mobile-map-legend {
    width: 100%;
    .map-legend {
      position: absolute;
      left: 5vw;
      top: 127px;
      width: 87vw;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      border-radius: 10px;
      z-index: 460;
      padding: 8px 0px;

      &-item-wrapper {
        white-space: nowrap;
        padding: 0px 5px;
        font-size: 10px;
      }
    }

    &-arrow {
      $arrow: &;
      width: 24px;
      fill: black;
      cursor: pointer;

      &-left {
        @extend #{$arrow};
      }

      &-right {
        @extend #{$arrow};
        transform: rotate(180deg);
      }

      &-hide {
        opacity: 0;
      }
    }
  }
}
