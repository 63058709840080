@import '../../abstract/variables';
@import '../../abstract/mixins';

@include sm {
  .favourites-mini {
    &-title {
      text-transform: uppercase;
    }

    &-search {
      margin-bottom: 60px;
      .global-search-bar {
        &-input {
          width: 100%;
        }
        &-suggestion {
          width: 100%;
        }
      }
    }

    &-button {
      padding: 0;
      border: none;
      background-color: inherit;
      color: $tertiary-color;
      text-decoration: underline;
      display: inline-block;
      padding-inline-start: 5px;
      padding-inline-end: 5px;

      &:focus {
        outline: none;
      }
    }
  }
}
