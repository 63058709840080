@import '../../abstract/variables';
@import '../../abstract/mixins';

.allocation-chart-legend {
  padding-left: 10px;
  justify-content: flex-start;
  position: initial;
  display: flex;
  margin-top: 10px;
  max-width: 1200px;

  &-item {
    margin-right: 15px;
    display: flex;
    align-items: center;
  }

  &-item-surface {
    border-radius: 5px;
  }

  &-label {
    font-size: 12px;
    padding-left: 14px;
    width: min-content;
  }
}

@include sm {
  .allocation-chart-legend {
    max-width: 300px;

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-left: 1rem;
    }
    &-label {
      margin-left: 0px;
    }
  }
}
