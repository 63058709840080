@import '../../abstract/variables';
@import '../../abstract/mixins';

// Local Variable
@mixin general-container {
  &-pageTop {
    display: flex;
    align-items: center;
    .info-icon-modal {
      display: inline-block;

      &-icon {
        padding: 0px;
        vertical-align: 0.2em;
      }
    }
  }

  @include lg {
    &-pageTop {
      align-items: flex-start;
      justify-content: flex-start;
      .info-icon-modal {
        display: inline-block;
      }
    }
  }
}

@mixin general-container-mini {
  min-height: calc(100vh - 190px);
  padding: 20px;
}

.body-container {
  &-outer {
    display: flex;
    padding: 40px $page-margin-left-right 50px;
    min-height: 80vh;

    @include sm {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding: 32px 20px;
      flex-wrap: wrap;
      overflow-y: scroll;
    }
  }

  &-inner {
    max-width: 1500px;
    width: 100%;

    &-header {
      display: grid;
      // grid-template-columns: 1fr 0.6fr;
      .empty {
        margin: 0px !important;
      }
      @include lg {
        display: flex;
        flex-direction: column;
      }
    }
    &-menu {
      display: flex;
      justify-content: space-between;
      margin: 20px 0px;
      align-items: flex-end;
      @include sm {
        display: block;
        margin: 10px 0px;
      }
    }
  }
}

.update-body {
  @include spaceBetween;
  @include sm {
    .page-body-title {
      margin-bottom: 20px;
    }
  }

  &-right {
    padding-left: max(20px, 3vw);
    @include lg {
      padding-left: 0px;
    }
  }
}

.update-general {
  &-headerContainer {
    margin-bottom: 20px;
  }
  &-header {
    width: 100%;
    border-radius: 10px;
    background: $general-notice;
    font-weight: bold;
    color: $label-primary-color;
    font-size: 24px;
    @include sm {
      font-size: 16px;
    }
  }

  &-body {
    padding-bottom: 32px;

    @include lg {
      width: 100%;
      padding-right: 0px;
      padding-bottom: 0px;
    }
  }

  &-noResults {
    margin-top: 15px;
  }

  &-dropdown {
    margin-top: 10px;
    display: flex;
    &-items {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
  }

  @include sm {
    width: 100%;
    &-noResults {
      color: $text-secondary-color;
      padding: 10px;
      font-size: map-get($mobile-text-size, content-body);
    }
  }
}
// Rainfalls
.rainfall-container {
  @include general-container;
}

// Usage
.usage-container {
  @include general-container;
}

// Flow
.flow-class-container {
  @include general-container;
}

// Rules
.rules-intro-text {
  font-size: map-get($text-size, content-body);
  color: $text-secondary-color;
  margin: 20px 0px;

  &-container {
    margin: 20px 0px 0px 0px;
  }

  &-container:last-child {
    margin: 20px 0px;
  }
}

.rules-intro-wsp {
  color: $text-primary-color;
  font-weight: bold;
}
.rules-intro-wsp:hover {
  color: $text-primary-color;
  font-weight: bold;
}
.rules-pageTop {
  padding-bottom: 20px;
}

.external-link-icon {
  width: 19px;
  padding: 0 0 4px 4px;
}

.rules-container,
.salinity-container {
  @include general-container;
  .body-container-outer {
    @include md {
      padding: 40px 25px 50px;
    }
  }
  &-body {
    width: 100%;
    .station-list {
      flex: 6;
      min-width: 800px;
    }
    &-right {
      margin-top: 15px;
    }
  }
}

.salinity-container {
  &-body {
    display: flex;
    justify-content: space-between;
    &-right {
      padding-left: max(20px, 3vw);
      margin-top: 0px !important;
      @include md {
        padding-left: 0px;
        padding-top: 20px;
      }
    }
    &-left {
      &-legend-container {
        display: flex;
        justify-content: flex-end;

        .data-quality-legend {
          @include data-quality-legend;
        }
      }
    }
  }
  .body-container-inner {
    max-width: 2000px !important;
  }
  .data-refresh-button {
    margin-bottom: 20px;
  }
}

// Allocation
.allocation-container {
  @include general-container;

  &-pageTop {
    flex-direction: column;
    align-items: unset;

    .view-details a {
      font-weight: 400;
      font-size: map-get($text-size, content-body);
      color: $text-primary-color;
      justify-self: flex-end;
      text-transform: initial;
    }
  }
}

.allocation-commentary {
  padding-top: 20px;
}

// Storage
.storage-container {
  .page-top-subIntro-lastItem {
    margin: 20px 0px;
  }

  @include general-container;

  .page-body-title {
    @include sm {
      margin-bottom: unset;
    }
  }
}

.storage-weir-container {
  padding-bottom: 40px;

  &-null {
    display: none;
  }

  @include sm {
    padding-bottom: 32px;
  }

  &-quality-legend {
    display: flex;
    justify-content: flex-end;
    .data-quality-legend {
      @include data-quality-legend();
    }
  }
}

.storage-intro-link {
  color: $text-primary-color;
  text-decoration: underline;
}

// River
.river-aquifer-container {
  @include general-container;

  &-header {
    &-left {
      .toggle-button {
        @include sm {
          button:first-of-type {
            border-radius: 10px 0px 0px 10px;
          }
          button:last-of-type {
            border-radius: 0px 10px 10px 0px;
          }
        }
        &-button {
          max-width: 130px;
          height: auto;
          padding: 5px 8px;
          
          @include sm {
            font-size: 12px;
            button:first-of-type {
              border-radius: 10px 0px 0px 10px;
            }
            button:last-of-type {
              border-radius: 0px 10px 10px 0px;
            }
          }
        }
      }
      &-title {
        margin-bottom: 10px;
        font-size: 14px;
        
        @include sm {
          font-size: 14px;
        }
      }
    }

    &-right {
      align-items: flex-end;

      @include sm {
        float: right;
      }
      
      .general-dropdown {
        max-width: 230px;
        
        &-button {
          background: $primary-color;
          color: white;
        }
        &-menu {
          width: 100% !important;
        }
      }
    
      .expandBox-container {
        width: 190px !important;

        @include sm {
          width: 160px !important;
          // height: 30px;
          margin-top: 10px;
        }
        .expandBox-title-container-textContainer {
          @include sm {
            font-size: 12px !important;
          }
        }
        .expandBox-title-expand-shape {
          @include sm {
            width: 15px;
            height: 12px;
          }
        }
        
      }
      .expandBox-title-container-textContainer {
        width: 80% !important;
      }
      

      @include lg {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      
      .data-refresh-button {
        margin-bottom: 20px;

        @include sm {
          margin-bottom: 0px;
        }

        &-timestamp {
          @include sm {
            text-align: end;
            font-size: 10px;
          }
        }
      }

      &-legend-container {
        display: flex;
        justify-self: flex-end;

        .data-quality-legend {
          @include data-quality-legend;
        }
      }
    }
  }
}

// Research
.research-container {
  @include general-container;
}

// Trade
.trade-container {
  @include general-container;
}

// Pollution
.pollution-container {
  @include general-container;
}

// Losses
.losses {
  @include general-container;

  &-header-icon {
    fill: $text-primary-color;
    width: 24px;
  }

  &-modal {
    &-item {
      margin-bottom: map-get($text-size, content-body);
      color: $text-secondary-color;
      font-size: map-get($text-size, content-body);
    }

    & > :last-child {
      margin-bottom: 0;
    }
  }
}

.roa {
  &-station-title {
    font-size: map-get($text-size, content-h2);
    color: $text-primary-color;
    font-weight: bold;
    padding-top: 20px;
    @include sm {
      padding-top: 10px;
    }
  }
}

.site-info {
  @include sm {
    min-height: calc(100vh - 190px);
    &-upper {
      padding: 20px;
      .spinner-wrapper {
        position: inherit;
        margin-top: 30px;
      }
    }

    &-title {
      color: $text-primary-color;
      font-weight: bold;
      font-size: 18px;
    }
  }
}

.sites {
  .home-mini-search {
    margin-bottom: 0px;
  }
  @include sm {
    @include general-container-mini;
  }
}

@media (max-width: $screen-sm-min) {
  .body-container {
    padding: $sm-page-padding-top-down $sm-page-padding-left-right;
    align-items: center;
    flex-direction: column;
  }

  .body-container-subcontent {
    & .page-top-intro {
      font-size: map-get($mobile-text-size, content-h1);
    }
  }

  .update-null-state {
    margin-top: 20px;
    color: $text-secondary-color;
    font-size: map-get($mobile-text-size, page-h2);
  }

  .losses {
    &-modal {
      &-item {
        margin-bottom: map-get($mobile-text-size, content-body);
        font-size: map-get($mobile-text-size, content-body);
      }
    }
  }

  .rules-intro-text {
    font-size: map-get($mobile-text-size, content-body);
  }
}
