@import '../../abstract/variables';
@import '../../abstract/mixins';

.info-button {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  pointer-events: auto;

  &:focus {
    outline: none;
  }

  &-water-source,
  &-water-source-dropdown {
    cursor: pointer;
    height: 17px;
    width: 17px;
    fill: $primary-color;

    @include sm {
      height: 15px;
      width: 15px;
      fill: $text-secondary-color;
    }
  }

  &-water-source-dropdown {
    @include sm {
      display: none;
    }
  }
  &-dist-graph-legend {
    border-width: 0px;
    fill: $primary-color;
  }
}
