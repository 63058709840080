@import '../../abstract/variables';
@import '../../abstract/mixins';

.paragraph-section {
  margin-bottom: $page-margin-general;
  color: $text-secondary-color;

  &-title {
    margin-bottom: 8px;
    font-size: map-get($text-size, content-h2);
    font-weight: bold;
  }
  &-content {
    &-sentence {
      .conditions-dealing-sentence {
        display: inline;
      }
      &-content {
        display: inline;
      }
    }
  }
}

@include sm {
  .paragraph-section {
    line-height: 1.43;
    font-size: map-get($mobile-text-size, content-body);
    &-title {
      margin-bottom: 4px;
      font-size: map-get($mobile-text-size, content-h1);
    }
  }
}
