@import '../../abstract/variables';
@import '../../abstract/mixins';

//public styles
.side-drawer-container {
  position: relative;
  .expand-link {
    margin: 20px 0;
    color: #fff;
    &-arrow {
      width: 25px;
    }
    &-nav-item {
      padding: 0 17px;
      padding-bottom: 5px;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    &-dropdown-menu {
      background: #7fa9d2;
      color: white;
    }
    &:last-child {
      margin: 0px;
    }
  }
}

.drawer-header-name {
  font-family: Arial;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  flex: 2;
}

.drawer-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  &.mini {
    align-items: center;
    margin: 0;
  }
}

//full-version
.side-drawer {
  background-color: $primary-color;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 80px;
  max-width: 79%;
  height: auto;
  z-index: 1200;
  transform: translateX(110vw);
  transition: transform 0.3s ease-out;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &-links {
    overflow-y: scroll;
  }

  .drawer-header {
    padding: 22px 16px;
  }

  .drawer-header-name {
    width: 79px;
    height: 22px;
    padding: 0 17px;
    color: #fff;
    font-size: 20px;
  }

  .drawer-links-container {
    padding: 0 17px;
  }

  a {
    color: #fff;
  }

  //light-version
  &.mini {
    padding: 0;
    background-color: #f0eff0;
    max-width: 62%;
    height: auto;
    .drawer-header {
      padding: 22px 16px;
    }
    .drawer-header-name {
      flex: 2;
      height: 16px;
      font-size: 14px;
      color: $text-primary-color;
      padding: 0;
    }
    .drawer-links-container {
      margin-top: -10px;
      padding: 0 20px;
    }
    a {
      color: $title-tertiary-color;
    }
  }

  button {
    background: none;
    outline: 0;
    border: none;
    float: right;
  }

  button:focus {
    outline: 0;
  }

  .logout-link,
  .login-link {
    width: 100%;
    height: 48px;
    font-size: 14px;
    font-weight: bold;
    padding: 15px 20px;
    cursor: pointer;
  }
  .login-link {
    color: #fff !important;
    background-color: $primary-color;
  }
  .logout-link {
    color: #fff;
    background-color: #000;
    &.mini {
      background: none;
      color: $title-tertiary-color;
    }
  }
}

.side-drawer-overlay {
  position: fixed;
  z-index: 1100;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65);
  opacity: 0;

  @include sm {
    transition: opacity 0.5s ease 0s;
    opacity: 1;
  }
}

.side-drawer.open {
  transform: translateX(80px);
}

.icon-container {
  display: block;
  width: 16px;
  .close-menu-icon {
    font-size: 16px;
    color: #fff;
    transform: scale(1.5);
    &.mini {
      color: $title-tertiary-color;
    }
  }
}
