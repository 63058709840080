@import '../../../abstract/variables';
@import '../../../abstract/mixins';

.manage-alert {
  &-not-auth {
    padding: 10% 0;
  }
  &-body {
    display: grid;
    grid-template-columns: 1.5fr 1fr 0.3fr 0.4fr 0.3fr 0.4fr;
    grid-column-gap: 10px;
    padding: 14px 17px;
    align-items: center;
    border-bottom: 1px solid #edeeee;
    p {
      margin-bottom: 0;
    }
    div:first-letter {
      text-transform: uppercase;
    }

    @include sm {
      line-height: 1.67;
      display: flex;
      flex-wrap: wrap;
      &-gauges {
        flex: 2;
        min-width: 100px;
      }
      .alert-item-threshold {
        font-size: 12px;
        color: $text-secondary-color;
        span {
          font-weight: bold;
        }
      }
      .alert-item-info {
        display: none;
      }
      .edit-remove-buttons {
        font-size: 12px;
        color: $primary-color;
        a {
          text-decoration: none;
        }
      }
      .manage-alert-button {
        padding-inline-start: 0;
        padding-inline-end: 10px;
        &:nth-child(2) {
          padding-inline-start: 10px;
        }
      }
    }
  }

  &-delivery-type {
    display: flex;
    justify-content: center;
    .check-icon {
      display: none;
    }
    @include sm {
      flex: 1;
      .checkbox-container {
        width: 18px;
        height: 18px;
        position: relative;
        input[type='checkbox'] {
          display: block;
          opacity: 0;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 2;

          & + .check-icon {
            display: block;
            background-color: white;
            border-radius: 4px;
            border-color: $text-secondary-color;
            width: 100%;
            height: 100%;
          }
          &:checked + .check-icon {
            color: #004ba3;
          }
        }
      }
    }
    &-expiry {
      display: flex;
      justify-content: center;
      @include sm {
        display: none;
      }
    }
  }

  .view-details a {
    color: $primary-color;
  }

  &-button {
    padding: 0;
    border: none;
    background-color: inherit;
    color: $primary-color;
    text-decoration: underline;
    display: inline-block;
    padding-inline-start: 5px;
    padding-inline-end: 5px;

    &:focus {
      outline: none;
      text-decoration: underline;
    }

    &:hover {
      outline: none;
      font-weight: bold;
      text-decoration: none;
    }
  }
}
