@import '../../abstract/variables';
@import '../../abstract/mixins';

@include sm {
  .water-site-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 10vh;

    &-volume {
      color: $title-tertiary-color;
      font-size: 18px;
      font-weight: bold;
      margin-top: 40px;
      margin-bottom: 20px;

      span {
        font-weight: normal;
      }
    }

    .recharts-text {
      fill: $title-tertiary-color;
      font-size: 28px;
      font-weight: bold;
    }

    &-resources {
      margin-top: 16px;
      width: 312px;
      border-radius: 10px;
      border: solid 1px #88a8ce;
      color: $title-tertiary-color;
      font-size: 14px;
      padding: 15px 15px 10px;

      &-item {
        display: grid;
        grid-template-columns: 1.5fr 1fr;
        grid-column-gap: 10px;
        align-items: center;
        width: 100%;

        &-label {
          font-weight: bold;
        }
      }
      &-item:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    &-date {
      margin-top: 16px;
      font-style: italic;
      color: #707070;
      font-size: 12px;
      display: flex;
      justify-content: center;
    }

    &-msg {
      font-style: italic;
      color: #707070;
      display: flex;
      justify-content: center;
    }

    &-graph {
      background: rgba(0, 75, 163, 0.05);
      min-height: 460px;
      width: 120%;
    }
  }
}
