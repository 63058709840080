@import '../../../abstract/variables';
@import '../../../abstract/mixins';

.checkbox-list-dropdown {
  &-container {
    width: 100%;
    position: absolute;
    z-index: 10;
    background: white;
    border: 1px solid #d4d4d4;
    border-radius: 10px;
  }

  &-options {
    border: none !important;
    border-radius: 0 !important;
  }
  &-btn-grp {
    border-top: 1px solid #d4d4d4;
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    &-item-sec, &-item-prim {
      margin-left: 10px;
      width: 150px;
      border-radius: 21px !important;
    }

    &-item-sec {
      color: #0054a6 !important;
      border: none !important;
      background-color: inherit !important;
      text-decoration: underline;
      width: auto;
    }
  }
}
