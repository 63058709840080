@import '../../../abstract/variables';
@import '../../../abstract/mixins';

.trading-category {
  &-row {
    .expandBox-title-container-textContainer {
      width: 90%;
    }
    .expandBox-title-container {
      display: flex;
      justify-content: space-between;
    }
    font-size: map-get($text-size, content-body);
    text-align: right;
    color: $text-secondary-color;
    margin-bottom: 6px;
    border-radius: 10px;
  }

  &-summary {
    background-color: #ecedee;
  }

  &-arrow {
    fill: $primary-color;
  }

  &-grid {
    display: grid;
    grid-template-columns: repeat(2, 2fr) repeat(4, 1.1fr);
    grid-column-gap: 10px;
    text-align: right;

    &:nth-child(2) {
      text-transform: capitalize;
    }

    &-item {
      word-break: break-word;
    }

    &-first {
      text-align: left;
      word-wrap: break-word;
    }

    &-row-head {
      font-weight: bold;
    }

    &-item,
    &-row-head {
      text-transform: none;
      align-self: center;
    }
  }

  &-details {
    padding: 30px 20px 0px 20px;

    .data-summary-table-grid {
      grid-column-gap: 5px;
      grid-template-columns: 1fr repeat(13, 0.3fr);
    }

    .recharts-legend-wrapper {
      bottom: 8px !important;
      li {
        cursor: pointer;
      }
    }
  }

  &-title {
    &-icon {
      fill: $text-secondary-color;
      width: 16px;
      height: 16px;
    }
  }
}

.paragraph-section-content {
  text-align: none !important;
}

@include lg {
  .trading-category {
    &-lg-hide {
      display: none;
    }

    &-grid {
      grid-template-columns: 1fr 1.5fr 0.8fr 0.8fr 0.8fr;
    }

    &-details {
      .data-summary-table-grid {
        grid-template-columns: 0.8fr repeat(7, 0.2fr);
      }
    }
  }
}
.paragraph-section-content {
  text-align: left !important;
}

@include sm {
  .trading-category {
    .expandBox-container {
      font-size: 13px;
    }
    .expandBox-title-container {
      padding: 8px 15px;
    }
    &-row {
      font-size: map-get($mobile-text-size, content-body);
      margin-bottom: 6px;
    }

    &-grid {
      grid-template-columns: 1fr 1fr 0.5fr;
      grid-column-gap: 5px;
    }

    &-mobile-hide {
      display: none;
    }

    &-details {
      padding: 0px 25px 0px 10px;
      .data-summary-table {
        display: grid;
        grid-template-columns: repeat(4, 1fr);

        &-grid {
          grid-template-columns: 1fr;
          grid-template-rows: 1.8fr repeat(13, 1fr);
          align-items: center;
        }
      }
    }
    &-title {
      &-icon {
        fill: $text-secondary-color;
        width: 16px;
        height: 16px;
      }
    }
  }
}
.paragraph-section-content {
  text-align: none !important;
}
