@import '../../abstract/variables';
@import '../../abstract/mixins';

.chart-yaxis-label {
  font-size: 14px;
  color: #4b555a;
}

.order-usage-popup-timestamp {
  display: flex;
  font-size: 15px;
  font-style: italic;
  margin-top: 40px;

  @include sm {
    justify-content: center;
  }
}

@include sm {
  .order-usage {
    border-radius: 10px 10px 0px 0px;

    &-chart {
      display: flex;
      justify-content: center;
    }

    &-popup-timestamp {
      justify-content: center;
    }

    &-popup-container {
      padding: 20px;
    }
  }

  .map-popup-body {
    &-title {
      margin-bottom: 20px;
    }
  }
}
