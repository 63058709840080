@import '../../abstract/variables';
@import '../../abstract/mixins';

.map-legend-item-wrapper {
  @include textVerticalCenter;
}

.map-legend-line-base {
  margin-right: 8px;
  width: 20px;
}

.map-legend-item {
  color: #4b555a;
}

.map-legend-type {
  font-weight: bold;
  text-transform: uppercase;
}

.map-legend-space {
  height: 29px;
}

.map-legend,
.flow-class-map-legend {
  background: white;
  border-radius: 3px;
  position: absolute;
  right: 24.5px;
  z-index: 500;
  padding: 12px;
  transition: opacity 0.3s;
  font-size: 14px;
}

.map-legend {
  width: 143px;
}

.flow-class-map-legend {
  font-size: 12px;
  width: 1000px;
  position: absolute;
  bottom: 80px;
  left: 85px;
  z-index: 400;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  z-index: 460;

  &-section {
    display: flex;
    padding: 5px 0px 5px 10px;
  }

  .map-legend-item-wrapper {
    padding-left: 20px;
  }
}

.drought-status-map-legend {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 168px;

  &-title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 14px;
  }

  .map-legend-item-wrapper {
    margin-bottom: 10px;
  }

  .map-legend-item-wrapper:last-child {
    margin-bottom: 0px;
  }
}

@include xxl {
  .flow-class-map-legend {
    width: 900px;
  }
}
@include xl {
  .flow-class-map-legend {
    width: 650px;
  }
}

@include lg {
  .flow-class-map-legend {
    width: 575px;
    .map-legend-item-wrapper {
      padding-left: 10px;
    }
  }
}

@include md {
  .flow-class-map-legend {
    width: 450px;

    &-section {
      padding-left: 0px;
    }

    .map-legend-item-wrapper {
      padding-left: 7px;
    }
  }
}
