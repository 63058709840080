@import '../../abstract/variables';
@import '../../abstract/mixins';

.chart-container {
  .chart-icon {
    width: fit-content;
  }

  border-radius: 10px;
  background: #fafafa;
  margin: 20px 0px 20px 0px;
  width: 100%;

  &-title {
    font-weight: bold;
    color: $label-primary-color;
    background: #707070;
    border-radius: 10px 10px 0 0;
    font-size: map-get($text-size, content-h1);

    span {
      padding: 22px;
      @include textVerticalCenter;
    }
  }

  &-body {
    padding: 30px 22px 35px 22.5px;
    height: auto;
    color: $text-secondary-color;
  }
}

@include sm {
  .chart-container {
    font-size: 14px;

    &-title {
      font-size: map-get($mobile-text-size, page-h1);
      span {
        padding: 12px 16px;
      }
    }

    &-body {
      padding: 15px;
    }
  }
}
