@import '../../abstract/variables';
@import '../../abstract/mixins';

.chart-commentary {
    font-style: italic;
    margin: 10px 0px;
    font-size: 14px;
}

@include sm {
    .chart-commentary {
        font-size: 10px !important;
        margin: 0px;
    }
}