@import '../../abstract/variables';
@import '../../abstract/mixins';

@include sm {
  .water-site-info-line-chart {
    &-tooltip {
      background-color: white;
      padding: 5px;
      font-size: map-get($mobile-text-size, content-body-subtitle);
      box-shadow: 0px 1px 3px;
      &-value {
        font-size: map-get($mobile-text-size, content-body-subtitle);
        font-weight: bold;
      }
    }
  }
}
