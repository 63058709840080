@import '../../abstract/variables';
@import '../../abstract/mixins';

.snapshot {
  &-quality-legend {
    display: flex;
    justify-content: flex-end;
    .data-quality-legend {
      @include data-quality-legend;
    }
  }
}
