@import '../../abstract/variables';
@import '../../abstract/mixins';

.footer-bar {
  @include flexVerticalCenter;
  @include headerAndFooter;

  position: absolute;
  width: 100%;
  color: white;
  padding: 20px 0px;
}

.footer-link-item-group,
.footer-social-link-item-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px 0px;
  @media (min-width: $screen-lg-min) {
    @include flex(left);
    height: 100%;
    align-items: center;
  }
}
@media (min-width: $screen-lg-min) {
  .footer-social-link-item-group {
    @include flex(right);
  }
}
