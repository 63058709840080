@import '../../abstract/variables';
@import '../../abstract/mixins';

.dropdown-locator-container {
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.find-location-title {
  text-transform: uppercase;
  color: $text-primary-color;
  font-size: 28px;
  font-weight: bold;
  pointer-events: auto;
  width: 260px;
  display: block;

  @include sm {
    display: none;
  }
}
