@import '../../abstract/variables';
@import '../../abstract/mixins';

.river-schematic-legend {
  &-data-quality {
    .data-quality-legend {
      width: 86% !important;
      background-color: white;
      &-title {
        font-size: 16px !important;
      }
      &-status {
        font-size: 14px !important;
      }
    }
  }
}
