@import '../../../abstract/variables';
@import '../../../abstract/mixins';

.inter-valley-transfer {
  .recharts-label {
    fill: #707070;
    font-weight: bold;
    font-size: 18px;
  }
  .legend-label {
    font-size: 14px !important;
  }

  &-title {
    display: flex;
    align-items: center;
    margin: 32px 0px 6px 0px;

    &-text {
      font-size: map-get($text-size, content-h2);
      color: $text-primary-color;
      font-weight: bold;
    }
    &-icon {
      fill: $primary-color;
      width: 16px;
      height: 16px;
    }
  }

  &-intro {
    margin-bottom: 20px;
  }

  &-content {
    grid-column-gap: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    &-item {
      background: rgba(0, 75, 163, 0.05);
      border-radius: 10px;
      padding: 17px 12px;
      position: relative;
    }
  }

  &-status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-header {
      font-size: 16px;
      font-weight: bold;
      color: #4b555a;
    }

    &-value {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      border-radius: 20px;
      height: 28px;
      width: 100px;
      padding: 0px 10px;
      margin-left: 10px;
      color: white;
      text-transform: uppercase;
    }
    .closed {
      background: #d12800;
    }
    .open {
      background: #1b9321;
    }
  }
  &-text {
    font-size: 14px;
    color: #707070;
    margin-top: 10px;
    font-weight: normal !important;
  }
  &-volume {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 10px;

    &-icon {
      fill: $text-secondary-color;
      width: 16px;
      height: 16px;
    }

    &-label {
      font-size: 16px;
      font-weight: bold;
      color: $text-secondary-color;
    }

    &-value {
      color: #707070;
      font-size: 2.6vw;
      font-weight: bold;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }

    &-unit {
      font-size: 1.8vw;
      padding-bottom: 0.3vw;
    }
  }

  &-date {
    text-align: center;
    left: 10%;
    right: 10%;
    position: absolute;
    bottom: 0;
    margin-bottom: 10px;
    font-size: 14px;
    color: #707070;
  }
}

@include xl {
  .inter-valley-transfer {
    &-volume {
      &-value {
        font-size: 4vh;
      }

      &-unit {
        font-size: 2vh;
        padding-bottom: 0.7vh;
      }
    }
  }
}

@include lg {
  .inter-valley-transfer {
    &-volume {
      display: block;
    }
  }
}

@include md {
  .inter-valley-transfer {
    &-content {
      display: block;

      &-item {
        margin-bottom: 10px;
      }
    }

    &-volume {
      display: grid;

      &-value {
        margin-bottom: 20px;
      }
    }
  }
}

@include sm {
  .inter-valley-transfer {
    .recharts-label {
      font-size: 14px;
    }
  }
}
