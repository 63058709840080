@import '../../abstract/variables';
@import '../../abstract/mixins';

.update {
  &-general-dropdown {
    justify-content: space-between;
    align-items: flex-end;
  }

  &-general-body {
    &-title {
      font-size: map-get($text-size, content-h1);
      color: $text-primary-color;
      font-weight: bold;
    }

    &-noResult {
      color: $text-secondary-color;
      padding-top: 15px;
    }
  }

  &-body {
    &-archived-link {
      float: right;
    }
  }

  &-general-dropdown-years {
    .general-dropdown-menu {
      width: 110px;
    }
  }

  &-algal-alert {
    &-header {
      font-size: map-get($text-size, content-h1);
      color: $primary-color;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: -20px;
    }
  }
}

@include lg {
  .update-body-right {
    margin-top: 30px;
    padding-top: 30px;
    border-top:1px solid rgb(217, 217, 217);
  }
}

@include sm {
  .update-body-right {
    margin-top: 0px;
    padding-top: 0px;
    border-top: none;
  }
  .update-general-dropdown {
    &-type {
      max-width: 65%;
      .general-dropdown-button {
        background: none;
        color: $text-primary-color;
        border-radius: 0px !important;
        border: none;
        border-bottom: 2px solid #0054a6;
        min-width: 180px;
      }
      &-menu {
        width: 300px;
      }
      .expandBox-title-container-text {
        width: auto;
      }
    }
    &-years {
      .general-dropdown-button {
        background: none;
        color: $text-primary-color;
        border-radius: 0px !important;
        border: none;
        border-bottom: 2px solid #0054a6;
        min-width: unset !important;
      }
      .general-dropdown-menu-item {
        min-width: unset !important;
        &-selected {
          font-size: 14px;
        }
      }
      &-menu {
        width: 300px;
      }
    }
  }

  .update {
    &-general {
      &-header {
        text-transform: uppercase;
        .expandBox-title-container {
          padding: 8px 22px;
        }
      }

      &-body {
        margin-top: 0px;
        

        &-noResult {
          margin: 20px 0px 0px 0px;
        }
      }
    }

    &-body {
      flex-direction: unset;
      &-archived-link {
        float: none;
        margin-bottom: 10px;
      }
      .update-item {
        padding-bottom: 20px;
        border-bottom: 1.5px solid #d2d7da;
        margin: 20px 0px 0px 0px;
        &-title {
          font-size: 16px;
        }
      }
    }
  }
}
