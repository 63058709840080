@import '../../abstract/variables';
@import '../../abstract/mixins';

.nav-link-item {
  padding: 0px 18px;
  cursor: pointer;

  &-link {
    color: inherit;
    font-size: map-get($tertiary-nav-item-text, font-size);

    &:hover {
      color: inherit;
    }
  }
}
