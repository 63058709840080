@import '../../../../abstract/variables.scss';
@import '../../../../abstract/mixins.scss';

.groundwater-data-filters {
  &-title {
    padding: 22px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    width: 100%;
    height: 60px;
    line-height: 16px;
    background-color: $primary-color;
    border-radius: 10px 10px 0 0;
  }
  &-container {
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 100%;

    @include sm {
      border-radius: 0 0 10px 10px;
      padding: 10px;
      align-items: center;
    }
    .expandBox {
      &-title-container {
        padding: 0px;
      }
      &-title {
        &.archived {
          margin-bottom: 0;
          padding: 0 20px;
          .expandBox-title-container {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            height: 45px;
          }
        }
      }
      &-container {
        &.archived {
          background-color: white;
          border-radius: 4px;
          padding-bottom: 0px;
        }
        @include sm {
          margin: 0px 10px;
        }
      }
    }
  }

  &-item {
    .expandBox {
      &-title-container {
        flex-direction: row-reverse;
        justify-content: flex-end;
      }
    }
    color: $primary-color;
    font-size: map-get($text-size, 'content-h2');
    margin-bottom: 5px;

    &-arrow {
      visibility: hidden;
    }

    &-content {
      background-color: white;
      padding-left: 10px;
      @include sm {
        .download-check-box,
        .type,
        .download-radio-picker {
          font-size: 14px;
        }
      }

      input[type='text'] {
        color: darken(#a4a9ac, 30%);
        width: 100%;
        height: 40px;
        margin: 0px;
        padding-left: 10px;
        border: none;
        border-radius: 4px;
        background-color: #efefef;
        &:focus-visible {
          outline: none;
        }
        &::placeholder {
          color: #a4a9ac;
        }
      }
    }
  }

  &-btn {
    margin-top: 10px;
    background-color: unset;
    border: 2px solid $primary-color;
    border-radius: 5px;
    color: $primary-color;
    font-size: map-get($text-size, 'content-h2');
    font-weight: bold;
    padding: 5px 10px;
    width: auto;
    align-self: flex-end;

    &.archived {
      @include sm {
        background-color: $primary-color;
        color: white;
        font-size: 16px;
        text-transform: capitalize;
      }
    }

    &:disabled {
      cursor: default;
      pointer-events: none;
      border: 2px solid $text-secondary-color;
      color: $text-secondary-color;
      background-color: unset;
    }
  }

  &-msg {
    font-style: italic;
    color: $text-secondary-color;
    text-align: right;
    font-size: 12px;
    margin-top: 10px;
  }

  &-option label {
    cursor: pointer;
    margin-top: 0.5rem;
    color: #0054a6;
    font-size: 18px;
    font-family: Arial;
    text-align: left;

    input[type='radio'] {
      margin-right: 15px;
      color: #0054a6;
    }
  }
}
