@import '../../abstract/variables';
@import '../../abstract/mixins';

.general-dropdown {
  height: 100%;
  font-size: 16px;
  @include sm {
    margin: 0;
  }
  &-button {
    height: 100%;
    background: $primary-color;
    color: $label-primary-color;
    border-radius: 10px !important;
    font-weight: bold;
    font-size: map-get($text-size, content-body);
    text-transform: uppercase;
    border-radius: 4px;
    padding: 5px 10px;
    border: 1px solid $primary-color;
    cursor: pointer;
    &-disabled {
      cursor: default;
    }
    &-enabled {
      cursor: pointer;
    }
    @include sm {
      button {
        width: 20%;
      }
    }
  }

  &-menu {
    position: absolute;
    background-color: white;
    border-radius: 10px;
    width: 350px;
    max-height: 510px;
    overflow-y: auto;
    z-index: 3;
    padding: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-weight: bold;
    color: $text-secondary-color;
    font-size: map-get($text-size, content-body);
    text-transform: uppercase;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    margin-top: 5px;
    @include sm {
      width: 250px;
      margin-top: 0px;
    }
    &-small {
      width: 100px;
    }
    &-medium {
      width: 250px;
    }
  }

  &-menu-item {
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    margin: 5px 0px;
    background-color: rgba($color: #eeeeee33, $alpha: 1);
    cursor: pointer;
    color: $text-secondary-color;
    &.storage {
      background-color: #eee;
    }
    &:hover {
      background-color: $primary-color;
      color: $label-primary-color;
    }
    &-selected {
      cursor: pointer;
      width: 100%;
      border-radius: 10px;
      padding: 10px;
      margin: 5px 0px;
      background-color: $primary-color !important;
      color: $label-primary-color !important;
      background-color: unset;
      color: $text-secondary-color;
      @include sm {
        font-size: 14px;
      }
    }
    @include sm {
      font-size: 14px;
      min-width: 150px;
    }
  }

  input[type='checkbox']:before {
    content: '';
    display: block;
    position: absolute;
    width: 14px;
    height: 14px;
    top: 0;
    left: 0;
    border-radius: 3px;
    background-color: white;
  }
  input[type='checkbox']:checked:after {
    content: '';
    display: block;
    width: 5px;
    height: 10px;
    border: solid $primary-color;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 1px;
    left: 5px;
  }
  .checkbox {
    -ms-transform: scale(1.2); /* IE */
    -moz-transform: scale(1.2); /* FF */
    -webkit-transform: scale(1.2); /* Safari and Chrome */
    -o-transform: scale(1.2); /* Opera */
    transform: scale(1.2);
  }

  .grid-container {
    display: inline-grid;
    grid-template-columns: auto auto auto;
  }

  .grid-container div:nth-child(1) {
    margin-left: 5px;
  }
  .grid-container div:nth-child(2) {
    margin-left: 15px;
  }

  .expandBox-title-container {
    padding: 0px;
    @include sm {
      display: flex;
      justify-content: space-between;
    }
  }

  .expandBox-container {
    padding: 0px;
  }

  .expandBox-body {
    &-animation {
      transition: bottom 0.25s ease-in;
    }
  }

  &-squaredFour {
    width: 20px;
    position: relative;
    margin: 20px auto;

    input[type='checkbox'] {
      visibility: hidden;
      &:checked + label:after {
        opacity: 1;
      }
    }
  }
}
