@import '../../abstract/variables';
@import '../../abstract/mixins';

.page-header {
  min-height: 47px;
  color: grey;
  @include flex(right);
  height: 100%;
  align-items: center;
}
