@import '../../../abstract/variables';
@import '../../../abstract/mixins';

.data-quality-text {
  &-status {
    &-anomaly {
      color: $status-anomaly;
    }

    &-suspect {
      color: $status-suspect;
    }
  }
}
