@import '../../abstract/variables';
@import '../../abstract/mixins';

@mixin salinityHeaderColumns {
  display: flex;
  width: 100%;
  font-size: 14px;
  align-items: center;
  column-gap: 5px;
  .priority-1 {
    width: 10%;
  }
  .priority-2 {
    width: 20%;
  }
  .priority-3,
  .priority-4,
  .priority-5,
  .priority-6,
  .priority-7,
  .priority-8,
  .priority-9,
  .priority-10 {
    width: 10%;
    text-align: center;
  }

  .priority-11 {
    width: 3%;
  }

  @include md {
    font-size: 12px;
    .priority-1 {
      width: 20%;
    }
    .priority-2 {
      width: 30%;
    }
    .priority-3,
    .priority-4,
    .priority-9 {
      display: none;
      width: 0;
    }
    .priority-5,
    .priority-6,
    .priority-7,
    .priority-8,
    .priority-10 {
      width: 14%;
    }
  }
  @include sm {
    .priority-1 {
      width: 25%;
    }
    .priority-2 {
      width: 39%;
    }
    .priority-3,
    .priority-4,
    .priority-6,
    .priority-7,
    .priority-9 {
      display: none;
      width: 0;
    }
    .priority-5,
    .priority-8, 
    .priority-10 {
      width: 18%;
    }
  }
}

.salinity-stations {
  &-body {
    border-radius: 0px 0px 10px 10px;
    background: #fafafa;
    padding: 13px 20px;

    .recharts-tooltip-wrapper, 
    .recharts-tooltip-wrapper-left,
    .recharts-tooltip-wrapper-bottom {
      padding: 10px;
      border: 1px solid #cccccc;
      background: $label-primary-color;
      line-height: 15px;
    }
  }
  .expandBox-title-container-textContainer {
    width: 100% !important;
  }
  background-color: white;
  .list-item-body {
    height: 100px;
  }
  .data-summary,
  .expandBox-title-container {
    flex-wrap: nowrap;
  }

  .list-content {
    @include salinityHeaderColumns;
  }

  &-header {
    @include salinityHeaderColumns;
    @include sm {
      width: 100%;
      font-size: 13px;
      .expandBox-title-container-textContainer {
        margin-right: 0px !important;
      }
    }
  }
  .skeleton {
    @include skeleton(8s);
  }
}
