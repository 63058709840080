@import '../../../abstract/variables';
@import '../../../abstract/mixins';

.trading-body {
  &-ivt {
    &-note {
      margin-top: 20px;
      color: $text-secondary-color;
    }
  }

  .report-table {
    padding: 10px;
    tr > th:nth-child(2),
    tr > td:first-child {
      width: 15%;
    }
  }
}
