@import '../../../abstract/variables';
@import '../../../abstract/mixins';

.user-body-container {
  display: grid;
  grid-template-columns: 0.5fr 2.5fr;

  @include sm {
    display: block;
    .user-body-container-left {
      display: none;
    }
    .user-body-container-right {
      display: flex;
      flex-direction: column;
    }
  }

  &-left {
    border-right: 2px solid #edeeee;
    width: 160px;
    height: 100%;
    margin-right: 20px;
    padding-right: 20px;
    color: $text-secondary-color;
    float: left;

    a {
      color: $text-secondary-color;
    }

    &-item {
      cursor: pointer;
      margin: 10px;
      padding: 10px 20px;
    }

    .selected {
      background: #e5edf6;
      font-weight: bold;
      border-radius: 5px;
    }
  }

  &-right {
    overflow: hidden;
    height: 100%;
  }
}
