@import '../../abstract/variables';
@import '../../abstract/mixins';

.feedback-form-response {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  justify-content: center;
  text-align: center;
  align-items: center;
  background: white;
  width: 100%;
  height: 100%;
  padding: 40px 5px 40px 25px;
  border-radius: 10px;
  z-index: 1;
  &-text {
    font-size: map-get($text-size, content-h1);
    color: $text-secondary-color;
  }
}
