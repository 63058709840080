@import '../../abstract/variables';
@import '../../abstract/mixins';

@include sm {
  .home-mini {
    min-height: calc(100vh - 190px);
    padding: 20px;

    &-title {
      @include componentTitle;
    }

    &-intro {
      font-size: 14px;
      color: $text-secondary-color;
      margin-bottom: 40px;
    }

    &-water-site {
      margin-bottom: 20px;
    }

    &-search {
      margin-bottom: 60px;
      .global-search-bar {
        &-input {
          width: 100%;
          z-index: inherit;
        }
        &-suggestion {
          width: 100%;
          top: unset;
          padding: 20px;

          .site-link-item {
            border-top: unset;
            height: 40px;
          }
        }
      }
    }
  }
}
