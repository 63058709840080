@import '../../abstract/variables';
@import '../../abstract/mixins';

.login-button {
  background: $primary-color;
  color: white;
  font-weight: bold;
  border-radius: 7px;
  padding: 10px 20px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  @include sm {
    margin-top: 26px;
    font-size: 16px;
    width: 104px;
    height: 40px;
    padding: 0;
    line-height: 40px;
  }
}
