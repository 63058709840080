.site-info {
  &-upper {
    .general-dropdown-menu {
      width: auto;
    }
  }

  &-buttons-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-icon-container {
    display: flex;
    flex-direction: column;
    font-size: 14px !important;
    .favourite-btn {
      margin-top: 0px !important;
    }

    .chart-icon {
      text-transform: none;
      padding: 4px;
      a {
        text-decoration: underline;
      }
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
}
