@import '../../abstract/variables';
@import '../../abstract/mixins';

.flow-class-value {
  font-weight: normal;
}

@include sm {
  .flow-class {
    border-radius: 8px 8px 0px 0px;
  }
}
