@import '../../../../abstract/variables.scss';
@import '../../../../abstract/mixins.scss';
.navigation-help {
  margin-top: 20px;
  &-vids {
    display: flex;
    // justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    column-gap: 25px;
  }

  &-fact-sheet {
    color: $text-secondary-color;
    font-size: 16px;
    line-height: 1.25;
    &-link {
      color: $primary-color;
    }
  }
}

@include sm {
  .navigation-help {
    &-fact-sheet {
      font-size: 14px;

      &-icon {
        width: 20px;
        height: 20px;
      }
    }
  }
}