@import '../../../../../abstract/variables.scss';
@import '../../../../../abstract/mixins.scss';

.ratings-curve-table-item {
  &-1, &-2 {
    &-data {
      table {
        width: 100%;
      }
      td:first-child, th:first-child {
        text-align: left;
      }
      td, th {
        text-align: center;
      }
      th {
        border-bottom: 2px solid #dee2e6;
      }
      th:nth-child(2n), td:nth-child(2n) {
        background-color: $zebra-background;
      }
      tbody > tr:hover {
        background: $secondary-color;
        td {
          background-color: $secondary-color;
        }
      }
    }
  }
  
  &-2 {
    &-data {
      td, th {
        text-align: right;
        padding: 3px;
      }
      table {
        font-size: 14px;
      }
    }
  }
}
