@import '../../abstract/variables';
@import '../../abstract/mixins';
$tab-height: calc(($tab-box-selected-height - 5px) * 1 / 2);

.tab-text-base {
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  margin: auto;
  font-size: 12px;
  @media (min-width: $screen-md-min) {
    font-size: 16px;
  }
  @media (min-width: $screen-lg-min) {
    font-size: 18px;
  }
}
.tabs {
  display: flex;
  position: absolute;
  bottom: 0;
  z-index: 450;
}
.tab-container-base {
  display: flex;
  z-index: 500;
  margin-left: $page-margin-left-right;
  position: relative;
  bottom: 0;

  .tab-divider {
    width: 2px;
  }

  .tab-rectangle {
    width: auto;
    @include tabBox($primary-color);
    margin: auto;
    height: auto;
    padding: 7px;
    @media (min-width: $screen-md-min) {
      padding: 10px;
    }
    @media (min-width: $screen-lg-min) {
      padding: 17px 20px 15px 20px;
    }
    box-shadow: $tab-box-shadow;
    white-space: nowrap;
    cursor: pointer;
    @extend .tab-text-base;
    color: $label-primary-color;
  }

  .tab-rectangle-selected {
    @include tabBox(white);
    margin-top: -5px;
    margin-bottom: 0px;
    transition: background-color 0.5s;
    color: $text-primary-color;
  }
}

.tab-container {
  @extend .tab-container-base;
}

.tab-container-visible {
  @extend .tab-container-base;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.tab-container-visible::-webkit-scrollbar {
  display: none;
}

@media (max-width: $screen-sm-min) {
  .tab-container-base {
    margin: 0;
    overflow-x: scroll;
    .tab-text-base {
      width: 140px;
    }
    .tab-rectangle {
      box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.16);
      scroll-snap-align: center;
      padding: 13px;
      font-size: 13px;
      @include tabBox(white);
      margin: auto;
      height: auto;
      cursor: pointer;
      color: $title-secondary-color;
      justify-content: center;
    }

    .tab-rectangle-selected {
      margin-top: -5px;
      margin-bottom: 0px;
      box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.16);
      font-size: 14px;
      border-top: 1.5px solid $text-primary-color;
      border-left: 1.5px solid $text-primary-color;
      border-right: 1.5px solid $text-primary-color;
      color: $text-primary-color;
    }
  }
  .tabs {
    width: 100%;
    height: 12vw;
    overflow: hidden;
  }

  .tab-container-visible::before {
    content: '';
    flex: 1 0 50%;
  }
  .tab-container-visible::after {
    content: '';
    flex: 1 0 50%;
  }
  .tab-container-visible,
  .tab-container-visible:after {
    width: 100%;
    height: 16vw;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    overflow-y: hidden;
    align-items: center;
  }
}
