@import '../../abstract/variables';
@import '../../abstract/mixins';

@mixin green-status($backgroundOpacity, $fillOpacity) {
  background: rgba(146, 209, 80, $backgroundOpacity);
  fill: rgba(146, 209, 80, $fillOpacity);
}

@mixin amber-status($backgroundOpacity, $fillOpacity) {
  background: rgba(255, 193, 48, $backgroundOpacity);
  fill: rgba(255, 193, 48, $fillOpacity);
}

@mixin red-status($backgroundOpacity, $fillOpacity) {
  background: rgba(255, 0, 21, $backgroundOpacity);
  fill: rgba(255, 0, 21, $fillOpacity);
}

.water-quality-popup {
  font-size: 16px;
  font-weight: bold;

  a {
    font-weight: normal !important;
  }
  
  .map-popup-right-value {
    text-align: right;
  }

  &-content-do,
  &-content-algal {
    padding: 7px;
    color: $title-tertiary-color;
    align-items: center;
    display: grid;
  }

  &-content-do {
    grid-template-columns: 1fr 0.35fr 0.25fr;
    grid-column-gap: 5px;
  }

  &-content-algal {
    grid-template-columns: 1fr 0.7fr;
    grid-column-gap: 10px;
  }

  &-msg {
    font-weight: 400 !important;
    font-size: 14px;
  }

  &-type-0,
  &-type-1,
  &-type-2,
  &-type-do {
    border-radius: 4px;
  }
  &-type-0 {
    @include green-status(0.33, 0);
  }
  &-type-1 {
    @include amber-status(0.33, 0);
  }
  &-type-2 {
    @include red-status(0.33, 0);
  }
  &-type-do {
    background: rgba(164, 169, 172, 0.33);
  }

  &-prev-results {
    grid-template-columns: 1fr !important;

    &-icons {
      display: flex;
      grid-column-gap: 5px;
      &-item-0,
      &-item-1,
      &-item-2,
      &-item--1 {
        height: 15px;
        width: 15px;
      }

      &-item-0 {
        @include green-status(0, 1);
      }
      &-item-1 {
        @include amber-status(0, 1);
      }
      &-item-2 {
        @include red-status(0, 1);
      }
      &-item--1 {
        fill: #cccccc;
      }
    }
  }
  &-title {
    font-size: 18px;
    font-weight: bold;
    color: #0054a6;
    margin-bottom: 16px;
  }

  &-timestamp {
    font-size: 15px;
    font-style: italic;
    font-weight: normal;
  }

  &-view-details {
    text-align: right;
    padding-top: 15px;
    font-weight: normal;
    display: flex;
    justify-content: flex-end;
  }
}

@include sm {
  .algal-alert-popup {
    font-size: 14px;

    &-title {
      font-size: 20px;
    }

    &-timestamp {
      text-align: center;
      padding-top: 10px;
    }
  }
}
