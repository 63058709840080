@import '../../../abstract/variables';
@import '../../../abstract/mixins';

.error-container {
  padding: 150px 200px;
}

.error-img {
  padding-right: 24px;
  width: 84px;
  height: 89px;
}
.error-body {
  width: 650px;
}
.error-title {
  font-size: 40px;
  height: 89px;
  width: 625px;
  font-weight: bold;
  color: $text-primary-color;
  line-height: 1.13;
}

.error-text {
  font-size: 24px;
  font-weight: bold;
  color: $text-secondary-color;
}

.error-link {
  font-size: 24px;
  font-weight: bold;
  color: $text-primary-color;
}
