@import '../../abstract/variables';
@import '../../abstract/mixins';

.map-container {
  height: 75vh;
  background: rgb(236, 236, 236);
  position: relative;
  transition: height 0.5s;
}

.map-spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  top: 0;
  z-index: 500;
}

.spinner-border {
  display: flex;
  margin: 0 auto;
  color: $primary-color;
}

.map-error-modal {
  display: flex;
  justify-content: center;
}

.grey-layer {
  background-color: rgba(120, 120, 120, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
}

.map-widget {
  &-container {
    margin-top: 40px;
    margin-left: $page-margin-left-right;
    z-index: 460;
    position: absolute;
    display: flex;
    flex-direction: column;
    @include sm {
      margin-top: 10px;
      margin-left: 5vw;
    }
  }
}
