@import '../../abstract/variables';
@import '../../abstract/mixins';

.event-item {
  margin-top: 12px;

  &-content-box {
    @include spaceBetween;
    border-radius: 10px;
    border: 1px solid $primary-color;
    padding: 15px 15px;
    margin-bottom: 20px;
  }

  &-date {
    font-size: map-get($text-size, content-h2);
    width: 100%;
    color: $primary-color;
  }

  &-subdate {
    font-size: map-get($text-size, content-h3);
    width: 100%;
    text-align: left;
    color: $text-secondary-color;
    position: relative;
    margin-bottom: 10px;
  }

  &-content {
    width: 100%;
    padding-left: 15px;
  }

  &-title {
    color: $primary-color;
    font-size: map-get($text-size, content-h2);
    font-weight: bold;
    margin-bottom: 3px;
  }

  &-location {
    cursor: pointer;
    color: $primary-color;
    text-transform: uppercase;
    font-size: map-get($text-size, content-body-subtitle);
    margin-bottom: 10px;
  }

  &-details {
    width: 100%;
    text-align: right;
    margin-top: 10px;
  }

  &-link {
    width: 100%;
    text-align: right;
    font-weight: normal;
    color: $text-primary-color;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 10px;
  }

  &-link:hover {
    color: $text-primary-color;
    text-decoration: underline;
    font-weight: bold;
  }

  &-location a {
    color: $primary-color;
    text-decoration: underline;
  }

  &-body {
    word-wrap: break-word;
    color: $title-secondary-color;
    font-size: map-get($text-size, content-body);
    margin-top: 15px;
  }

  &-icon {
    height: 17px;
    width: 17px;
    padding-right: 5px;
  }
}

@include sm {
  .event-item {
    margin-top: 10px;

    &-content-box {
      @include spaceBetween;
      padding: 13px 14.3px;
      margin-bottom: 20px;
    }

    &-content {
      width: 90%;
      padding-left: 10px;
    }

    &-title {
      margin-bottom: 18px;
    }

    &-icon {
      height: 18px;
      width: 20px;
      padding-right: 7.7px;
    }

    &-location {
      font-size: map-get($mobile-text-size, content-h1);
      margin-bottom: 11.1px;
      display: flex;
      align-items: center;
    }

    &-body {
      font-size: map-get($mobile-text-size, content-body);
    }
  }
}
