@import '../../abstract/variables';
@import '../../abstract/mixins';

.social-link-item {
  padding: 0px 18px;
}

.social-link-link {
  color: inherit;
  font-size: map-get($tertiary-nav-item-text, font-size);

  &:hover {
    color: inherit;
  }
}
