@import '../../../abstract/variables';
@import '../../../abstract/mixins';

.general-button {
  border: 1px solid $primary-color;
  border-radius: 8px;
  color: white;
  background-color: $primary-color;
  padding: 8px 16px;
  font-size: map-get($text-size, 'content-body');
  font-weight: bold;

  &:hover {
    background-color: darken($primary-color, 8%);
  }
}
