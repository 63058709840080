@import '../../abstract/variables';
@import '../../abstract/mixins';

.map-filter {
  &-button {
    display: flex;
    position: absolute;
    right: 24.5px;
    z-index: 500;

    &-wrapper {
      display: flex;
      border-radius: 3px;
      margin-bottom: 2px;
      cursor: pointer;
      pointer-events: auto;
    }
    &-label {
      display: flex;
      align-items: center;
      justify-content: left;
      overflow: hidden;
      height: 29px;
      width: 0px;
      transition: all 0.4s linear;
      right: 0;
      font-size: 10px;
      font-weight: bold;
      color: $label-primary-color;
    }
    &-label.show {
      margin-left: 8px;
      width: 106px;
    }

    &-icon {
      height: 29px;
      width: 29px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-space {
    height: 31px;
  }
  &-dam-storage {
    background: $dam-storage;
  }
  &-river-gauge {
    background: $river-gauge;
  }
  &-environment {
    background: $environmental-flow-target;
  }
  &-rainfall {
    background: $rainfall;
    &-items {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
  &-pumps {
    background: $pumps;
  }
  &-order-usage {
    background: $order-usage;
  }
  &-flow-rate {
    background: $flow-rate;
  }
  &-drought-status {
    background: $drought-status;
  }
  &-water-quality {
    background: $water-quality;
    img {
      height: 20px;
    }
  }
  &-constraints {
    background: $constraint;
  }
  &-events {
    background: $recreational-event;
  }
}

.water-quality-info-icon {
  height: 25px;
  width: 25px;
  fill: $water-quality;
  background: white;
  border-radius: 50%;
  padding: 5px;
}
