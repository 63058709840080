@import '../../abstract/variables';
@import '../../abstract/mixins';

.chart-header {
  height: auto;
  justify-content: space-between;
  padding-bottom: 15px;
  &-desc {
    width: 100%;
  }

  &-download-link {
    width: auto;
    position: relative;
    right: 0px;
    color: $primary-color;
    display: none;
  }
}

@include sm {
  .chart-header {
    padding-bottom: 0px;
    &-desc {
      font-size: map-get($mobile-text-size, content-body);
    }
  }
}
