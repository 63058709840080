@mixin flexVerticalCenter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@mixin flex($float) {
  display: flex;
  float: $float;
}

@mixin headerAndFooter {
  height: 75px;
  background: $primary-color;
  vertical-align: middle;
  display: table;
  width: 100%;
}

@mixin tabBox($background) {
  border-radius: 10px 10px 0 0;
  display: flex;
  background-color: $background;
}

@mixin spaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: bottom;
}

@mixin textVerticalCenter {
  display: flex;
  align-items: center;
}

@mixin sm {
  @media (max-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin md {
  @media (max-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin lg {
  @media (max-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin xl {
  @media (max-width: #{$screen-xl-min}) {
    @content;
  }
}

@mixin xxl {
  @media (max-width: #{$screen-xxl-min}) {
    @content;
  }
}

@mixin show($direction, $distance) {
  height: auto;
  opacity: 1;
  #{$direction}: $distance;
}

@mixin hidden($direction, $distance) {
  height: 0;
  opacity: 0;
  margin: 0 !important;
  overflow: hidden;
  #{$direction}: $distance;
}

@mixin easeIn {
  transition: opacity 0.25s ease-in, bottom 0.25s ease-in;
  position: relative;
}

@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin linkText {
  color: $primary-color;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

// Mini app
@mixin componentTitle {
  font-size: 18px;
  color: $text-primary-color;
  font-weight: bold;
  // margin-bottom: 16px;
  text-transform: uppercase;
}

@mixin skeleton($shineRenderTime: 2.6s) {
  background-color: #ccc;
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  animation: shine-lines ($shineRenderTime) infinite linear;
  border-radius: 5px;
  margin-right: 2px;
  margin-left: 2px;
  height: 20px;
}
@keyframes shine-lines {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}

@mixin data-quality-legend {
  font-size: 12px;
  margin-top: 12px;
  background-color: #fafafa;
  display: grid;
  grid-template-columns: 1fr 0.9fr;
  grid-gap: 20px;
  align-items: center;
  justify-content: space-between;
  width: 360px;
  @include sm {
    width: 100%;
    margin-bottom: 10px;
    align-items: end;
  }
}
