@import '../../abstract/variables';
@import '../../abstract/mixins';

.data-summary {
  width: 100%;
  border-radius: 10px 10px 10px 10px;
  background: #ecedee;
  font-weight: bold;
  color: $title-tertiary-color;
  font-size: map-get($text-size, content-body-subtitle);
  transition: border-radius 1s ease;

  &-non-clickable {
    .expandBox-title-container {
      cursor: default !important;
    }
  }
  .expandBox-title-container {
    display: flex;
    justify-content: space-between;
  }

  .expandBox-title-container-textContainer {
    width: 93%;
  }

  &-selected {
    border-radius: 10px 10px 0px 0px;
    background: #e5edf6;
    color: $text-primary-color;
  }

  &-arrow {
    fill: black;
  }

  &-arrow-selected {
    fill: $text-primary-color;
  }
  &-content {
    background-color: #fafafa;
    border-radius: 0px 0px 10px 10px;
  }
}
