@import '../../../abstract/variables';
@import '../../../abstract/mixins';

.trading-limit {
  margin-top: 32px;
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  grid-column-gap: 20px;
  align-items: center;

  &-intro {
    &-title {
      font-size: 18px;
      color: $text-primary-color;
      font-weight: bold;
      margin-bottom: 6px;
    }
    &-text {
      font-size: 16px;
      color: $text-secondary-color;
    }
  }

  &-limit {
    width: 100%;
    background: rgba(0, 75, 163, 0.05);
    border-radius: 10px;
    padding: 17px 12px;
    position: relative;
    color: $text-secondary-color;
    font-weight: bold;

    &-volume {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      font-size: 16px;

      &-value {
        color: #707070;
        font-size: 40px;
        font-weight: bold;
        display: flex;
        align-items: flex-end;
        justify-content: center;
      }

      &-unit {
        font-size: 28px;
        padding-bottom: 0.3vw;
      }
    }

    &-date {
      text-align: center;
      left: 10%;
      right: 10%;
      bottom: 0;
      font-size: 14px;
      color: #707070;
      font-weight: normal;
    }
  }
}

@include md {
  .trading-limit {
    &-limit {
      &-volume {
        display: block;
        &-value {
          font-size: 30px;
        }
        &-unit {
          font-size: 18px;
        }
      }
    }
  }
}

@include sm {
  .trading-limit {
    display: block;
    &-limit {
      margin-top: 15px;
      padding: 12px;

      &-volume {
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        &-value {
          margin-top: 10px;
          font-size: 32px;
        }
        &-unit {
          font-size: 22px;
        }
      }
    }
  }
}
