@import '../../abstract/variables';
@import '../../abstract/mixins';

//light version
.min-nav-item {
  padding: 8px 0;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  &.not-show {
    display: none;
  }
  &:first-child {
    padding-top: 0px;
  }
  .nav-link-item-link {
    display: block;
    color: $title-tertiary-color;
    height: 16px;
    font-family: Arial;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
  }
}

//full-version
.nav-item {
  font-weight: bold;
  margin: 20px 0;
  &.not-show {
    display: none;
  }
  .nav-link-item-link {
    text-transform: uppercase;
  }
}

.external-link-item {
  padding: 10px 40px;
}

.nav-link-item {
  &-login {
    background: white;
    color: $text-primary-color;
    font-weight: bold;
    border-radius: 7px;
    padding: 5px 10px;
    width: 10vw;
    max-width: 160px;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
