@import '../../abstract/variables';
@import '../../abstract/mixins';

@include sm {
  .historical-usage {
    .general-dropdown {
      .expandBox-title-container-textContainer {
        min-width: 95px !important;
      }
      &-menu-item,
      &-button {
        font-size: map-get($mobile-text-size, content-body);
      }
    }
  }
}
