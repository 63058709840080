@import '../../abstract/variables';
@import '../../abstract/mixins';

.page-body-title {
  font-size: 24px;
  color: $title-primary-color;
  text-transform: uppercase;
  font-weight: bold;
  text-size-adjust: auto;
  @include sm {
    @include componentTitle;
  }
  &.archived {
    @include sm {
      font-size: 20px;
      text-transform: uppercase;
    }
  }
}

.page-title {
  font-size: map-get($text-size, page-h1);
  color: $title-primary-color;
  text-transform: uppercase;
  font-weight: bold;
}

.title-sub-title {
  color: $text-secondary-color;
}
