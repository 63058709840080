@import '../../abstract/variables';
@import '../../abstract/mixins';

.storage-chart {
  width: 100%;
  &-title {
    font-weight: bold;
    color: $label-primary-color;
    background: #707070;
    border-radius: 10px 10px 0 0;
    font-size: map-get($text-size, content-h1);

    span {
      padding: 22px;
      @include textVerticalCenter;
    }
  }

  &-header {
    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 32px 0px 6px 0px;
      &-text {
        font-size: map-get($text-size, content-h1);
        color: $text-primary-color;
        font-weight: bold;
        margin-right: 16px;
        &-container {
          display: flex;
          align-items: center;
        }
      }

      .view-details a {
        color: $text-primary-color;
        justify-self: flex-end;
      }
    }
  }
}

.singaling-icon {
  padding-left: 22px;
}

.storage-chart-body {
  padding: 30px 22px 30px 22px;
  height: auto;
}

.svg-reference-line-label {
  font-size: 18px;
  fill: $primary-color;
}

@include sm {
  .storage-chart {
    &-title {
      font-size: map-get($mobile-text-size, page-h1);
      span {
        padding: 12px 16px;
      }
    }
    &-body {
      padding: 15px 22px;
    }
  }

  .svg-reference-line-label {
    font-size: map-get($mobile-text-size, content-body);
  }
}
