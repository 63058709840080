@import '../../abstract/variables';
@import '../../abstract/mixins';

@include sm {
  .water-site-graph {
    background: rgba(0, 75, 163, 0.05);
    width: 100%;
    padding: 20px;
    color: $text-secondary-color;

    .expandBox-title-container {
      border-bottom: 2px solid #0054a6;
    }
    .expandBox-title-container-textContainer {
      width: 90% !important;
    }
    .general-dropdown-button {
      border: 0px;
    }

    &-content {
      min-height: 270px;

      .spinner-wrapper {
        position: unset;
      }
    }

    &-gauge {
      &-title {
        font-size: 16px;
        font-weight: bold;
        color: $text-primary-color;
        text-align: center;
        margin-bottom: 15px;
      }
      &-date {
        margin-top: 20px;
        font-size: 12px;
        display: grid;
        grid-column-gap: 2px;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-bottom: 1px solid #a5a9ac;
        padding-bottom: 10px;
        grid-template-columns: 0.3fr repeat(7, 0.5fr) 0.3fr;

        &-item {
          padding: 4px 2px;
          &-selected {
            border-radius: 10px;
            background: #7fa9d2;
            font-weight: bold;
            padding: 4px;
          }
        }
      }
    }

    &-storage {
      &-title {
        font-size: 16px;
        font-weight: bold;
        color: $text-primary-color;
      }
      &-date {
        margin-top: 20px;
        font-size: 12px;
        display: grid;
        grid-template-columns: repeat(12, 0.5fr);
        grid-column-gap: 2px;
        align-items: end;
        justify-content: center;
        text-align: center;
        line-height: 25px;
        border-bottom: 1px solid #a5a9ac;
        padding-bottom: 10px;

        &-selected {
          border-radius: 5px;
          background: #7fa9d2;
          font-weight: bold;
        }
      }
    }

    &-graph {
      width: 100%;
    }

    &-subtitle {
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        margin-left: 7px;
      }
    }

    &-volume {
      font-size: 20px;
      font-weight: bold;
      &-date {
        font-size: 12px;
        font-weight: normal;
      }
    }

    .general-dropdown {
      width: 250px;
      margin-bottom: 10px;

      &-button {
        background: none;
        color: $text-secondary-color;
        padding: 10px 0px;
        font-size: 18px;
        text-transform: unset;
      }
      &-menu {
        max-height: 40vh;
        width: 200px;
        margin-top: 0px;
        text-transform: unset;
      }
    }

    .expandBox-container {
      width: auto;
      min-width: 0;
      .expandBox-title-expand-shape {
        fill: $primary-color;
      }
    }

    &-date-range {
      font-size: 16px;
      color: $text-secondary-color;
      text-align: center;
      margin-bottom: 20px;
    }

    .toggle-button {
      margin-bottom: 40px;
      button {
        text-transform: uppercase;
        font-size: 16px;
        width: 50%;
      }
      button:first-of-type {
        border-left: solid 1px $primary-color;
        border-radius: 10px 0px 0px 10px;
      }
      button:last-of-type {
        border-radius: 0px 10px 10px 0px;
      }
    }

    &-arrow {
      $arrow: &;
      width: 25px;
      fill: $primary-color;
      cursor: pointer;

      &-disabled {
        fill: #4b555a;
      }

      &-left {
        @extend #{$arrow};
      }

      &-right {
        @extend #{$arrow};
        transform: rotate(180deg);
      }
    }

    &-interval {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
      grid-column-gap: 0px;
      text-align: center;
      font-weight: bold;
      font-size: 16px;

      div {
        padding: 15px;
      }
      &-selected {
        background: rgba(0, 75, 163, 0.05);
        color: $text-primary-color;
        text-decoration: underline;
        text-underline-position: under;
      }
    }

    &-gap {
      height: 30px;
    }

    &-message {
      font-size: 18px;
      color: $text-secondary-color;
      padding: 0px 60px;
      text-align: center;
      @include flexCenter;
      .view-details {
        color: #0054a6;
        cursor: pointer;
        text-decoration: underline;
        font-size: 16px;
      }
    }

    &-no-data {
      font-style: italic;
      text-align: center;
    }
  }
}
