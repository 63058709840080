@import '../../abstract/variables';
@import '../../abstract/mixins';

.restrictions-body {
  border-radius: 0px 0px 10px 10px;
  background: lighten($restriction, 40%);
  padding: 13px 19px;

  & > :first-child {
    border-top: none;
  }

  &-show {
    @include show(bottom, 0px);
  }

  &-hidden {
    @include hidden(bottom, -10px);
  }

  &-animation {
    @include easeIn;
  }

  &-header {
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    background: $restriction;
    font-weight: bold;
    color: $label-primary-color;
    font-size: map-get($text-size, content-h1);
    transition: border-radius 1s ease;
  }

  &-container {
    display: flex;
    align-items: center;
    cursor: auto;
  }

  // demostration purpose code
  &-arrow {
    fill: white;
    width: 20px;
    height: 20px;
  }

  &-null {
    color: $text-secondary-color;
  }

  &-expand-link {
    display: flex;
    margin-bottom: 32px;

    &-text {
      @include linkText;
    }
    &-arrow {
      transform: rotate(-90deg);
      fill: white;

      &-container {
        background-color: $primary-color;
        padding: 0px 2px;
        margin-left: 8px;
        border-radius: 5px;
        cursor: pointer;
      }

      &-expand {
        transform: rotate(90deg);
      }
    }
  }
}

.update-item-date.restrictions {
  font-size: map-get($text-size, content-body);
}

@include sm {
  .restrictions-body {
    font-size: map-get($mobile-text-size, content-body);
    background-color: unset;
    padding: 0px;
    color: $text-secondary-color;

    &-header {
      border-radius: 10px;
      font-size: map-get($mobile-text-size, content-h1);
    }
    &-null {
      margin: 16px 0px 0px 0px;
      text-align: center;
    }

    .update-item {
      padding-bottom: 15px;
      border-bottom: 1.5px solid #d2d7da;

      &-content {
        font-size: map-get($mobile-text-size, content-body);
      }
    }
  }
}
