@import '../../abstract/variables';
@import '../../abstract/mixins';

.locator-container {
  width: 324px;

  @include sm {
    width: 87vw;
  }

  input:focus {
    outline: 0;
  }

  button:focus {
    outline: 0;
  }
}

.locator-input {
  pointer-events: auto;
  margin-top: 8px;
  background: white;
  border: solid 0.8px $primary-color;
  border-radius: 10px;
  width: 100%;
  height: 45px;
  font-size: map-get($text-size, content-body);
  background-image: url('../../image/icons/locator.png');
  position: left;
  padding-left: 40px;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position-x: 10px;
  background-position-y: 8px;

  @include sm {
    background-size: 16px;
    background-position-y: 7px;
    height: 35px;
    background-size: 15px;
  }
}

.custom-style {
  background: red;
  height: 100px;
}

.locator-input-menu {
  background: white;
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  margin-bottom: 8px;
  margin-top: 5px;
  padding: 8px 0px;
  position: absolute;
  z-index: 1;
  box-sizing: border-box;
  width: 324px;

  @include sm {
    width: 87vw;
  }
}

.locator-item-list {
  color: $text-secondary-color;
  font-size: map-get($text-size, content-body);
  // border-bottom: 2px solid grey;

  &:after {
    content: '';
    display: block;
    margin: 10px auto;
    width: 90%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  }
}

.locator-item-label {
  padding-left: 10px;
}

.locator-icon {
  height: 21px;
  width: 21px;
}

.locator-item-icon {
  height: 20px;
  width: 15px;
}

.locator-item-wrapper {
  // margin-bottom: 15px;
  cursor: pointer;
  padding: 8px 10px;
  margin: 0px 10px;

  &:hover {
    background: #e5edf6;
    border-radius: 5px;
  }
}

.clear-btn {
  pointer-events: auto;
  font-size: 14px;
  font-style: italic;
  color: #a4a9ac;
  margin-left: -60px;
  z-index: 100;
}

.locator-popular-search-menu {
  margin: 0px 20px;
}

.locator-popular-search {
  font-size: 18px;
  font-weight: bold;
  color: $text-primary-color;
  margin: 0px 20px;
}
