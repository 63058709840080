@import '../../abstract/variables';
@import '../../abstract/mixins';

.river-data-summary {
  &-body {
    border-radius: 0px 0px 10px 10px;
    background: #fafafa;
    padding: 13px 20px;

    &-show {
      @include show(bottom, 0px);
    }

    &-hidden {
      @include hidden(bottom, -10px);
    }

    &-animation {
      @include easeIn;
    }

    &-header {
      width: 100%;
      border-radius: 10px 10px 10px 10px;
      background: #ecedee;
      font-weight: bold;
      color: $title-tertiary-color;
      font-size: map-get($text-size, content-body-subtitle);
      transition: border-radius 1s ease;
    }

    &-header-expanded {
      border-radius: 10px 10px 0px 0px;
      background: #e5edf6;
    }

    &-images {
      margin-left: 5px;
      margin-top: 0.25rem;
    }

    // demostration purpose code
    &-arrow {
      fill: $text-secondary-color;
      width: 20px;
      height: 20px;
      margin-left: 3px;
      align-content: right;
      &-bold {
        fill: $primary-color;
        width: 20px;
        height: 20px;
      }
    }
    &-attribution-group {
      margin-top: 10px;
    }
    &-attribution {
      font-size: 12px;
      text-align: right;
    }
  }
  &-body-header .expandBox-title-container {
    padding: 9px 0 9px 8px;
  }

  &-body-header .expandBox-title-container-textContainer {
    width: 99%;
  }

  &-body-table-title {
    visibility: hidden;
  }

  &-body-chart {
    padding-top: 1rem;
  }

  &-body-chart-title {
    width: 50%;
    position: absolute;
    padding: 10px;
    div {
      font-size: 17px;
    }
  }

  .river-data-info-icon {
    fill: $primary-color;
  }

  .graph-custom-tooltip {
    background: white;
    border: 1px solid rgb(204, 204, 204);
    padding: 10px;
    white-space: nowrap;

    &:focus {
      outline: none;
    }

    p {
      margin-bottom: 0.5rem;
    }
    &-value {
      color: $text-primary-color;
    }
  }
  
  &-table {
    .data-summary-table-content-header {
      justify-content: flex-end;
    }
  }
}

@include md {
  .river-data-summary {
    &-body-header .expandBox-title-container {
      padding: 9px 8px;
    }

    &-body-header .expandBox-title-container-textContainer {
      width: 85%;
    }

    &-body-header .expandBox-title-expand .expandBox-title-expand-collapse {
      align-items: right;
      width: 5%;
    }

    &-body {
      padding: 0 10px;

      &-arrow,
      &-arrow-bold {
        width: 15px;
        height: 15px;
        color: red;
      }

      &-chart {
        display: none;
      }

      &-table-title {
        visibility: visible;
        position: relative;
        padding-top: 10px;
        div {
          font-size: 13px;
        }
      }
    }
  }
}

@include sm {
  .river-data-summary {
    &-body {
      &-header {
        border-radius: 10px;
      }
    }
    .expandBox-title-container-textContainer {
      width: 93% !important;
    }
  }
}
