@import '../../../../abstract/variables';
@import '../../../../abstract/mixins';

.download-map {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  &-container {
    height: 500px;
    position: relative;

    &-msgSelection {
      display: flex;
      align-items: center;
      position: absolute;
      top: -30px;
      font-size: 16px;
      color: #4b555a;
      margin-bottom: 16px;

      p {
        margin: 0px;
      }

      &-icon {
        background: #d12814;
        fill: white;
        border-radius: 50%;
        margin-right: 8px;
      }
    }
  }
}

.leaflet-top .leaflet-control {
  opacity: 0%;
  z-index: -1;
}

@include lg {
  .download-map {
    &-container {
      &-msgSelection {
        top: -48px;
      }
    }
  }
}
