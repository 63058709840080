@import '../../abstract/variables';
@import '../../abstract/mixins';

.research-body {
  &-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 32px 0px 6px 0px;
    &-text {
      font-size: map-get($text-size, content-h2);
      color: $text-primary-color;
      font-weight: bold;
      margin-right: 5px;
      &-container {
        display: flex;
        align-items: center;
      }
    }

    &-icon {
      fill: $primary-color;
      width: 16px;
      height: 16px;
      margin-bottom: 3px;
    }

    .view-details a {
      color: $text-primary-color;
      justify-self: flex-end;
    }
  }
}
