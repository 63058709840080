@import '../../abstract/variables';
@import '../../abstract/mixins';

.river-data-summary-graph {
  position: relative;

  .spinner-wrapper {
    top: 15%;
  }

  .general-dropdown-menu-medium {
    width: 260px !important;
  }

  .general-dropdown {
    &-menu, &-button {
      text-transform: none;
    }
  }

  .recharts-legend-wrapper {
    bottom: 0px !important;
    li {
      cursor: pointer;
    }
  }
}
