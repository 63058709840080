@import '../../abstract/variables';
@import '../../abstract/mixins';

.lateral-hydrograph {
  position: relative;
  
  .spinner-wrapper {
    top: 100px;
  }
  .recharts-label {
    fill: #707070;
    font-weight: bold;
    font-size: 18px;
  }
  .legend-label {
    font-size: 14px !important;
  }
}

@include sm {
  .lateral-hydrograph {
    .recharts-legend-wrapper {
      left: 0px !important;
    }
    .recharts-label {
      font-size: 14px;
    }
    .customized-legend {
      display: inline-block;
      margin-top: 0px;
      padding-left: 0px;

      .legend-item {
        text-align: left;
        display: block;

        .legend-label {
          margin-left: 5px;
        }
      }
    }
    .recharts-tooltip-wrapper {
      font-size: 12px;
    }
  }
}
