@import './riverDataSummaryHeader';
@import '../../abstract/variables';
@import '../../abstract/mixins';

.river-data-summary {
  @include headerColumns;
  &-overview {
    &-container {
      display: flex;
      align-items: center;

      &-station-id-selected,
      &-station-name-selected {
        color: $primary-color;
      }
      &-station-name {
        padding-right: 10px;
        &-selected {
          padding-right: 10px;
        }
      }
      &-level {
        padding-right: 10px;
        text-align: right;
      }
      &-flow_rate {
        padding-right: 25px;
        text-align: right;
      }
      &-date {
        padding-right: 10px;
      }
    }

    &-commentary {
      font-weight: normal;
      font-style: italic;
      font-size: 12px;
    }
  }
}

@include md {
  .river-data-summary {
    &-overview {
      padding: 0 10px;

      &-container {
        &-station-name span {
          box-sizing: border-box;
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          display: block;
        }
        &-station-name,
        &-station-name-selected {
          box-sizing: border-box;
          width: 35%;
          display: inline-block;
        }
        &-level,
        &-level-bold {
          box-sizing: border-box;
          width: 15%;
          display: inline-block;
          text-align: left;
        }
        &-flow_rate,
        &-flow_rate-bold {
          box-sizing: border-box;
          width: 15%;
          display: inline-block;
          text-align: left;
        }
      }
    }
  }
}

@include sm {
  .river-data-summary {
    &-overview {
      &-container {
        font-size: 13px;
        justify-content: space-between;
      }
    }
  }
}
.skeleton {
  @include skeleton(8s);
}
