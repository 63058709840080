@import '../../../abstract/variables';
@import '../../../abstract/mixins';

.general-tab-item {
  @include tabBox($primary-color);
  padding: 16px;
  box-shadow: $tab-box-shadow;
  white-space: nowrap;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  color: $label-primary-color;
  margin: 0px 2px;
  &:first-child {
    margin-left: 0px;
  }

  &:last-child {
    margin-right: 0px;
  }

  &-selected {
    @include tabBox(white);
    margin-top: -5px;
    margin-bottom: 0px;
    transition: background-color 0.5s;
    color: $text-primary-color;
  }

  &-disabled {
    @include tabBox(#c9c9c9);
    cursor: default;
  }
}

@include xl {
  .general-tab-item {
    padding: 10px;
    font-size: 14px;
  }
}
