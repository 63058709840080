@import '../../abstract/variables';
@import '../../abstract/mixins';

.feedback-form-item {
  display: flex;
  flex-direction: column;
  width: 100%;

  &-label {
    font-size: map-get($text-size, content-h2);
    font-weight: bold;
    margin-bottom: 6px;
    color: $text-secondary-color;
  }

  &-input {
    padding: 5px;
    border: 1px solid rgba(143, 141, 141, 0.842);
    border-radius: 5px;
    margin-bottom: 6px;

    &:focus {
      outline: none;
    }

    &-error {
      border: 1px solid red;
    }
  }
  &-dropdown {
    margin-bottom: 6px;
  }

  &-error {
    margin: 6px 0px;
    font-size: map-get($text-size, content-body-subtitle);
    color: red;
  }

  &-textarea {
    resize: none;
  }
  
  .general-dropdown {
    &-button {
      background: none;
      color: black;
      border-radius: 5px !important;
      border: 1px solid rgba(143, 141, 141, 0.842);
      font-size: inherit !important;
      text-transform: none;
      font-weight: normal;
      padding: 5px;

      .expandBox-title-expand-shape {
        fill: #4b555a;
      }
    }

    &-menu {
      width: 100%;
      text-transform: none;
      font-weight: normal;

      &-item, &-item-selected {
        padding: 5px;
        
      }

      &-item {
        background-color: white !important;
      }
      &-item:hover, &-item-selected {
        background-color: #e5edf6 !important;
        color: #4b555a !important;
      }
    }
  }
}
