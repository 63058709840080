@import './abstract/variables';
@import './abstract/mixins';

#render {
  width: 450px;
  height: 250px;
}

.app {
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  a:hover {
    text-decoration: none;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
