@import '../../abstract/variables.scss';
@import '../../abstract/mixins';

.pagination-container {
  display: flex;
  list-style-type: none;
  padding: 0;
  justify-content: center;

  .pagination-item {
    padding: 0 12px;
    height: 32px;
    width: 32px;
    text-align: center;
    margin: auto 2px;
    color: $primary-color;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    border-radius: 50%;
    line-height: 14px;
    font-size: 14px;
    min-width: 32px;
    cursor: pointer;
    justify-content: center;

    &:first-child,
    &:last-child {
      width: 40px;
      &:hover {
        background-color: transparent !important;
      }
    }
    &:not(:first-child),
    &:not(:last-child) {
      &:hover {
        background-color: #f4f4f4;
      }
    }
    &.dots:hover {
      background-color: transparent;
      color: $primary-color;
      cursor: default;
    }

    &.selected,
    &.selected:hover {
      background-color: $primary-color !important;
      color: white !important;
    }

    .arrow {
      fill: $tertiary-color;
      &:hover {
        fill: $primary-color;
      }
      &-right {
        transform: rotate(180deg);
        fill: $tertiary-color;
        &:hover {
          fill: $primary-color;
        }
      }
    }

    &.disabled {
      pointer-events: none;
      &:hover {
        background-color: transparent;
        cursor: default;
      }
      .arrow,
      .arrow-right {
        fill: $text-secondary-color;
      }
    }
  }
}
