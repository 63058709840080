@import '../../abstract/variables';
@import '../../abstract/mixins';

.item-base-style {
  display: flex;
  align-items: center;
  font-weight: normal;
  margin-bottom: 30px;
  margin-top: 20px;
}

@mixin menu-item {
  background: unset !important;
  color: $text-secondary-color !important;
  padding: 3px 0 3px 10px;
  &:hover {
    background-color: $primary-color !important;
    color: $label-primary-color !important;
  }
}

.gauge-alert-modal-body {
  color: $text-secondary-color;
  font-weight: bold;
  font-size: 16px;

  @include sm {
    font-size: 14px;
  }

  &-title {
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 20px;
    @include sm {
      font-size: 14px;
      text-transform: none;
    }
  }

  &-condition-dropdown {
    .item-base-style {
      @extend .item-base-style;
    }
  }

  &-condition-input {
    span {
      line-height: 38px;
    }
  }

  &-threshold {
    @extend .item-base-style;
    gap: 10px;
    @include sm {
      flex-wrap: wrap;
      margin-top: 10px;
      gap: 5px;
    }

    &-dropdowns {
      display: flex;
      gap: 5px;
      flex-wrap: wrap;
      align-items: center;
    }

    &-value {
      width: 150px;
      height: 38px;
      padding: 5px 10px;
      border: 1px solid #4b555a;
      border-radius: 4px;
      @include sm {
        min-width: 50px;
        margin: 0;
      }
    }
  }

  .general-dropdown {
    &-button {
      color: #0054a6;
      background: #fff;
      border: 1px solid #0054a6;
      border-radius: 4px !important;
      padding: 5px 10px !important;
      cursor: pointer;
      .expandBox-title-expand-shape {
        fill: #0054a6;
      }
    }
    &-menu {
      margin-top: 0;
      padding: 0 5px;
      width: 300px;
      &-item {
        background-color: unset;
      }
      &-item,
      &-item-selected {
        @include menu-item;
      }
    }
  }

  &-interval {
    @extend .item-base-style;
    input {
      margin-right: 10px;
    }
  }

  &-type {
    @extend .item-base-style;
    input {
      margin-right: 10px;
      accent-color: $primary-color;
    }
  }

  &-button {
    text-transform: uppercase;
    background: $primary-color;
    border-radius: 4px;
    padding: 12px 16px;
    color: white;
    width: 130px;
    text-align: center;
    cursor: pointer;

    &-disabled {
      cursor: default;
      background-color: $title-secondary-color;
    }
  }
}

.alert-modal-body {
  color: $text-secondary-color;
  font-weight: bold;
  font-size: 16px;
  width: 450px;
  @include sm {
    width: 300px;
    font-size: 14px;
  }

  &-title {
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  &-type {
    @extend .item-base-style;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: flex-start;

    .general-dropdown {
      font-size: 16px;
      margin: 0;
      .expandBox-title {
        &-expand-shape {
          fill: $primary-color;
        }
      }
      &-button {
        color: $text-primary-color;
        background: white;
        border: 1px solid $primary-color;
        border-radius: 4px !important;
        padding: 5px 10px !important;
        cursor: pointer;
      }

      &-menu {
        margin-top: 0px;
        padding: 5px 10px;
        &-item,
        &-item-selected {
          @include menu-item;
          padding: 0 0 0 10px !important;
        }
      }
    }

    &-value {
      margin-left: 20px;
      width: 120px;
      height: 38px;
      padding: 5px 10px;
      border: 1px solid #4b555a;
      border-radius: 4px;
    }

    &-item {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  &-interval {
    @extend .item-base-style;
    input {
      margin-right: 10px;
    }
  }

  &-type {
    @extend .item-base-style;
    input {
      margin-right: 10px;
    }
  }

  &-button {
    text-transform: uppercase;
    background: $primary-color;
    border-radius: 4px;
    padding: 12px 16px;
    color: white;
    width: 130px;
    text-align: center;
    cursor: pointer;

    &-disabled {
      cursor: default;
      background-color: $title-secondary-color;
    }
  }
}

.item-base-style {
  display: flex;
  align-items: center;
  font-weight: normal;
  margin-bottom: 30px;
  margin-top: 20px;
}
