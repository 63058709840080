@import '../../../abstract/variables';
@import '../../../abstract/mixins';

.state-overview-table {
  display: flex;

  &-row {
    display: grid;
    grid-template-rows: 1fr;
    margin-bottom: 10px;
    font-size: map-get($text-size, 'content-body-subtitle');
  }

  &-column {
    margin-top: 4px;
    margin-right: 30px;
    &-hide {
      opacity: 0;
    }
    &-header {
      @extend .state-overview-table-row;
      color: $text-secondary-color;
      font-size: 12px;
    }
  }

  &-cell {
    font-weight: bold;
    text-align: right;
  }

  &-header {
    @extend .state-overview-table-row;
    font-weight: bold;
  }
}
