@import '../../abstract/variables';
@import '../../abstract/mixins';

.dam-info {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  width: 100%;

  &-title {
    display: flex;
    margin-bottom: 10px;
    &-text {
      font-size: map-get($text-size, content-h1);
      font-weight: bold;
      color: $title-tertiary-color;
    }
    &-map-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      &-bg {
        border-radius: 50%;
        background-color: $primary-color;
        height: 36px;
        width: 36px;
        position: relative;
        margin-left: 30px;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  &-links {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    &-storage {
      font-size: map-get($text-size, content-body);
      color: $text-primary-color;
      text-decoration: underline;
    }
    &-desc {
      @extend .dam-info-links-storage;
      cursor: pointer;
      &-icon {
        cursor: pointer;
        width: 19px;
        padding: 0 0 4px 4px;
      }
    }
  }

  &-daily-update {
    width: 100%;
  }
}

@include sm {
  .dam-info {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-mobile-title {
      font-size: 18px;
      font-weight: bold;
      width: 100%;
      color: $primary-color;
      display: flex;
      justify-content: space-between;
      text-transform: uppercase;
    }

    &-title-map-icon-bg {
      margin-left: 0px;
      margin-bottom: 16px;
    }
  }
}
