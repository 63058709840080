@import '../../abstract/variables';
@import '../../abstract/mixins';

.onboarding-overlay {
  position: absolute;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  z-index: 999999;
  opacity: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.onboarding-showElement {
  z-index: 9999999 !important;
}

.onboarding-disableInteraction {
  z-index: 99999999 !important;
  position: absolute;
  background-color: #ffffff;
  opacity: 0;
  filter: alpha(opacity=0);
}

.onboarding-relativePosition {
  position: relative;
}

.onboarding-helperLayer {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  position: absolute;
  z-index: 9999998;
  border-radius: 4px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.onboarding-tooltipReferenceLayer {
  font-family: 'Helvetica Neue', Inter, ui-sans-serif, 'Apple Color Emoji', Helvetica, Arial,
    sans-serif;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  position: absolute;
  visibility: hidden;
  z-index: 100000000;
  background-color: transparent;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.onboarding-arrow {
  border: 5px solid transparent;
  content: '';
  position: absolute;
}

.onboarding-arrow.top {
  top: -10px;
  left: 10px;
  border-bottom-color: #ffffff;
}

.onboarding-arrow.top-right {
  top: -10px;
  right: 10px;
  border-bottom-color: #ffffff;
}

.onboarding-arrow.right {
  right: -10px;
  top: 10px;
  border-left-color: #ffffff;
}

.onboarding-arrow.right-bottom {
  bottom: 10px;
  right: -10px;
  border-left-color: #ffffff;
}

.onboarding-arrow.bottom {
  bottom: -10px;
  left: 10px;
  border-top-color: #ffffff;
}

.onboarding-arrow.bottom-right {
  bottom: -10px;
  right: 10px;
  border-top-color: #ffffff;
}

.onboarding-arrow.left {
  left: -10px;
  top: 10px;
  border-right-color: #ffffff;
}

.onboarding-arrow.left-bottom {
  left: -10px;
  bottom: 10px;
  border-right-color: #ffffff;
}

.onboarding-tooltip {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  position: absolute;
  visibility: visible;
  background-color: #ffffff;
  min-width: 400px;
  border-radius: 5px;
  -webkit-box-shadow: 0 3px 30px rgba(33, 33, 33, 0.3);
  box-shadow: 0 3px 30px rgba(33, 33, 33, 0.3);
  -webkit-transition: opacity 0.1s ease-in-out;
  -o-transition: opacity 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out;
}

.onboarding-tooltipbuttons {
  border-top: 1px solid #e0e0e0;
  text-align: right;
  white-space: nowrap;
  padding: 10px 10px 5px 5px;
}

.onboarding-tooltipbuttons:after {
  content: '';
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}

.onboarding-button {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  position: relative;
  overflow: visible;
  display: inline-block;
  padding: 0.5rem 1rem;
}

.onboarding-skipbutton {
  float: left;
}

.onboarding-prevbutton {
  float: center;
}

.onboarding-bullets {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.onboarding-bullets ul {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  clear: both;
  margin: 0 auto 0;
  padding: 0;
  display: inline-block;
}

.onboarding-bullets ul li {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  list-style: none;
  float: left;
  margin: 0 2px;
}

.onboarding-bullets ul li a {
  -webkit-transition: width 0.1s ease-in;
  -o-transition: width 0.1s ease-in;
  transition: width 0.1s ease-in;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  display: block;
  width: 6px;
  height: 6px;
  background: rgb(145, 138, 138);
  border-radius: 10px;
  text-decoration: none;
  cursor: pointer;
}

.onboarding-bullets ul li a:hover,
.onboarding-bullets ul li a:focus {
  width: 15px;
  background: $tertiary-color !important;
  text-decoration: none;
  outline: none;
}

.onboarding-bullets ul li a.active {
  width: 15px;
  background: $tertiary-color !important;
}

.onboarding-tooltiptext {
  padding: 20px 20px 0px 20px;
}

.onboarding-arrow.top {
  border-style: solid;
  border-width: 0 24px 20px 24px;
  border-color: transparent transparent #ffffff transparent;
  top: -17px;
}

.onboarding-arrow.top-middle {
  border-style: solid;
  border-width: 0 24px 20px 24px;
  border-color: transparent transparent #ffffff transparent;
  top: -17px;
  margin-left: -20px;
}

.onboarding-arrow.left {
  border-style: solid;
  border-width: 28px 24px 28px 0;
  border-color: transparent #ffffff transparent transparent;
  left: -17px;
}

.onboarding-arrow.right {
  right: -17px;
  border-style: solid;
  border-width: 28px 0 28px 24px;
  border-color: transparent transparent transparent #ffffff;
}

.onboarding-arrow.right-bottom {
  right: 17px;
  border-style: solid;
  border-width: 28px 24px 28px 0;
  border-color: transparent #ffffff transparent transparent;
}

.onboarding-helperLayer {
  box-shadow: rgba(226, 216, 216, 0) 0px 0px 1px 2px, rgba(95, 94, 94, 0.8) 0px 0px 0px 5000px !important;
  border-radius: 9px !important;
  border: none;
}

.onboarding-bottom,
.onboarding-bottom-left-aligned,
.onboarding-bottom-middle-aligned {
  top: calc(100% + 25px) !important;
}

.onboarding-right {
  left: calc(100% + 25px) !important;
}

.onboarding-left {
  right: calc(100% + 25px) !important;
}

.onboarding-nextbutton {
  float: right;
  background-color: white;
  text-transform: uppercase;
  box-shadow: none;
  font-size: 15px;
  font-weight: bold;
  border-radius: 0;
  color: $text-primary-color !important;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-family: Arial;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.onboarding-prevbutton,
.onboarding-skipbutton {
  background-color: white;
  text-transform: uppercase;
  box-shadow: none;
  font-weight: bold;
  font-size: 15px;
  border-radius: 0;
  color: #707070 !important;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-family: Arial;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

@include sm {
  .onboarding-tooltip {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    position: absolute;
    min-width: 100px !important;
    max-width: 320px !important;
  }
  .onboarding-bottom-middle-aligned {
    left: 10px !important;
  }
}
