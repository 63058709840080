@import '../../abstract/variables';
@import '../../abstract/mixins';

.introduction-container-content {
  width: 100%;
  position: relative;

  &-inner {
    display: flex;
    &-right {
      display: block;
      padding-left: 24px;
      padding-top: 0;
    }
  }

  .back-link {
    padding-bottom: 20px;
  }

  &-header {
    margin-bottom: 20px;
  }

  &-license {
    $innerHorizontalPadding: 20px;
    margin-top: 32px;

    &-header {
      display: flex;
      font-weight: bold;
      align-items: center;
      font-size: map-get($text-size, content-h1);
      color: $label-primary-color;
      background-color: $primary-color;
      padding: 18px $innerHorizontalPadding;
      border-radius: 10px 10px 0px 0px;
    }

    &-content {
      background-color: $section-background-color;
      padding: 20px $innerHorizontalPadding;
      color: $text-secondary-color;
      border-radius: 0px 0px 10px 10px;
    }
  }

  &-show {
    @include show(bottom, 0px);
  }

  &-hidden {
    @include hidden(bottom, -100px);
  }

  &-easeIn {
    @include easeIn;
  }

  &-expand {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &-expand-button {
    padding: 0;
    border: none;
    background-color: inherit;
    color: $tertiary-color;
    text-decoration: underline;

    &:focus {
      outline: none;
    }
  }
}

@include sm {
  .introduction-container-content {
    width: 100%;
    min-width: unset;

    &-inner {
      display: block;
      &-right {
        padding: 0;
      }
    }

    &-header {
      line-height: 1.13;
      font-size: map-get($mobile-text-size, content-h1);
    }

    &-license {
      $innerHorizontalPadding: 22px;
      margin-top: 24px;

      &-header {
        font-size: map-get($mobile-text-size, content-h1);
        padding: 13px $innerHorizontalPadding;
        line-height: 1.13;
        text-transform: uppercase;
      }

      &-content {
        padding: 20px $innerHorizontalPadding;
        font-size: map-get($mobile-text-size, content-body);
      }
    }

    &-expand {
      font-size: map-get($mobile-text-size, content-body);
    }
  }
}

@include md {
  .introduction-container-content {
    width: 100%;
    min-width: unset;

    &-inner {
      display: block;
      &-right {
        padding: 0;
      }
    }

    &-header {
      line-height: 1.13;
      font-size: map-get($mobile-text-size, content-h1);
    }

    &-license {
      $innerHorizontalPadding: 22px;
      margin-top: 24px;

      &-header {
        font-size: map-get($mobile-text-size, content-h1);
        padding: 13px $innerHorizontalPadding;
        line-height: 1.13;
        text-transform: uppercase;
      }

      &-content {
        padding: 20px $innerHorizontalPadding;
        font-size: map-get($mobile-text-size, content-body);
      }
    }

    &-expand {
      font-size: map-get($mobile-text-size, content-body);
    }
  }
}
