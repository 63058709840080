@import '../../abstract/variables';
@import '../../abstract/mixins';

.data-refresh-button {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  &-button {
    cursor: pointer;
    @include sm {
      margin-bottom: unset;
    }
    &-text {
      font-size: map-get($text-size, 'content-body');
      font-weight: bold;
      color: $text-primary-color;
      margin: 0px 8px;
      @include sm {
        font-size: 14px;
      }
    }
    &-icon {
      margin-bottom: 3px;
      @include sm {
        width: 18px;
        height: 18px;
      }
    }

    margin-bottom: 8px;
  }

  &-timestamp {
    color: $text-secondary-color;
    font-size: map-get($text-size, 'content-body-subtitle');
    font-style: italic;
    @include sm {
      font-size: 12px;
    }
  }
}
