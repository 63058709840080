@import '../../abstract/variables';
@import '../../abstract/mixins';

.dropdown-toggle::after {
  all: unset;
}

.dropdown-menu-invisible {
  display: none;
}

.watersource-drop-down {
  display: block;

  &-header {
    font-size: 16px;
    color: $text-secondary-color;
    font-weight: bold;
    display: none;
    @include sm {
      display: block;
    }
  }

  &-menu {
    display: inline-block;
  }

  &-info-icon {
    filter: grayscale(100%);
    margin-left: 4px;
    height: 17px;
    width: 17px;
  }

  &-content {
    background: white;
    font-size: 16px;
    overflow-x: auto;
    border-radius: 10px;
    max-height: 55vh;
    min-width: 420px;
    color: $text-primary-color;
    line-height: 32px;
    padding: 20px;
    display: block;

    input:focus {
      outline: 0;
    }

    @include sm {
      font-size: 14px;
      line-height: 28px;
      min-width: 87vw;
      -webkit-box-ordinal-group: 2;
      -moz-box-ordinal-group: 2;
      padding: 15px;
    }
  }

  .dropdown-menu {
    z-index: 500;
  }

  .dropdown-menu::-webkit-scrollbar-track {
    border-radius: 20px;
    background-color: none;
  }

  .dropdown-menu::-webkit-scrollbar {
    width: 20px;

    @include sm {
      width: 15px;
    }
  }

  .dropdown-menu::-webkit-scrollbar-thumb {
    border-radius: 15px;
    border: 5px solid white;
    background-color: #b1b1b1;
    height: 40%;
  }

  @include sm {
    width: 87vw;
    order: -1;
  }
}

.drop-down-menu-btn {
  @include textVerticalCenter;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  pointer-events: auto;
  white-space: normal;
  text-align: left;
}

.drop-down-menu-icon {
  background: $primary-color;
  border-radius: 5px;
  height: 17px;
  width: 17px;
  margin-left: 11px;
}

.water-source-info {
  @include textVerticalCenter;
  margin-top: 11px;
  flex-wrap: wrap;

  button:focus {
    outline: 0;
  }

  span {
    display: inline;
  }

  @include sm {
    margin-top: 0px;
  }

  &-menu-btn {
    color: $text-primary-color;
    font-size: map-get($text-size, content-h2);
    font-weight: bold;
    line-height: 22px;
  }

  &-menu {
    font-style: map-get($text-size, content-h2);
  }
}

.dropdown-item-cust {
  font-weight: bold;
  color: $text-secondary-color;
  margin-bottom: 8px;
  padding: 0;
  background: #edeeee;
  padding: 18px;
  border-radius: 10px;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    background: $primary-color;
    color: white;
  }
}

.view-more-menu-item {
  pointer-events: auto;
  text-decoration: underline;
}

.dropdown-item-seperator {
  &:after {
    content: '';
    display: block;
    margin: 10px auto;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  }
}

.dropdown-item {
  &-clickable {
    pointer-events: auto;

    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
    }
  }

  &-watersource {
    line-height: 20px;
    padding-bottom: 10px;
    @extend .dropdown-item-clickable;

    @include sm {
      padding-bottom: 6px;
    }
  }

  &-type {
    font-weight: bold;
    font-size: 18px;

    @include sm {
      font-size: 16px;
    }
  }
}

.item-selected {
  background: $primary-color;
  color: white;
}

.dropdown-item-more {
  text-transform: none;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.dropdown-item-list {
  cursor: pointer;
  pointer-events: none;
}

.find-input {
  pointer-events: auto;
  margin-bottom: 20px;
  background: white;
  border: solid 0.8px $primary-color;
  border-radius: 10px;
  width: 100%;
  height: 45px;
  font-size: 16px;
  background-image: url('../../image/icons/search.svg');
  position: left;
  padding-left: 40px;
  background-size: 30px;
  background-repeat: no-repeat;
  background-position-x: 8px;
  background-position-y: 8px;

  @include sm {
    background-size: 20px;
    height: 35px;
    margin-bottom: 10px;
    background-position-x: 6px;
    background-position-y: 6px;
  }
}

.warning-msg {
  font-style: italic;
  color: $text-secondary-color;
}
