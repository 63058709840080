@import '../../abstract/variables';
@import '../../abstract/mixins';

.scale-label-basic {
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  font-size: 12px;
}

.color-bar-basic {
  text-align: center;
  height: 22px;
  border-radius: 10px;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
}

.observed-color-bar-base {
  @extend .color-bar-basic;
  width: 320px;
  border: 1px solid black;
  opacity: 0.75;
}

.climate-map-legend {
  background: rgba(255, 255, 255, 1);
  width: 400px;
  border-radius: 5px;
  padding: 15px;
  font-size: 16px;
  color: $title-tertiary-color;
  font-weight: bold;
  margin-bottom: 5px;
  margin-right: 24.5px;
  z-index: 1000;

  &-outlook-color-bar {
    @extend .color-bar-basic;
    opacity: 0.75;
  }

  &-observed-color-bar {
    @extend .observed-color-bar-base;
    background-image: linear-gradient(
      to right,
      #ff1919,
      #ff6666,
      #ff6666,
      #ffcccb,
      #ffcccb,
      #ffffff,
      #ffffff,
      #ffffff,
      #ffffff,
      #ccccff,
      #ccccff,
      #6665ff,
      #6665ff,
      #0000fe
    );
  }

  &-observed-temp-color-bar {
    @extend .observed-color-bar-base;
    background-image: linear-gradient(
      to right,
      #0000cc,
      #4b4cfe,
      #4b4cfe,
      #807ffe,
      #807ffe,
      #cacdff,
      #cacdff,
      #ffcc19,
      #ffcc19,
      #ff7e18,
      #ff7e18,
      #ff4c19,
      #ff4c19,
      #cc0001
    );
  }

  &-color-scale-observed {
    width: 300px;
    @extend .scale-label-basic;
  }

  &-color-scale-outlook {
    width: 330px;
    @extend .scale-label-basic;
  }

  &-color-scale-label {
    margin-top: 12px;
    width: 100%;
    @extend .scale-label-basic;

    &-text {
      width: 80px;
      text-align: center;
      font-size: 11px;
    }
  }

  &-color-box {
    width: 7.143%;
    border: 1px solid #707070;
    display: inline-block;
    height: 100%;
  }

  &-licence {
    margin-top: 5px;
    font-size: 11px;
    font-weight: 500;

    &-link {
      cursor: pointer;
      pointer-events: auto;
    }
  }
}

.left-rounded-border {
  border-radius: 10px 0px 0px 10px;
}

.right-rounded-border {
  border-radius: 0px 10px 10px 0px;
}
