@import '../../../../abstract/variables';
@import '../../../../abstract/mixins';

.download-filters {
  &-filter {
    &-container {
      .expandBox {
        &-title-container {
          flex-direction: row-reverse;
          justify-content: flex-end;
        }
      }

      color: $primary-color;
      margin-bottom: 5px;
      font-size: map-get($text-size, 'content-h2');
    }

    &-arrow {
      fill: $primary-color;
    }

    &-content {
      background-color: white;
      margin-bottom: 20px;
      padding: 20px;
    }
  }
  &-input {
    margin: 4px 0px;
  }

  &-error {
    margin-top: 8px;
    padding: 4px 0px;
    display: flex;
    align-items: center;
    &-icon {
      background-color: #d12814;
      fill: white;
      padding: 1px;
      border-radius: 100%;
      margin-right: 8px;
      width: 14px;
      height: 14px;
    }

    &-text {
      width: 80%;
      display: flex;
      align-items: center;
      font-size: 11px;
      font-style: italic;
      color: #4b555a;
      margin: 0;
    }
  }

  &-suggestion {
    position: absolute;
    background: white;
    border-radius: 10px;
    padding: 16px;
    z-index: 2000;

    &-item {
      color: $text-secondary-color;
      padding: 8px 12px;
      cursor: pointer;
      border-bottom: 2px solid #fafafa;

      &:last-of-type {
        border-bottom: none;
      }

      &:hover {
        color: $primary-color;
        border-radius: 5px;
      }
    }
  }
}
