@import '../../../../../abstract/variables.scss';
@import '../../../../../abstract/mixins.scss';

.ratings-curve-graph-item {
  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &-title {
      font-weight: bold;
      font-size: map-get($text-size, 'content-h2');
      color: $primary-color;
    }

    &-btn {
      padding: 5px;
      background-color: unset;
      border: 2px solid $primary-color;
      border-radius: 5px;
      color: $primary-color;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      min-width: 80px;
      a {
        display: block !important;
      }

      &-hidden {
        display: none;
      }

      &:hover {
        background-color: inherit !important;
      }
    }
  }
  
  &-msg {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: map-get($text-size, 'content-h2');
    font-weight: bold;
    color: $primary-color;
    text-align: center;
  }
}
