@import '../../abstract/variables';
@import '../../abstract/mixins';

.page-nav-bar {
  @include flexVerticalCenter;
  @include headerAndFooter;
  color: white;
  display: flex;
  justify-content: space-between;

  &-link-item-group {
    @include flex(right);
    height: 100%;
    align-items: center;

    button:focus {
      outline: 0;
    }
    .nav-link-item {
      @include flexCenter;
      padding: 0px 16px;
      height: 100%;

      &:hover {
        background-color: #004486;
      }
      &.User:hover {
        background-color: #004486 !important;
      }
      &.Login {
        background-color: inherit !important;
      }
      &:last-child {
        padding: 0px;
        margin: 0px 10px;
      }
      .user-profile-btn {
        padding: 24px 15px;
      }
    }
    .nsw-update-btn {
      .expand-link {
        &-dropdown-menu {
          right: -15px;
        }
        &-item {
          margin: 0px;
        }
      }
    }

    .expand-link {
      &-arrow {
        width: 24px;
        margin-left: 5px;
      }
      &-dropdown-menu {
        margin-top: 15px;
        position: absolute;
        z-index: 500;
        width: 190px;
        display: none;
        border-radius: 10px;
        box-shadow: 0 7px 22px 0 rgba(187, 192, 199, 0.37);
        // padding: 12px 0 14px;
        padding: 10px 0 !important;
        right: -25px;
        background: var(--white);
      }
      &-dropdown-menu.expandable {
        display: inherit;
      }
      &-item {
        width: 100%;
        height: 37px;
        margin: 6px 0 0;
        padding: 10px 13.4px 1.2px 21px;
        &:hover {
          background-color: #e5edf6;
        }
      }
      &-item:first-letter {
        text-transform: uppercase;
      }
      &-nav-item {
        a {
          font-size: 14px;
        }
        @include flexCenter;
      }
    }
  }

  &-title {
    display: flex;
    align-items: center;
    justify-content: center;
    &-text {
      margin: 30px 2vw 0px 1vw;
      font-size: map-get($text-size, 'content-h1-subtitle');
    }
  }
}

.nav-display {
  display: inline-block;
}

.logo {
  float: left;
  padding: 5px 0px 5px $page-margin-left-right;
}

.hamburger-menu-icon {
  width: 30px;
  margin: 0px 20px;
}

.cust-navbar-toggler {
  padding: 0;
}

@include xl {
  .page-nav-bar {
    &-link-item-group {
      .nav-link-item {
        padding: 0px 8px;
      }
    }

    &-title {
      &-text {
        margin: 30px 8px 0px 4px;
        font-size: map-get($text-size, 'content-h2');
      }
    }
  }
}

@include md {
  .page-nav-bar {
    &-title {
      &-text {
        display: none;
      }
    }
  }
}

@include sm {
  .page-nav-bar {
    &-title {
      &-text {
        display: unset;
      }
    }
    height: 62px;
  }

  .logo {
    padding: 0px;
  }
}
