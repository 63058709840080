@import '../../abstract/variables';
@import '../../abstract/mixins';

.events-container {
  &-header {
    width: 100%;
    border-radius: 10px;
    background: $scheduled-events;
    font-weight: bold;
    color: $label-primary-color;
    font-size: map-get($text-size, content-h1);
  }
  &-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    font-size: 16px;
    font-weight: bold;

    &-alert {
      font-weight: bold;
      display: flex;
      justify-content: flex-end;
    }
  }

  &-subTitle {
    margin-top: 15px;
    font-size: map-get($text-size, content-body);
    font-weight: bold;
    color: $title-secondary-color;
  }

  &-noEvents {
    margin-top: 10px;
    color: $text-secondary-color;
  }

  &-btn-grp {
    display: flex;
    justify-content: space-between;

    .chart-icon {
      margin-left: 12px;
      height: 100%;

      svg {
        margin-right: 0px !important;
      }
    }

    #alertLink {
      font-weight: bold;
      display: flex;
      align-items: center;
    }
  }
}

@include sm {
  .events-container {
    margin-top: 0px;
    &-header {
      font-size: map-get($mobile-text-size, content-h1);
      text-transform: uppercase;

      .expandBox-title-container {
        padding: 8px 22px;
      }
    }
    &-subTitle {
      margin-top: 20px;
      font-size: map-get($mobile-text-size, content-body);
    }
    &-noEvents {
      font-size: map-get($mobile-text-size, content-body);
      color: $text-secondary-color;
    }
    &-title {
      display: block;
      .page-body-title {
        margin-bottom: 0px !important;
      }
      &-alert {
        .chart-icon {
          justify-content: flex-end;
        }
      }
    }

    .event-item {
      &-content {
        padding-left: 0px;
      }
      &-title,
      &-subdate,
      &-location,
      &-body,
      p {
        margin-bottom: 5px;
      }
      &-body {
        margin-top: 5px;
      }
    }
  }
}
