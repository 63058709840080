@import '../../abstract/variables';
@import '../../abstract/mixins';

.toggle-button,
.toggle-button-single {
  &-button {
    border: solid 1px $primary-color;
    border-left: none;
    width: 180px;
    height: 40px;
    font-size: map-get($text-size, content-body);
    font-weight: bold;
    text-align: center;
    color: $primary-color;
    &-active {
      background-color: $primary-color;
      color: $label-primary-color;
    }
  }

  button:focus {
    outline: none;
  }

  button:first-of-type {
    border-left: solid 1px $primary-color;
    border-radius: 10px 0px 0px 10px;
  }
  button:last-of-type {
    border-radius: 0px 10px 10px 0px;
  }
}

.toggle-button-single {
  button {
    border-radius: 10px !important;
  }
}
@include sm {
  .toggle-button {
    button:first-of-type {
      border-radius: 10px 10px 0px 0px;
    }
    button:last-of-type {
      border-left: solid 1px $primary-color;
      border-radius: 0px 0px 10px 10px;
    }
  }
}

@include md {
  .toggle-button {
    display: flex;
    &-button {
      height: unset;
      font-size: map-get($mobile-text-size, content-body);
      width: 100px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}
