@import '../../abstract/variables';
@import '../../abstract/mixins';

.global-search-bar {
  position: relative;

  &-input {
    position: relative;
    pointer-events: auto;
    background: white;
    border: solid 0.8px $primary-color;
    width: 25vw;
    border-radius: 10px;
    height: 45px;
    font-size: map-get($text-size, 'content-body');
    background-image: url('../../image/icons/search.svg');
    padding-left: 40px;
    padding-right: 10px;
    background-size: 30px;
    background-repeat: no-repeat;
    background-position-x: 8px;
    background-position-y: 8px;
    z-index: 1010;

    &:focus {
      outline: none;
    }
  }

  &-suggestion {
    position: absolute;
    top: 30px;
    background-color: white;
    color: $text-secondary-color;
    font-size: map-get($text-size, 'content-body');
    width: 25vw;
    border-radius: 10px;
    z-index: 1001;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 30px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-item {
      display: flex;
      padding-bottom: 8px;

      &:last-child {
        padding-bottom: 0px;
      }
    }

    &-icon {
      margin-right: 10px;
      width: 24px;
      height: 24px;
    }

    &-remove {
      position: absolute;
      right: 20px;
      font-size: map-get($text-size, 'content-body-subtitle');
      cursor: pointer;
    }

    &-text {
      cursor: pointer;
    }
  }
}

@include sm {
  .global-search-bar {
    &-search {
      background-size: 20px;
      height: 35px;
      margin-bottom: 10px;
      background-position-x: 6px;
      background-position-y: 6px;
    }
  }
}
