@import '../../../../../../abstract/variables';
@import '../../../../../../abstract/mixins';

.station-selections {
  padding-bottom: 12px;
  &-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
  }

  &-msg {
    font-style: italic;
    color: $title-tertiary-color;
    font-size: 13px;
  }

  .spinner-wrapper {
    position: inherit;
  }
}

@include sm {
  .station-selections {
    display: block;
    width: 100%;
  }
}
