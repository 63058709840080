@import '../../abstract/variables';
@import '../../abstract/mixins';

.alert-link {
  a,
  a:hover {
    color: $text-primary-color;
  }
  font-size: 16px;
  padding: 10px;
  // width: 130px;
  border-radius: 5px;
  margin-right: 0;
  margin-top: 5px;
  font-weight: normal;
  cursor: pointer;

  @include sm {
    font-size: 12px;
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
    width: auto;
    span {
      display: inline-block;
      height: 25px;
      line-height: 25px;
      &.update {
        @include sm {
          font-size: 16px;
        }
      }
    }
  }
  &.mini {
    a {
      text-decoration: underline;
    }
    margin-top: 12px;
    font-size: 16px;
    margin-bottom: 0;
  }
}

.popup-alert-link {
  a,
  a:hover {
    color: $text-primary-color;
  }
  font-size: 14px;
  width: 130px;
  border-radius: 5px;
  margin-top: 12px;
  font-weight: normal;
  cursor: pointer;
}

.events-alert {
  padding: 0px;
  width: 126px;
  @include md {
    padding: 0px;
    width: 15vw;
  }
  @include sm {
    padding: 0px;
    width: 20vw;
    display: none;
  }
}
