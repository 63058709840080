@import '../../../abstract/variables';
@import '../../../abstract/mixins';

.hourly-updates {
  &-container {
    border-radius: 10px;
    background: rgba(0, 75, 163, 0.05);
    margin-top: 15px;
    &-graph {
      height: 120px;
      display: 'flex';
      padding-right: 15%;
      justify-content: 'center';
      align-items: 'center';
    }

    .expandBox-container  {
      padding-bottom: unset;
    }
  }
  &-title {
    text-transform: uppercase;
    font-weight: bold;
    color: $text-secondary-color;
    background: #e5edf6;
    border-radius: 10px 10px 0 0;
    font-size: 18px;
    display: flex;
    align-items: center;
    padding: unset;

    &-collapsed {
      text-transform: uppercase;
      font-weight: bold;
      color: $text-secondary-color;
      background: #e5edf6;
      font-size: 18px;
      align-items: center;
      padding: unset;
      border-radius: 10px;
    }

    &-button {
      margin-left: 6px;
      margin-bottom: 3px;
    }

    span {
      @include textVerticalCenter;
    }
  }
  &-icon {
    padding-bottom: 2px;
    &-info {
      fill: $text-secondary-color;
    }
  }
  &-body {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    margin: 20px 1vw;
  }
  &-timestamp {
    position: relative;
    bottom: 45px;
    width: 100%;
    font-size: 14px;
    font-style: italic;
    line-height: 1.14;
    color: #707070;
    text-align: center;
    margin-top: 5px;
  }
  &-arrow {
    fill: $text-primary-color;
  }
  .expandBox-container {
    padding-bottom: 0px;
  }
}

.ws-daily-updates {
  &-title {
    text-transform: uppercase;
    font-weight: bold;
    color: $label-primary-color;
    background: $primary-color;
    border-radius: 10px 10px 0 0;
    font-size: 18px;
    padding: 15px 20px;
    display: flex;
    align-items: center;

    &-button {
      margin-left: 6px;
      margin-bottom: 3px;
    }

    span {
      @include textVerticalCenter;
    }
  }
  &-icon {
    padding-bottom: 2px;
    &-info {
      fill: $label-primary-color;
    }
  }
  &-body {
    margin: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-content: flex-end;
  }
  &-timestamp {
    position: relative;
    bottom: 10px;
    width: 100%;
    font-size: 14px;
    font-style: italic;
    line-height: 1.14;
    color: #707070;
    text-align: center;
    margin-top: 5px;
  }
}

@include xl {
  .ws-daily-updates {
    &-body {
      margin: 40px 5px;
    }
  }
}

@include sm {
  .daily-updates {
    &-title {
      font-size: map-get($mobile-text-size, page-h2);
      padding: 18px 20px 17px 20px;
      height: unset;
      line-height: 1.17;
    }
    &-body {
      padding: 20px 10px;
      height: unset;
      display: flex;
      justify-content: space-evenly;
      margin: unset;
    }
    &-timestamp {
      padding-bottom: 8px;
      position: relative;
      bottom: 0;
      font-size: 14px;
      margin: 0px;
    }
  }
  .ws-daily-updates {
    &-body {
      margin: 20px 0px 30px 0px;
      justify-content: space-evenly;
    }
    &-timestamp {
      bottom: 0;
      font-size: 14px;
    }
  }
}
